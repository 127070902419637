import React from "react";
import Styles from "./styles.module.scss";

export default function TabHeader({
  tabs,
  setTabs,
  tabOptions,
  style,
  className,
}) {
  return (
    <div className={className}>
      <div className={Styles.tabContainer} style={style}>
        {tabOptions.map((el, idx) => (
          <div
            key={idx}
            className={Styles.tab}
            is-selected={tabs === idx + 1 ? "true" : "false"}
            onClick={() => setTabs(idx + 1)}
          >
            <p>{el}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
