import Footer from "@Molecule/Footer";
import Sidebar from "@Molecule/Sidebar";
import React from "react";
import { Outlet } from "react-router-dom";

export default function InstructorDashboardPage() {
  return (
    <Sidebar type={"instructor"}>
      <Outlet />
      <Footer />
    </Sidebar>
  );
}
