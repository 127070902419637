import React, { useLayoutEffect, useState } from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import { useLocation, useNavigate } from "react-router-dom";
import rightArrow from "@Assets/Icon/arrow-circle-right.svg";
import Modal from "@Atom/Modal";
import TeacherScoreReviewModal from "../TeacherScoreReview";
import LoadingContent from "@Molecule/Loading";

export default function ASTDetail({ data, isLoading }) {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  useLayoutEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isModalOpen]);

  return (
    <div className={Styles.container}>
      <div className={Styles.backButton}>
        <Button
          onClick={() => navigate(-1)}
          text={"kembali"}
          startIcon={"chevron-left"}
          variant="text"
        />
      </div>
      <div className={Styles.header}>
        <p>Penilaian Asesmen Sumatif Teori</p>
      </div>
      <div className={Styles.body}>
        <div className={Styles.bodyHead}>
          <p>Nama Murid</p>
          <p>{locationState.name}</p>
        </div>
        {isLoading ? (
          <LoadingContent />
        ) : (
          <div className={Styles.sectionWrapper}>
            {data.map((el, idx) => (
              <div className={Styles.section} key={idx}>
                <div className={Styles.scoreSection}>
                  <p>{el.status === "filled" ? "-" : el.materialScore}</p>
                </div>
                <div className={Styles.textSection}>
                  <p>Asesmen Sumatif Teori {idx + 1}</p>
                  <p>
                    Pilihan Ganda: {el.mcCount} Soal{" "}
                    {el.essayCount > 0 && <span>|</span>}{" "}
                    {el.essayCount > 0 && (
                      <span>Essay: {el.essayCount} Soal</span>
                    )}
                  </p>
                </div>
                <div className={Styles.statusSection}>
                  <div
                    className={Styles.status}
                    status={String(el.status === "filled")}
                  >
                    <p>
                      {el.status !== "filled" ? "Dinilai" : "Butuh Penilaian"}
                    </p>
                  </div>
                </div>
                <div className={Styles.actionSection}>
                  <img
                    src={rightArrow}
                    alt=""
                    onClick={() => {
                      setSelectedData(el.material);
                      setIsModalOpen(true);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Modal isOpen={isModalOpen}>
        <TeacherScoreReviewModal
          materialData={selectedData}
          handleClose={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
}
