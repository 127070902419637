import { API } from "@Config/index";

export function login(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/login`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function decryptToken(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/token`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function resetPassword(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/resetPassword`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function forgotPassword(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/forgetPassword`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function loginVerification(hash = '', otp = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/verifyOtp/login`, { hash, otp });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function register(name = '', email = '', phoneNumber = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/register`, { name, email, phoneNumber });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function registerVerification(hash = '', otp = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/verifyOtp`, { hash, otp });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getAllInstructors(name = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/user/instructor`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          name
        }
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}