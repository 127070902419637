import DoodleGradientCard from "@Atom/DoodleGradientCard";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Footer from "@Molecule/Footer";
import Table from "@Molecule/Table";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import useWindowSize from "@Hooks/useWindowSize";
import Searchbar from "@Atom/Searchbar";
import useQuery from "@Hooks/useQuery";
import { useDebounce } from "@Hooks/useDebounce";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import InputField from "@Molecule/InputField";
import Select from "@Atom/Select";
import InputSelect from "@Atom/InputSelect";
import { downloadStudentData, updateStudentData } from "@Services/admin";
import moment from "moment";
import Modal from "@Atom/Modal";
import InputFileModal from "@Molecule/InputFileModal";
import { fileBaseUrl } from "@Config/index";

export default function ListStudentLayout({ dataStudent, isLoading }) {
  const user = decryptStorageData("user", localStorage);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const query = useQuery();
  const pageQuery = query?.get("page");
  const limitQuery = query?.get("limit");
  const tabQuery = query?.get("tab");
  const searchQuery = query?.get("search");
  const gradeQuery = query?.getAll("grades");
  const progressQuery = query?.get("progress");
  const reportQuery = query?.get("report");
  const [tab, setTab] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);
  const [grade, setGrade] = useState([]);
  const [progress, setProgress] = useState("");
  const [report, setReport] = useState("");
  const [triggerFilter, setTriggerFilter] = useState("");
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [error, setError] = useState("");
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);

  useEffect(() => {
    setPage(+pageQuery || 1);
    setLimit(+limitQuery || 10);
    setTab(+tabQuery || 1);
    setSearchTerm(searchQuery || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitQuery, pageQuery, searchQuery, tabQuery, triggerFilter]);
  // useEffect(() => {
  //   setData(dataStudent);
  // }, [dataStudent]);

  useEffect(() => {
    setGrade(gradeQuery || []);
    setProgress(progressQuery || "");
    setReport(reportQuery || "");
    setTriggerFilter(true);
  }, []);

  const debouncedSearchQuery = useDebounce(searchTerm, 500);
  useEffect(() => {
    if (debouncedSearchQuery?.length > 1) {
      setLoading(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [debouncedSearchQuery]);

  useEffect(() => {
    navigate(
      `${pathname}?s=1&limit=${limit}&page=${page}&search=${debouncedSearchQuery}&tab=${tab}${grade
        .map((el) => "&grades=" + el)
        .join("")}${progress ? "&progress=" + progress : ""}${
        report ? "&report=" + report : ""
      }`,
      {
        replace: true,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, limit, page, tab, triggerFilter]);

  const template = useMemo(() => {
    return {
      data: dataStudent?.data?.map((obj) => {
        return {
          ...obj,
          id: obj?._id,
        };
      }),
      // .filter((el) =>
      //   el.name.toLowerCase().includes(searchTerm.toLowerCase())
      // )
      // .slice(limit * (page - 1), limit * page),
      columns: [
        {
          name: "",
          title: "Nama Siswa",
          renderData: (row) => (
            <div
              className={Styles.student}
              onClick={() => navigate(`detail/${row?._id}`)}
            >
              <span>{row?.name}</span>
            </div>
          ),
        },
        {
          name: "",
          title: "Kelas",
          renderData: (row) => (
            <div className={Styles.student}>
              <span>{row?.grade.grade}</span>
            </div>
          ),
        },
        {
          name: "idNumber",
          title: "NISN",
        },
        {
          name: "phoneNumber",
          title: "No Telpon",
        },
        {
          name: "",
          title: "Progress",
          renderData: (row) => <span>{Math.round(row?.grade.progress)}%</span>,
          align: "right",
        },
        {
          name: "",
          title: "Penerbitan Rapor",
          align: "center",
          renderData: (row) => (
            <div
              className={Styles.progressStatus}
              status={row.isPublished ? "published" : "false"}
            >
              <span>
                {row.isPublished ? "Diterbitkan" : "Belum Diterbitkan"}
              </span>
            </div>
          ),
        },
      ],
    };
  }, [dataStudent, navigate]);

  const handleDownloadData = async () => {
    try {
      const response = await downloadStudentData();
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `data siswa ${moment().format("LL")}`;
      downloadLink.click();
    } catch (error) {
      console.log(error);
    }
    // const workbook = XLSX.utils.book_new();
    // const worksheet = XLSX.utils.json_to_sheet(data);
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
  };
  const handleUploadData = async (file) => {
    try {
      setLoadingUploadFile(true);
      await updateStudentData(file);
      setOpenUploadModal(false);
      setLoadingUploadFile(false);

      setError("");
      navigate(
        `${pathname}?s=1&limit=${limit}&page=${page}&search=${debouncedSearchQuery}&tab=${tab}${grade
          .map((el) => "&grades=" + el)
          .join("")}${progress ? "&progress=" + progress : ""}${
          report ? "&report=" + report : ""
        }`,
        { replace: true }
      );
    } catch (error) {
      setLoadingUploadFile(false);
      setError(error.response.data.message);
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <DoodleGradientCard className={Styles.header}>
          <div className={Styles.title}>
            <h3>Selamat datang {<span>{user?.name}</span>}</h3>
            <span>Berikut adalah daftar seluruh siswa di THS</span>
          </div>
          <Button
            variant="outlined-neutral"
            text={"Tambah Siswa"}
            startIcon={"add"}
            onClick={() => navigate("add")}
          />
        </DoodleGradientCard>
        <div className={Styles.filterWrapper}>
          <Searchbar
            value={searchTerm}
            setValue={setSearchTerm}
            placeholder="Cari Peserta"
          />
          <div
            className={Styles.filter}
            active={String(openFilter)}
            onClick={() => setOpenFilter(!openFilter)}
          >
            <Icon icon={"setting-4"} size={20} color={"#9E9E9E"} />
            <p>Filter</p>
          </div>
          <div
            className={Styles.dropdownContainer}
            is-open={String(openFilter)}
          >
            <div className={Styles.header}>
              <p>Filter</p>
            </div>
            <div className={Styles.divider} />
            <div className={Styles.body}>
              <div className={Styles.input}>
                <label htmlFor="batch">Kelas</label>
                <InputSelect
                  value={grade}
                  setValue={setGrade}
                  isMultiple={true}
                  placeholder={"Pilih Kelas"}
                  position="bottom"
                  options={[
                    "SD Kelas 1",
                    "SD Kelas 2",
                    "SD Kelas 3",
                    "SD Kelas 4",
                    "SD Kelas 5",
                    "SD Kelas 6",
                    "SMP Kelas 7",
                    "SMP Kelas 8",
                    "SMP Kelas 9",
                    "SMA Kelas 10",
                    "SMA Kelas 11",
                    "SMA Kelas 12",
                  ]}
                />
              </div>
              <div className={Styles.input}>
                <label htmlFor="batch">Progress Kelas</label>
                <InputSelect
                  value={progress}
                  setValue={setProgress}
                  placeholder={"Pilih Progress Siswa"}
                  position="bottom"
                  options={[
                    "Terendah",
                    "Tertinggi",
                    "Sudah 100%",
                    "Kurang Dari 100%",
                  ]}
                />
              </div>
              <div className={Styles.input}>
                <label htmlFor="batch">Rapor</label>
                <InputSelect
                  value={report}
                  setValue={setReport}
                  placeholder={"Pilih Progress Siswa"}
                  position="bottom"
                  options={["Diterbitkan", "Belum Diterbitkan"]}
                />
              </div>
            </div>
            <div className={Styles.divider} />

            <div className={Styles.filterAction}>
              <Button
                text={"Reset"}
                variant="outlined"
                disabled={
                  grade.length === 0 &&
                  progress.length === 0 &&
                  report.length === 0
                }
                onClick={() => {
                  setGrade([]);
                  setProgress("");
                  setReport("");
                }}
              />
              <Button
                text={"Terapkan"}
                onClick={() => {
                  setTriggerFilter(!triggerFilter);
                  setOpenFilter(false);
                }}
              />
            </div>
          </div>
        </div>
        <div className={Styles.tabSection}>
          <div className={Styles.tabWrapper}>
            <Button
              variant="tab"
              text={"Siswa Aktif"}
              isActive={tab === 1}
              onClick={() => setTab(1)}
            />
            <Button
              variant="tab"
              text={"Siswa Tidak Aktif "}
              isActive={tab === 2}
              onClick={() => setTab(2)}
            />
          </div>
          <div className={Styles.buttonWrapper}>
            <Button
              variant="outlined"
              text={"Upload Data"}
              startIcon={"document-upload-filled"}
              onClick={() => setOpenUploadModal(true)}
            />
            <Button
              text={"Download Data"}
              startIcon={"document-upload-filled"}
              onClick={handleDownloadData}
            />
          </div>
        </div>
        <Table
          data={template?.data}
          totalData={dataStudent?.totalData}
          totalPage={Math.ceil(dataStudent.totalData / limit)}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table} ${
            !template?.data?.length ? Styles.grow : ""
          }`}
          limit={limit}
          setLimit={setLimit}
          withPagination
          isLoading={loading || isLoading || page !== +pageQuery}
          withNumbering
          expandComponent={(row) => (
            <div className={Styles.expandedInfo}>
              <div className={Styles.infoRow}>
                <span>Email</span>
                <span>{row?.email}</span>
              </div>
              <div className={Styles.infoRow}>
                <span>No. Telepon</span>
                <span>{row?.phoneNumber}</span>
              </div>
            </div>
          )}
        />
      </div>
      <Modal isOpen={openUploadModal}>
        <InputFileModal
          text="Siswa"
          onClose={() => setOpenUploadModal(false)}
          handleSubmit={(file) => handleUploadData(file)}
          withTemplate
          error={error}
          loading={loadingUploadFile}
          templateUrl={
            fileBaseUrl + "1733386375526-Template_Input_Student.xlsx"
          }
        />
      </Modal>
      <Footer />
    </div>
  );
}
