import React from "react";
import style from "./style.module.scss";
import Text from "@Atom/Text";
import Icon from "@Atom/Icon";
import schoolGirlWithTeacher from "../../../assets/Images/SchoolgirlWIthTeacher.png";
export default function ModalSaveToDraft({
  onClickCloseModal,
  onClickButtonRemove,
  OnClickSaveToDraft,
}) {
  return (
    <div>
      <section className={style.wrapper}>
        <div className={style.modalHeader}>
          <Text color={"#404040"} size={"l"} width={"semibold"}>
            Keluar dari pembuatan kelas?
          </Text>
          <Icon
            color={"black"}
            className={style.modalClose}
            size={"24px"}
            icon={"cross"}
            onClick={onClickCloseModal}
          />
        </div>
        <img
          className={style.modalImage}
          src={schoolGirlWithTeacher}
          alt="images"
        />
        <div className={style.modalDescription}>
          <Text color={"#333333"} size={"s"} width={"regular"}>
            Apakah Anda ingin menyimpan pembuatan kelas ini sebagai draft?
          </Text>
        </div>
        <div className={style.buttonModal}>
          <button onClick={onClickButtonRemove}>
            <p className={style.textButtonBack}>Hapus</p>
          </button>
          <button
            onClick={OnClickSaveToDraft}
            className={style.textButtonStart}
          >
            Simpan Draft
          </button>
        </div>
      </section>
    </div>
  );
}
