import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import Button from '@Atom/Button'
import { useState } from 'react'
import ViewQuestionsModal from '@Molecule/ViewQuestionsModal'

export default function QuizCard({
  data
}) {
  const [showQuestions, setShowQuestions] = useState(false)

  return (
    <div className={Styles.quizCard}>
      <div className={Styles.quizLogo}>
        <Icon icon={'list-numbered-filled'} size={20} />
      </div>
      <span>{`${data?.questions?.length || 0} Soal Dibuat`}</span>
      <Button
        variant='outlined'
        text={'Lihat Soal'}
        onClick={()=> setShowQuestions(true)}
      />
      <ViewQuestionsModal
        questions={data?.questions}
        show={showQuestions}
        onClose={()=>setShowQuestions(false)}
      />
    </div>
  )
}