import React, { useState } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import Images from "@Theme/Images";
import Footer from "@Molecule/Footer";
import { useNavigate } from "react-router-dom";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Lottie from "lottie-react";
import loadingClass from "@Assets/animation/loadingClass.json";
import ClassImage from "@Theme/ClassImage";
import AcademicYearCard from "@Molecule/AcademicYearCard";
import Icon from "@Atom/Icon";
import { useMemo } from "react";

function filterBySchoolLevel(grades, level) {
  if (level === "SD") {
    return grades.filter((g) => g.grade >= 1 && g.grade <= 6);
  } else if (level === "SMP") {
    return grades.filter((g) => g.grade >= 7 && g.grade <= 9);
  } else if (level === "SMA") {
    return grades.filter((g) => g.grade >= 10 && g.grade <= 12);
  } else {
    return null; // Return an empty array if the level is invalid
  }
}

export default function MyClass({ data = [], isLoading }) {
  const user = decryptStorageData("user", localStorage);
  const dataToShow = useMemo(() => {
    return data.map((el) => ({
      ...el,
      grade: el.grade.split(" ").slice(-1)[0],
    }));
  }, [data]);

  return (
    <div className={Styles.container}>
      <div className={Styles.layout}>
        <section className={Styles.doodle}>
          <DoodleGradientCard>
            <div className={Styles.topCard}>
              <div className={Styles.leftSection}>
                <div>
                  <p className={Styles.title}>
                    Selamat Datang <span>{user?.name}</span>
                  </p>
                  <p className={Styles.desc}>
                    Berikut daftar kelasmu, mulai dan selesaikan belajarmu
                  </p>
                </div>
                {/* <TransparentSearchbar
                  placeholder="Cari Kelasmu"
                  setValue={setSearchQuery}
                  value={searchQuery}
                /> */}
              </div>
              <div className={Styles.rightSection}>
                <img
                  src={Images.STUDENT_DOODLE_ICON}
                  width={"168px"}
                  height={"168px"}
                  alt="icon"
                />
              </div>
            </div>
          </DoodleGradientCard>
        </section>
        <section className={Styles.bodyWrapper}>
          {isLoading ? (
            <div className={Styles.loadingContainer}>
              <Lottie animationData={loadingClass} loop={true} />
              <p>Memuat Kontent</p>
            </div>
          ) : (
            <div className={Styles.cardByScoolLevelWrapper}>
              {filterBySchoolLevel(dataToShow, "SMA").length > 0 && (
                <CardSchoolByLevel
                  data={filterBySchoolLevel(dataToShow, "SMA")}
                  title="Sekolah Menengah Atas"
                  schoolLevel="SMA"
                />
              )}
              {filterBySchoolLevel(dataToShow, "SMP").length > 0 && (
                <CardSchoolByLevel
                  data={filterBySchoolLevel(dataToShow, "SMP")}
                  title="Sekolah Menengah Pertama"
                  schoolLevel="SMP"
                />
              )}
              {filterBySchoolLevel(dataToShow, "SD").length > 0 && (
                <CardSchoolByLevel
                  data={filterBySchoolLevel(dataToShow, "SD")}
                  title="Sekolah Dasar"
                  schoolLevel="SD"
                />
              )}
            </div>
          )}
        </section>
      </div>
      <Footer />
    </div>
  );
}

const CardSchoolByLevel = ({ data, title, schoolLevel }) => {
  const [showned, setShowned] = useState(true);
  const navigate = useNavigate();

  return (
    <div className={Styles.cardByScoolLevel}>
      <div className={Styles.header}>
        <div className={Styles.logo}>
          <img src={ClassImage(schoolLevel)} alt="" />
        </div>
        <div className={Styles.text}>
          <p>
            {title} ({schoolLevel})
          </p>
        </div>
        <div className={Styles.icon} showned={String(showned)}>
          <Icon
            icon={"arrow-up1"}
            size={24}
            onClick={() => setShowned(!showned)}
          />
        </div>
      </div>
      <div className={Styles.cardWrapper} showned={String(showned)}>
        {data.map((el, idx) => (
          <React.Fragment key={idx}>
            <AcademicYearCard
              onClick={() => navigate(`${el._id}?ta=${el.academicYear}`)}
              grade={el.grade}
              academicYear={el.academicYear}
              schoolLevel={title}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
