import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function GeneralInfoDetail({
  title,
  description,
  list = []
}) {


  return (
    <div className={Styles.container}>
      <h5>{title}</h5>
      {!!description && <p>{description}</p>}
      {
        !!list?.length
        &&
        <div className={Styles.list}>
          {list?.map((l, lI) => (
            <p key={lI}>
              <Icon icon={'checked'} size={20} color={'#9360A8'} />
              <span>{l}</span>
            </p>
          ))}
        </div>
      }

    </div>
  )
}