import AdminClassDetail from "@Organism/Admin/AdminClassDetail";
import { getClassDetailAdmin } from "@Services/admin";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader({ params }) {
  const { id } = params;
  const getClassDetail = getClassDetailAdmin(id);
  return defer({
    getClassDetail,
  });
}

export default function AdminClassDetailPage() {
  const { getClassDetail } = useLoaderData();
  //   console.log(getAllStudent);

  return (
    <Suspense fallback={<AdminClassDetail dataClass={null} isLoading />}>
      <Await
        resolve={getClassDetail}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <AdminClassDetail dataClass={value.data} />}
      </Await>
    </Suspense>
  );
}
