import React from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import Images from "@Theme/Images";

export default function ModalError({ title, onClose, content }) {
  return (
    <div className={Styles.container}>
      <div className={Styles.modalBody}>
        <p>{title}</p>
        <img src={Images.SESSION_DISABLE} alt="" />
        {content}
      </div>
      <div className={Styles.modalAction}>
        <Button text={"Tutup"} onClick={() => onClose()} variant="outlined"/>
      </div>
    </div>
  );
}
