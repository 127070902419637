import InputField from "@Molecule/InputField";
import { useCallback, useEffect, useMemo } from "react";
// import { useLocation } from "react-router-dom";
import Styles from "./style.module.scss";
import { getYearsArray } from "@Helpers/getArrayOfYear";

export default function DetailsSection({
  form,
  setForm,
  instructors,
  // isLoadingSync,
  certificate,
  setCertificate,
  // type,
}) {
  // const { pathname } = useLocation();

  const handleChangeForm = useCallback(
    (name, newVal, childName) => {
      if (!childName) {
        setForm((prev) => {
          return {
            ...prev,
            [name]: newVal,
          };
        });
      } else {
        setForm((prev) => {
          return {
            ...prev,
            [name]: {
              ...prev[name],
              [childName]: newVal,
            },
          };
        });
      }
    },
    [setForm]
  );

  useEffect(() => {
    handleChangeForm(
      "instructorID",
      instructors?.find((obj) => obj?.name === form?.instructorName)?._id || ""
    );
  }, [form?.instructorName, handleChangeForm, instructors]);

  // const handleSwitchCertificate = () => {
  //   if (certificate.isAllCertificate) {
  //     setCertificate({
  //       isAllCertificate: false,
  //       data: [
  //         {
  //           name: "",
  //           file: "",
  //           _id: "1",
  //           vendor: "BUKALAPAK",
  //         },
  //         {
  //           name: "",
  //           file: "",
  //           _id: "2",
  //           vendor: "TOKOPEDIA",
  //         },
  //         {
  //           name: "",
  //           file: "",
  //           _id: "3",
  //           vendor: "PINTAR",
  //         },
  //       ],
  //     });
  //   } else {
  //     setCertificate({
  //       isAllCertificate: true,
  //       data: [
  //         {
  //           name: "",
  //           file: "",
  //           _id: "1",
  //           vendor: "ALL",
  //         },
  //       ],
  //     });
  //   }
  // };

  // const handleChangeCertificate = (val) => {
  //   setCertificate((prev) => ({
  //     ...prev,
  //     data: prev.data.map((el) =>
  //       el._id === modalSelectCertificate ? { ...el, ...val } : el
  //     ),
  //   }));
  // };

  const options = useMemo(() => {
    return {
      grades: [
        "SD Kelas 1",
        "SD Kelas 2",
        "SD Kelas 3",
        "SD Kelas 4",
        "SD Kelas 5",
        "SD Kelas 6",
        "SMP Kelas 7",
        "SMP Kelas 8",
        "SMP Kelas 9",
        "SMA Kelas 10",
        "SMA Kelas 11",
        "SMA Kelas 12",
      ],
      subjects: [
        "AGAMA & BUDI PEKERTI",
        "PENDIDIKAN PANCASILA",
        "BAHASA INDONESIA",
        "MATEMATIKA",
        "PENGETAHUAN ALAM & SOSIAL",
        "ILMU PENGETAHUAN ALAM",
        "ILMU PENGETAHUAN SOSIAL",
        "BAHASA INGGRIS",
        "JASMANI, OLAHRAGA & KESEHATAN",
        "SENI BUDAYA",
        "KETERAMPILAN",
        "PEMBERDAYAAN (KONSELING)",
        "FISIKA",
        "KIMIA",
        "BIOLOGI",
        "GEOGRAFI",
        "EKONOMI",
        "SEJARAH",
        "SOSIOLOGI",
        "INFORMATIKA",
      ],
    };
  }, []);

  // const [modalSelectCertificate, setModalSelectCertificate] = useState(false);

  const template = useMemo(() => {
    return [
      {
        title: "Thumbnail dan Video Pengenalan Kelas",
        component: (
          <div className={Styles.thumbnailsWrapper}>
            <InputField
              title={"Thumbnail"}
              value={form?.thumbnail}
              setValue={(newVal) => handleChangeForm("thumbnail", newVal)}
              placeholder="Pilih file untuk thumbnail kelas"
              isFile
              isImageOnly
              required
              speed={10}
            />
            <InputField
              title={"Video Pengenalan Guru / Mata Pelajaran"}
              value={form?.video}
              setValue={(newVal) => handleChangeForm("video", newVal)}
              // placeholder='Pilih file untuk video pengenalan kelas'
              // isFile
              // isVideoOnly
              placeholder="Masukkan link video"
              isLink
              required
              speed={10}
            />
          </div>
        ),
      },
      {
        title: "Informasi Umum",
        component: (
          <div className={Styles.infoWrapper}>
            <InputField
              title={"Kategori mata pelajaran"}
              value={form?.classSubject}
              setValue={(newVal) => handleChangeForm("classSubject", newVal)}
              placeholder="Kategori mata pelajaran"
              isDropdown
              dropdownOptions={options?.subjects}
              required
            />
            <InputField
              title={"Level Kelas"}
              value={form?.grade}
              setValue={(newVal) => handleChangeForm("grade", newVal)}
              placeholder="Level kelas"
              isDropdown
              dropdownOptions={options?.grades}
              isLoadingDropdown={false}
              required
            />
            <div className={Styles.sectionWrapper}>
              <p>
                Tahun Ajaran<span>*</span>
              </p>
              <div className={Styles.flex}>
                <InputField
                  value={form?.startAcademicYear}
                  setValue={(newVal) => {
                    handleChangeForm("startAcademicYear", newVal);
                    handleChangeForm("endAcademicYear", String(+newVal + 1));
                  }}
                  placeholder="Tahun Mulai"
                  isDropdown
                  dropdownOptions={getYearsArray()}
                  isLoadingDropdown={false}
                />
                <p>-</p>
                <input
                  value={form?.endAcademicYear}
                  placeholder="Tahun Berakhir"
                  disabled
                  // setValue={(newVal) =>
                  //   handleChangeForm("endAcademicYear", newVal)
                  // }

                  // dropdownOptions={getYearsArray()}
                  // isLoadingDropdown={false}
                />
              </div>
            </div>
            <InputField
              title={"Tujuan Pembelajaran"}
              value={form?.learningGoals}
              setValue={(newVal) => handleChangeForm("learningGoals", newVal)}
              placeholder="Tujuan Pembelajaran"
              isTextArea
              required
            />

            {/* <InputField
              title={'Pembelajaran Utama'}
              value={form?.mainObjective}
              setValue={(newVal) => handleChangeForm('mainObjective', newVal)}
              placeholder='Pembelajaran utama kelas'
              required
            /> */}
          </div>
        ),
      },
      // {
      //   title: "Konfigurasi",
      //   component: (
      //     <div className={Styles.configurationWrapper}>
      //       <CheckboxOption
      //         textCheckbox={
      //           "Peserta diizinkan untuk mempercepat video pembelajaran"
      //         }
      //         isChecked={form?.skipVideo}
      //         onClick={() =>
      //           setForm((prev) => {
      //             return {
      //               ...prev,
      //               skipVideo: !prev?.skipVideo,
      //             };
      //           })
      //         }
      //       />
      //       <CheckboxOption
      //         textCheckbox={
      //           "Peserta diizinkan untuk menonton video secara tidak berurutan"
      //         }
      //         isChecked={form?.unorderedWatch}
      //         onClick={() =>
      //           setForm((prev) => {
      //             return {
      //               ...prev,
      //               unorderedWatch: !prev?.unorderedWatch,
      //             };
      //           })
      //         }
      //       />
      //       <CheckboxOption
      //         textCheckbox={
      //           "Peserta diizinkan untuk mengerjakan kuis sebelum materi selesai"
      //         }
      //         isChecked={form?.doQuizFromStart}
      //         onClick={() =>
      //           setForm((prev) => {
      //             return {
      //               ...prev,
      //               doQuizFromStart: !prev?.doQuizFromStart,
      //             };
      //           })
      //         }
      //       />
      //     </div>
      //   ),
      // },
    ];
  }, [
    form?.thumbnail,
    form?.video,
    form?.classSubject,
    form?.grade,
    form?.startAcademicYear,
    form?.endAcademicYear,
    form?.learningGoals,
    options?.subjects,
    options?.grades,
    handleChangeForm,
  ]);

  const templateToMap = useMemo(() => {
    if (form?.participantType === "PRAKERJA") {
      return template?.filter((obj) => obj?.title !== "Konfigurasi");
    }
    return template?.filter((obj) => obj?.title !== "Unit Kompetensi");
  }, [form?.participantType, template]);

  // const [certificateData, setCertificateData] = useState([]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Detail Kelas</h3>
        <span>Isi dan lengkapi data informasi kelas yang akan dibuat</span>
      </div>
      <div className={Styles.content}>
        {templateToMap?.map((t, tI) => (
          <div key={tI} className={Styles.each}>
            <div className={Styles.eachHeader}>
              <h4>{t?.title}</h4>
              {!!t?.description && <span>{t?.description}</span>}
            </div>
            {t?.component}
          </div>
        ))}
      </div>
      {/* <Modal isOpen={modalSelectCertificate}>
        <ModalSelectCertificate
          onClose={() => setModalSelectCertificate(false)}
          data={certificateData}
          handleSelect={(val) => {
            handleChangeCertificate(val);
            setModalSelectCertificate(false);
          }}
        />
      </Modal> */}
    </div>
  );
}
