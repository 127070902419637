import { useEffect, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import useOutsideClick from "@Hooks/useOutsideClick";
import CheckBox from "@Atom/Checkbox";

export default function InputSelect({
  value = [],
  setValue,
  options,
  placeholder,
  className = "",
  width = "auto",
  position = "right",
  isMultiple = false,
  ...props
}) {
  const [expand, setExpand] = useState(false);

  const boxRef = useRef();
  const boxOutsideClick = useOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && expand) {
      setExpand(false);
    }
  }, [boxOutsideClick, expand]);

  const handleSelectMultiple = (newValue) => {
    if (value.includes(newValue)) {
      setValue(value.filter((v) => v !== newValue));
    } else {
      setValue([...value, newValue]);
    }
  };

  return (
    <div
      {...props}
      ref={boxRef}
      style={{ width }}
      className={`${Styles.container} ${
        !value || value.length < 1 ? Styles.empty : ""
      } ${className}`}
      onClick={() => setExpand(!expand)}
    >
      <span>
        {!value || value.length < 1
          ? placeholder
          : isMultiple
          ? value?.join(", ")
          : value}
      </span>
      <Icon
        className={expand ? Styles.rotate : ""}
        icon={"keyboard_arrow_down"}
        size={18}
      />

      {expand && !isMultiple && (
        <div
          className={Styles.optionsWrapper}
          position={position}
          ref={boxRef}
          onClick={() => setExpand(false)}
        >
          {options?.map((o, oI) => (
            <div
              key={oI}
              onClick={() => {
                setValue(o);
                setExpand(false);
              }}
            >
              {o}
            </div>
          ))}
        </div>
      )}
      {expand && isMultiple && (
        <div className={Styles.optionsWrapper} position={position} ref={boxRef}>
          {options?.map((o, oI) => (
            <div
              key={oI}
              onClick={() => handleSelectMultiple(o)}
              className={Styles.flex}
            >
              <CheckBox isChecked={value?.includes(o)} />
              {o}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
