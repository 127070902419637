import RadioButton from '@Atom/RadioButton'
import Styles from './style.module.scss'

export default function PopupAnswer({ isCorrect, setIsCorrect }) {

  return (
    <div className={Styles.container}>
      <div className={Styles.option} onClick={()=> setIsCorrect(true)}>
        {/* <div
          className={`${Styles.radio} ${isCorrect || isCorrect === false
            ?
            (
              isCorrect ? Styles.positive : Styles.negative
            )
            :
            ''
            }`}
        >
          {(isCorrect || isCorrect === false)
            &&
            <Icon icon={isCorrect ? 'check' : 'cross'} size={18} />
          }
        </div> */}
        <RadioButton  isSelected={isCorrect === true}/>
        <span>Benar</span>
      </div>
      <div className={Styles.option} onClick={()=> setIsCorrect(false)}>
        {/* <div
          className={`${Styles.radio} ${isCorrect || isCorrect === false
            ?
            (
              isCorrect ? Styles.negative : Styles.positive
            )
            :
            ''
            }`}         >
          {(isCorrect || isCorrect === false)
            &&
            <Icon icon={isCorrect ? 'cross' : 'check'} size={18} />
          }
        </div> */}
        <RadioButton  isSelected={!isCorrect}/>
        <span>Salah</span>
      </div>
    </div>
  )
}