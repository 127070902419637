// import Skeleton from "@Atom/Skeleton";
import Styles from "./style.module.scss";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import Pagination from "@Atom/Pagination";
import { Fragment, useEffect, useMemo, useState } from "react";
import useWindowSize from "@Hooks/useWindowSize";
import Icon from "@Atom/Icon";
import Lottie from "lottie-react";
import loadingClass from "@Assets/animation/loadingClass.json";

export default function Table({
  columns,
  data = [],
  totalData = 0,
  totalPage = 0,
  withPagination = false,
  page = 1,
  setPage = () => {},
  limit = 5,
  setLimit = () => {},
  isLoading = false,
  className = "",
  withNumbering = false,
  expandComponent = (row) => <span>data</span>,
  descEmpty,
  descTitle,
  isSticky = false,
  ...props
}) {
  const { width } = useWindowSize();
  const columnsToMap = useMemo(() => {
    if (width > 768) {
      return columns?.filter((obj) => !obj?.showOnMobile);
    }
    return columns?.filter((obj) => !obj?.hideOnMobile);
  }, [columns, width]);

  const [dataToMap, setDataToMap] = useState(data);
  useEffect(() => {
    setDataToMap(data);
  }, [data]);

  return (
    <div className={`${Styles.container} ${className}`}>
      <div
        className={Styles.tableWrapper}
        style={{
          minHeight:
            !isLoading && !data?.length
              ? "455px"
              : data?.length >= 5
              ? "unset"
              : "455px",
        }}
      >
        <table className={Styles.table}>
          <thead>
            <tr>
              {withNumbering && <th style={{ width: "40px" }}>No</th>}

              {columnsToMap?.map((c, cI) => (
                <th
                  key={cI}
                  style={{
                    textAlign: c?.align || "left",
                    width: c?.width,
                    minWidth: isSticky
                      ? c?.isNumber
                        ? "40px"
                        : c?.width || "100px"
                      : c?.width || "auto",
                    position: !isSticky
                      ? "inherit"
                      : cI === 0 || cI === 1
                      ? "sticky"
                      : c?.position || "inherit",
                    left: cI === 1 ? "51px" : "0",
                    right: c?.right,
                  }}
                >
                  {c?.title}
                </th>
              ))}
              {width <= 768 && <th style={{ width: "30px" }} />}
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              dataToMap?.map((d, dI) => (
                <Fragment key={dI}>
                  <tr key={dI}>
                    {/* <td style={{textAlign: 'center'}}>{dI + 1}</td> */}
                    {withNumbering && <td>{dI + 1 + (page - 1) * limit}</td>}
                    {columnsToMap?.map((c, cI) => (
                      <td
                        key={cI}
                        style={{
                          textAlign: c?.align || "left",
                          width: c?.width || "auto",
                          position: !isSticky
                            ? "inherit"
                            : cI === 0 || cI === 1
                            ? "sticky"
                            : c?.position || "inherit",
                          left: cI === 1 ? "51px" : "0",
                          right: c?.right,
                          background: "white",
                        }}
                      >
                        {c?.renderData ? (
                          c?.renderData(d)
                        ) : (
                          <span>{d[c?.name] || dI + 1}</span>
                        )}
                      </td>
                    ))}
                    {width <= 768 && (
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            onClick={() =>
                              setDataToMap((prev) =>
                                prev?.map((obj) => {
                                  return {
                                    ...obj,
                                    rowExpanded:
                                      obj?.id === d?.id
                                        ? !obj?.rowExpanded
                                        : obj?.rowExpanded,
                                  };
                                })
                              )
                            }
                            className={`${Styles.expandButton} ${
                              d.rowExpanded ? Styles.rotate : ""
                            }`}
                          >
                            <Icon icon={"keyboard_arrow_down"} size={20} />
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                  {d?.rowExpanded && (
                    <tr className={Styles.expandedRow}>
                      {withNumbering && <td />}
                      <td
                        colSpan={
                          withNumbering
                            ? columnsToMap?.length + 1
                            : columnsToMap?.length + 2
                        }
                      >
                        {expandComponent(d)}
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
          </tbody>
        </table>
        {isLoading && (
          <div className={Styles.loadingContainer}>
            <Lottie animationData={loadingClass} loop={true} />
            <p>Memuat Kontent</p>
          </div>
        )}
        {!isLoading && !data?.length && (
          <div
            style={{ position: "sticky", left: 0 }}
          >
            <TableEmptyHandler desription={descEmpty} title={descTitle} />
          </div>
        )}
      </div>
      {/* pagination below */}
      {withPagination && (
        <div className={Styles.paginationWrapper}>
          <Pagination
            total={totalData}
            limit={limit}
            setLimit={setLimit}
            selectedPage={page}
            setSelectedPage={setPage}
            totalPage={totalPage}
            siblingCount={1}
            isV2
            bgColor={"#fff"}
          />
        </div>
      )}
    </div>
  );
}
