import Styles from './style.module.scss'

export default function FormErrorMessage({
  message,
  fontSize = '13px'
}) {

  return (
    <div
      className={`${Styles.container} ${message?.length ? Styles.visible : Styles.hidden}`}
      style={{
        fontSize
      }}
    >
      *{message}
    </div>
  )
}