import IcoMoon from "react-icomoon";
import json from "@Assets/selection.json";

const Icon = ({
  icon,
  size,
  color,
  style,
  title,
  className,
  disableFill,
  removeInlineStyle,
  ...props
}) => (
  <IcoMoon
    icon={icon}
    size={size}
    color={color}
    style={style}
    title={title}
    className={className}
    disableFill={disableFill}
    removeInlineStyle={removeInlineStyle}
    iconSet={json}
    {...props}
  />
);

export default Icon;
