import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import FilePreview from "@Molecule/FilePreview";
import { fileBaseUrl } from "@Config/index";
import Icon from "@Atom/Icon";
import UploadFileSection from "@Molecule/UploadFileSection";
import { postAnswerUk } from "@Services/userSheet";
import { useNavigate } from "react-router-dom";
import Modal from "@Atom/Modal";
import FileUploadSuccess from "@Molecule/FileUploadSuccessModal";
import UkAgreement from "@Molecule/UkAgreementModal";
import { translateError } from "@Helpers/translateError";
import NextPrevButton from "@Molecule/NextPrevButton";
import useQuery from "@Hooks/useQuery";
import { transformStringToLinks } from "@Helpers/transformStringToLinks";
import { addLeadingZero } from "@Helpers/addLeadingZero";

export default function SkillTest({ data, userSheet }) {
  const [finishUploadFile, setFinishUploadFile] = useState(false);
  const [errorUploadFile, setErrorUploadFile] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState();
  const navigate = useNavigate();
  const query = useQuery();
  const title = query.get("t");

  const calculateTimeLeft = () => {
    const difference =
      +new Date(userSheet.data.skillTest.expiryDate) - +new Date();
    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    const timerAction = () => {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearTimeout(timer);
    };
    if (userSheet.data.skillTest.answer.length < 1) {
      timerAction();
    }
  });

  useEffect(() => {
    if (!userSheet.data.skillTest.expiryDate && data.platform === "VIDEO") {
      setAgreementModal(true);
    }
  }, [data.platform, userSheet.data]);

  const handleSubmit = async (e) => {
    const file = e.target.files[0];
    const maxSize = 10 * 1024 * 1024;

    if (file && file.size < maxSize) {
      const dataToSend = {
        userSheetID: userSheet.data._id,
        files: file,
      };
      try {
        const res = await postAnswerUk(dataToSend);
        if (res.status) {
          setFinishUploadFile(true);
          navigate("", { replace: true });
        }
      } catch (error) {
        setErrorUploadFile(translateError(error.response.data.error));
      }
    } else {
      setErrorUploadFile(
        "File terlalu besar. Batas maksimum untuk unggahan adalah 10 MB. Harap periksa ukuran file Anda dan coba lagi."
      );
    }
  };


  return (
    <div className={Styles.container}>
      {userSheet.data.skillTest.answer.length < 1 &&
        data.platform === "VIDEO" && (
          <div className={Styles.notes}>
            <Icon icon={"vector"} size={40} color={"#ECBE6C"} />
            <div className={Styles.text}>
              <span>Perhatian</span>
              <p>
                Tugas uji keterampilan harus dikumpulkan maksimal 7x24 jam. Jika
                melewati batas waktu tersebut, maka insentif Prakerjamu akan
                hangus.
              </p>
            </div>
            <div className={Styles.timeLeft}>
              <span>Waktu tersisa</span>
              <p>
                {addLeadingZero(
                  (timeLeft?.hours || "00") +
                    ":" +
                    (timeLeft?.minutes || "00") +
                    ":" +
                    (timeLeft?.seconds || "00")
                )}
              </p>
            </div>
          </div>
        )}
      <div className={Styles.contentWrapper}>
        <div className={Styles.titleWrapper}>
          <p>Uji Keterampilan</p>
        </div>
        <div className={Styles.filePreviewSection}>
          <FilePreview
            fileUrl={
              data?.trial?.hintURL?.includes("https")
                ? data?.trial?.hintURL
                : fileBaseUrl + data?.trial?.hintURL
            }
            type={data?.trial?.hintFormat === "pdf" ? "DOCUMENT" : "VIDEO"}
          />
        </div>

        <NextPrevButton
          nextText="Lanjut Isi Reflective Journal"
          prevText="Sebelumnya"
          disabledNext={userSheet.data.skillTest.answer.length < 1}
          handleNext={() =>
            navigate(`/classes/learn/${data._id}/journal?t=${title}`)
          }
          handlePrev={() =>
            navigate(`/classes/learn/${data._id}/postTest?t=${title}`)
          }
        />

        <div className={Styles.sectionWrapper}>
          <div className={Styles.titleSection}>
            <p>Deskripsi</p>
          </div>
          <p className={Styles.desc}>
            {transformStringToLinks(data.trial.hintDescription)}
          </p>
        </div>
        <div className={Styles.sectionWrapper}>
          <div className={Styles.titleSection}>
            <p>Upload Hasil Uji Keterampilan</p>
          </div>
          <div className={Styles.listWrapper}>
            <div className={Styles.list}>
              <Icon icon={"arrow_right"} size={24} color={"#9360A8"} />
              <p>Berkas yang di upload akan dinilai oleh fasilitator.</p>
            </div>
            <div className={Styles.list}>
              <Icon icon={"arrow_right"} size={24} color={"#9360A8"} />
              <p>
                Berkas dapat di pecah menjadi beberapa bagian. Total akumulasi
                ukuran file maksimal 10mb.
              </p>
            </div>
            <div className={Styles.list}>
              <Icon icon={"arrow_right"} size={24} color={"#9360A8"} />
              <p>Digunakan 0 MB, Sisa 150 MB</p>
            </div>
          </div>
        </div>
        <div className={Styles.filesWrapper}>
          {userSheet.data?.skillTest?.answer?.map((el, idx) => (
            <div key={idx} className={Styles.fileUploaded}>
              <UploadFileSection
                file={fileBaseUrl + el}
                fileName={el}
                handleChangeFile={handleSubmit}
                handleEdit={false}
                withLabel={idx === 0}
              />
            </div>
          ))}
          <UploadFileSection
            file={null}
            fileName={null}
            handleChangeFile={handleSubmit}
            withLabel={false}
          />
        </div>
        {userSheet.data.skillTest.feedback && (
          <div className={Styles.scoreSection}>
            <div className={Styles.sectionTitle}>
              <p>Hasil Uji Keterampilan</p>
            </div>
            <div className={Styles.sectionBody}>
              <div className={Styles.wrapScoreResult}>
                <div
                  className={Styles.scoreText}
                  score={userSheet.data.score.skillTest}
                ></div>
              </div>
              <div>
                <p>Feedback</p>
                <p>{userSheet.data.skillTest.feedback}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal isOpen={finishUploadFile}>
        <FileUploadSuccess
          text={"File Uji Keterampilan berhasil diunggah"}
          onClose={() => setFinishUploadFile(false)}
        />
      </Modal>
      <Modal isOpen={errorUploadFile}>
        <FileUploadSuccess
          text={errorUploadFile}
          onClose={() => setErrorUploadFile(false)}
          status="reject"
          headText="File Gagal Diunggah"
        />
      </Modal>
      <Modal isOpen={agreementModal}>
        <UkAgreement data={userSheet.data} setModal={setAgreementModal} />
      </Modal>
    </div>
  );
}
