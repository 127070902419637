import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";

export default function TransparentSearchbar({
  value,
  setValue = () => {},
  placeholder = "Search",
  className = "",
  width = "280px",
  ...props
}) {
  return (
    <div
      {...props}
      style={{ width }}
      className={`${Styles.input} ${className}`}
    >
      <input
        value={value}
        onChange={(e) => setValue(e?.target?.value)}
        placeholder={placeholder}
      />
      <Icon icon={"search-normal"} size={20} />
    </div>
  );
}
