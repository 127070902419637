import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Text from "@Atom/Text";
import Modal from "@Atom/Modal";
import RadioButtonOption from "@Molecule/RadioButtonOption";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import close from "../../../assets/Icon/closeToastIcon.svg";
import { useLocation } from "react-router-dom";
export default function ModalPopUpQuiz({
  question = "Apakah User Experience itu lebih penting dibandingkan User Interface saat melakukan design untuk suatu produk digital?",
  answerQuestion,
  isCorrect,
  isWrong,
  onClickIsCorrect,
  onClickIsWrong,
  isOpen,
  onCloseQuiz,
  submitQuiz,
}) {
  const [toastIsWrong, setToastIsWrong] = useState(false);
  const [triggerButton, setTriggerButton] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const {pathname} = useLocation()

  const handleSubmit = () => {
    setIsDone(true);
    if ((answerQuestion && isCorrect) || (isWrong && !answerQuestion)) {
      setTriggerButton(true);
    } else {
      setToastIsWrong(true);
    }
    submitQuiz();
  };

  useEffect(() => {
    setToastIsWrong(false);
    setTriggerButton(false);
    setIsDone(false);
  }, [pathname]);

  return (
    <Modal isOpen={isOpen}>
      <div
        className={styles.container}
        isCorrect={triggerButton ? "true" : "false"}
      >
        <Text color={"#000"} size={"l"} width={"medium"}>
          Pop Up Kuis
        </Text>
        <div
          color={"#000"}
          size={"m"}
          width={"regular"}
          className={styles.question}
        >
          {question}
        </div>
        <div className={styles.radioText}>
          <RadioButtonOption
            isSelected={isCorrect}
            onClick={onClickIsCorrect}
            textRadio={"Benar"}
          />
          <RadioButtonOption
            isSelected={isWrong}
            onClick={onClickIsWrong}
            textRadio={"Salah"}
          />
        </div>

        {triggerButton ? (
          <div className={styles.isCorrectToast}>
            <Icon
              color={"white"}
              icon={"check-circle"}
              size={"24"}
              className={styles.toastIcon}
            />
            <span> Selamat Jawaban Kamu Benar </span>
          </div>
        ) : (
          toastIsWrong && (
            <div className={styles.isWrongToast}>
              <img src={close} alt="" width={"24px"} height={"24px"} />
              <span>Mohon maaf jawaban kamu salah, lebih teliti lagi ya!</span>
            </div>
          )
        )}

        <div className={styles.button}>
          <Button
            disabled={isCorrect || isWrong ? false : true}
            onClick={isDone ? onCloseQuiz : handleSubmit}
            text={isDone ? "Tutup" : "Submit"}
          />
        </div>
      </div>
    </Modal>
  );
}
