import React from "react";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import PopupAnswer from "@Atom/PopupAnswer";
import SideActions from "@Atom/SideActions";
import { makeRandomString } from "@Helpers/makeRandomString";
import InputField, { InputTitle } from "@Molecule/InputField";
import QuestionGenerator from "@Molecule/QuestionGenerator";
import SectionTabs from "@Molecule/SectionTabs";
import ToolTip from "@Molecule/ToolTip/inde";
import { useCallback, useEffect, useMemo, useRef } from "react";
import Styles from "./style.module.scss";
import moment from "moment";
import { addDays } from "@Helpers/addDays";
import Switch from "@Atom/Switch";

export default function CurriculumsSection({
  form,
  setForm,
  type,
  activeSessionTabId,
  setActiveSessionTabId = () => {},
  activeIndex,
}) {
  const handleChangeForm = useCallback(
    (name, newVal, childName, id) => {
      if (!childName) {
        setForm((prev) => {
          return {
            ...prev,
            [name]: newVal,
          };
        });
      } else {
        setForm((prev) => {
          return {
            ...prev,
            [name]: prev[name]?.map((obj) => {
              if (obj?.id === id) {
                return {
                  ...obj,
                  [childName]: newVal,
                };
              } else {
                return obj;
              }
            }),
          };
        });
      }
    },
    [setForm]
  );

  useEffect(() => {
    if (form?.sessions?.length && !activeSessionTabId) {
      setActiveSessionTabId(form?.sessions[0]?.id);
    }
  }, [activeSessionTabId, form?.sessions, setActiveSessionTabId]);

  useEffect(() => {
    setActiveSessionTabId(form?.sessions[activeIndex]?.id);
  }, [activeIndex, form?.sessions, setActiveSessionTabId]);

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, [activeIndex]);

  const template = useMemo(() => {
    return [
      {
        title: "Jadwal Kursus",
        component: (
          <div className={Styles.schedulesWrapper}>
            {form?.schedules?.map((s, sI) => (
              <div key={sI} className={Styles.scheduleCard}>
                <div className={Styles.scHeader}>
                  <h4>Jadwal Batch {sI + 1}</h4>
                  {sI > 0 && (
                    <button>
                      <Icon
                        onClick={() => {
                          setForm((prev) => {
                            return {
                              ...prev,
                              schedules: prev?.schedules?.filter(
                                (obj) => obj?.id !== s?.id
                              ),
                            };
                          });
                        }}
                        icon={"delete-outline"}
                        color={"#D1421A"}
                        size={"22px"}
                      />
                    </button>
                  )}
                </div>
                {type === "OFFLINE" && (
                  <InputField
                    title={"Alamat Lokasi"}
                    value={s?.address}
                    setValue={(newVal) =>
                      handleChangeForm("schedules", newVal, "address", s?.id)
                    }
                    placeholder="Alamat lokasi"
                    required
                  />
                )}
                {/* {type !== "OFFLINE" && (
                  <InputField
                    title={
                      type === "OFFLINE" ? "Link Google Maps" : "Link Zoom"
                    }
                    value={s?.link}
                    setValue={(newVal) =>
                      handleChangeForm("schedules", newVal, "link", s?.id)
                    }
                    placeholder="Link zoom"
                    required
                  />
                )} */}
                {type === "OFFLINE" && (
                  <InputField
                    title={"Lokasi Kelas"}
                    value={s?.addressMaps || ""}
                    setValue={({ lng, lat, address }) => {
                      handleChangeForm("schedules", lng, "longitude", s?.id);
                      handleChangeForm("schedules", lat, "latitude", s?.id);
                      handleChangeForm(
                        "schedules",
                        address,
                        "addressMaps",
                        s?.id
                      );
                    }}
                    placeholder="Location"
                    readOnly
                    isPinPoint
                  />
                )}

                <div className={Styles.grid}>
                  <InputField
                    title={"Tanggal Mulai"}
                    value={s?.startDate}
                    setValue={(newVal) =>
                      handleChangeForm("schedules", newVal, "startDate", s?.id)
                    }
                    placeholder="Tanggal mulai"
                    isDate
                    required
                  />
                  <InputField
                    title={"Tanggal Selesai"}
                    value={s?.endDate}
                    setValue={(newVal) =>
                      handleChangeForm("schedules", newVal, "endDate", s?.id)
                    }
                    placeholder="Tanggal selesai"
                    isDate
                    required
                    min={
                      s?.startDate && moment(s?.startDate).format("YYYY-MM-DD")
                    }
                    max={moment(addDays(s?.startDate, 30)).format("YYYY-MM-DD")}
                  />
                </div>
                <div className={Styles.grid}>
                  <InputField
                    title={"Jam Mulai"}
                    value={s?.startTime}
                    setValue={(newVal) =>
                      handleChangeForm("schedules", newVal, "startTime", s?.id)
                    }
                    placeholder="Jam mulai"
                    isTime
                  />
                  <InputField
                    title={"Jam Selesai"}
                    value={s?.finishTime}
                    setValue={(newVal) =>
                      handleChangeForm("schedules", newVal, "finishTime", s?.id)
                    }
                    placeholder="Jam selesai"
                    isTime
                  />
                </div>
              </div>
            ))}
            <Button
              text={"Tambah Tanggal"}
              variant="text"
              startIcon={"plus"}
              iconSize={"11px"}
              style={{ alignSelf: "flex-start", padding: "0px" }}
              onClick={() =>
                setForm((prev) => {
                  return {
                    ...prev,
                    schedules: [
                      ...prev?.schedules,
                      {
                        id: makeRandomString(5),
                        address: prev?.schedules[0].address,
                        longitude: prev?.schedules[0].longitude,
                        latitude: prev?.schedules[0].latitude,
                        link: "",
                        startDate: "",
                        endDate: "",
                        startTime: "",
                        endTime: "",
                      },
                    ],
                  };
                })
              }
            />
          </div>
        ),
      },
      {
        title: "",
        component: (
          <div className={Styles.sessionsWrapper}>
            <div className={Styles.sessionTabs}>
              <SectionTabs
                tabs={form?.sessions}
                titleDenom={"Bagian"}
                activeTabId={activeSessionTabId}
                setActiveTabId={setActiveSessionTabId}
                onAdd={() =>
                  setForm((prev) => {
                    return {
                      ...prev,
                      sessions: [
                        ...prev?.sessions,
                        {
                          id: makeRandomString(5),
                          title: "",
                          description: "",
                          subjects: [
                            {
                              id: 1,
                              title: "",
                              file: null,
                              supportingFile: null,
                              popupQuiz: {
                                quiz: "",
                                isCorrect: true,
                              },
                            },
                          ],
                          quizes: [],
                          assignmentGuide: null,
                          assignmentDescription: "",
                        },
                      ],
                    };
                  })
                }
              />
              <SideActions
                className={Styles.sideActions}
                options={[
                  {
                    id: makeRandomString(5),
                    icon: "document-normal",
                    text: "Tambah Materi",
                    onClick: () => {
                      const newSubjects = [
                        ...form?.sessions[activeIndex]?.subjects,
                        {
                          id: makeRandomString(5),
                          title: "",
                          file: null,
                          supportingFile: null,
                          type: "MATTER",
                          popupQuiz: {
                            quiz: "",
                            isCorrect: true,
                            active: false,
                          },
                        },
                      ];
                      handleChangeForm(
                        "sessions",
                        newSubjects,
                        "subjects",
                        form?.sessions[activeIndex]?.id
                      );
                    },
                  },
                  {
                    id: makeRandomString(5),
                    icon: "playlist-add-check",
                    text: "Tambah Asesmen Sumatif Teori",
                    onClick: () => {
                      const newQuizes = [
                        ...form?.sessions[activeIndex]?.subjects,
                      ];
                      newQuizes.push({
                        id: makeRandomString(5),
                        title: "",
                        type: "QUIZ",
                        questions: [
                          {
                            id: makeRandomString(5),
                            type: "Pilihan Ganda",
                            question: "",
                            score: "",
                            options: [
                              {
                                id: makeRandomString(5),
                                option: "",
                                isCorrect: true,
                              },
                              {
                                id: makeRandomString(5),
                                option: "",
                                isCorrect: false,
                              },
                              {
                                id: makeRandomString(5),
                                option: "",
                                isCorrect: false,
                              },
                              {
                                id: makeRandomString(5),
                                option: "",
                                isCorrect: false,
                              },
                            ],
                          },
                        ],
                      });

                      handleChangeForm(
                        "sessions",
                        newQuizes,
                        "subjects",
                        form?.sessions[activeIndex]?.id
                      );
                    },
                  },
                  {
                    id: makeRandomString(5),
                    icon: "book-3",
                    text: "Asesmen Berbasis Proyek",
                    onClick: () => {
                      const newSubjects = [
                        ...form?.sessions[activeIndex]?.subjects,
                        {
                          id: makeRandomString(5),
                          file: null,
                          description: "",
                          type: "ABP",
                        },
                      ];
                      handleChangeForm(
                        "sessions",
                        newSubjects,
                        "subjects",
                        form?.sessions[activeIndex]?.id
                      );
                    },
                  },
                  // {
                  //   id: makeRandomString(5),
                  //   icon: "Exam",
                  //   text: "Tambah Asesmen",
                  //   onClick: () => {
                  //     const newSubjects = [
                  //       ...form?.sessions[activeIndex]?.subjects,
                  //       {
                  //         id: makeRandomString(5),
                  //         file: null,
                  //         description: "",
                  //         type: "ASSESSMENT",
                  //       },
                  //     ];
                  //     handleChangeForm(
                  //       "sessions",
                  //       newSubjects,
                  //       "subjects",
                  //       form?.sessions[activeIndex]?.id
                  //     );
                  //   },
                  // },
                ]}
              />
            </div>
            <div className={Styles.sessionHeader}>
              <div className={Styles.autoScroll} ref={targetElement} />
              {activeIndex > 4 && (
                <button>
                  <Icon
                    onClick={() => {
                      setForm((prev) => {
                        return {
                          ...prev,
                          sessions: prev?.sessions?.filter(
                            (obj) => obj?.id !== form?.sessions[activeIndex]?.id
                          ),
                        };
                      });
                    }}
                    icon={"delete-outline"}
                    color={"#D1421A"}
                    size={"22px"}
                  />
                </button>
              )}
            </div>
            {form?.sessions?.map((sF, sFI) => (
              <div
                key={sFI}
                style={{ display: sFI === activeIndex ? "flex" : "none" }}
                className={Styles.sessionFields}
              >
                <div className={Styles.section}>
                  <div className={Styles.sectionHeader}>
                    <InputTitle
                      title={`Video Pembukaan`}
                      className={Styles.sectionTitle}
                    />
                  </div>
                  <div className={Styles.sectionFields}>
                    <InputField
                      title={"Video Perkenalan"}
                      value={sF?.introduction}
                      setValue={(newVal) =>
                        handleChangeForm(
                          "sessions",
                          newVal,
                          "introduction",
                          form?.sessions[activeIndex]?.id
                        )
                      }
                      placeholder="Masukkan link video"
                      speed={30}
                      isVideoOnly={true}
                      isLink={type === "VIDEO"}
                      required
                    />
                  </div>
                </div>
                <div className={Styles.section}>
                  <div className={Styles.sectionHeader}>
                    <InputTitle
                      title={`Detail Bagian ${sFI + 1}`}
                      className={Styles.sectionTitle}
                    />
                  </div>
                  <div className={Styles.sectionFields}>
                    <InputField
                      title={"Judul Bagian"}
                      value={sF?.title}
                      setValue={(newVal) =>
                        handleChangeForm(
                          "sessions",
                          newVal,
                          "title",
                          form?.sessions[activeIndex]?.id
                        )
                      }
                      placeholder="Judul Bagian"
                      required
                    />
                    <InputField
                      title={"Deskripsi"}
                      value={form?.sessions[activeIndex]?.description}
                      setValue={(newVal) =>
                        handleChangeForm(
                          "sessions",
                          newVal,
                          "description",
                          form?.sessions[activeIndex]?.id
                        )
                      }
                      placeholder="Deskripsi"
                      required
                    />
                    {type === "WEBINAR" && (
                      <InputField
                        title={"Link Zoom"}
                        value={form?.sessions[activeIndex]?.meetURL}
                        setValue={(newVal) =>
                          handleChangeForm(
                            "sessions",
                            newVal,
                            "meetURL",
                            form?.sessions[activeIndex]?.id
                          )
                        }
                        placeholder="Link zoom"
                        required
                      />
                    )}
                  </div>
                </div>
                {sF?.subjects?.map((sb, sbI) => (
                  <React.Fragment>
                    {sb.type === "MATTER" && (
                      <div
                        key={sbI}
                        className={`${Styles.section} ${Styles.subject}`}
                      >
                        <div className={Styles.sectionHeader}>
                          <InputTitle
                            title={`Materi ${
                              sF.subjects
                                .filter((el) => el.type === "MATTER")
                                .findIndex((el) => el.id === sb.id) + 1
                            } `}
                            className={Styles.sectionTitle}
                            required
                          />

                          <button>
                            <Icon
                              onClick={() => {
                                const newSubjects = form?.sessions[
                                  activeIndex
                                ]?.subjects?.filter(
                                  (obj) => obj?.id !== sb?.id
                                );
                                handleChangeForm(
                                  "sessions",
                                  newSubjects,
                                  "subjects",
                                  form?.sessions[activeIndex]?.id
                                );
                              }}
                              icon={"delete-outline"}
                              color={"#D1421A"}
                              size={"22px"}
                            />
                          </button>
                        </div>

                        <div className={Styles?.sectionFields}>
                          <InputField
                            title={"Judul Materi"}
                            value={sb?.title}
                            setValue={(newVal) => {
                              const newSubjects = sF?.subjects?.map((obj) => {
                                if (obj?.id === sb?.id) {
                                  return {
                                    ...obj,
                                    title: newVal,
                                  };
                                }
                                return obj;
                              });
                              handleChangeForm(
                                "sessions",
                                newSubjects,
                                "subjects",
                                sF?.id
                              );
                            }}
                            placeholder="Judul materi"
                            required
                          />
                          <InputField
                            title={"File Materi"}
                            value={sb?.file}
                            setValue={(newVal) => {
                              const newSubjects = sF?.subjects?.map((obj) => {
                                if (obj?.id === sb?.id) {
                                  return {
                                    ...obj,
                                    file: newVal,
                                  };
                                }
                                return obj;
                              });
                              handleChangeForm(
                                "sessions",
                                newSubjects,
                                "subjects",
                                sF?.id
                              );
                            }}
                            // placeholder='Pilih file materi'
                            placeholder="Masukkan link materi"
                            speed={30}
                            isFile={type !== "VIDEO"}
                            isPDFOnly={type === "VIDEO" ? false : true}
                            isVideoOnly={type === "VIDEO" ? true : false}
                            // forceConvertFile
                            isLink={type === "VIDEO"}
                            required
                          />
                          <InputField
                            title={"File Materi Penunjang"}
                            titleAdornment={
                              <ToolTip
                                headerText="File Materi Penunjang"
                                description="Silahkan upload file materi utama terlebih dahulu, baru Anda akan bisa menambahkan file materi penunjang."
                              >
                                <Icon
                                  icon={"info-circle-2"}
                                  size={16}
                                  color={"#9E9E9E"}
                                  style={{ cursor: "pointer" }}
                                />
                              </ToolTip>
                            }
                            value={sb?.supportingFile}
                            setValue={(newVal) => {
                              const newSubjects = form?.sessions[
                                activeIndex
                              ]?.subjects?.map((obj) => {
                                if (obj?.id === sb?.id) {
                                  return {
                                    ...obj,
                                    supportingFile: newVal,
                                  };
                                }
                                return obj;
                              });
                              handleChangeForm(
                                "sessions",
                                newSubjects,
                                "subjects",
                                sF?.id
                              );
                            }}
                            placeholder="Pilih file materi penunjang"
                            disabled={!sb?.file}
                            isPDFOnly
                            speed={30}
                            isFile
                          />
                          {type === "VIDEO" && (
                            <div className={Styles.popUpQuizSwitcher}>
                              <Switch
                                isChecked={sb?.popupQuiz?.active}
                                setIsChecked={() => {
                                  const newSubjects = sF?.subjects?.map(
                                    (obj) => {
                                      if (obj?.id === sb?.id) {
                                        return {
                                          ...obj,
                                          popupQuiz: {
                                            ...obj?.popupQuiz,
                                            active: !obj?.popupQuiz?.active,
                                          },
                                        };
                                      }
                                      return obj;
                                    }
                                  );

                                  handleChangeForm(
                                    "sessions",
                                    newSubjects,
                                    "subjects",
                                    sF?.id
                                  );
                                }}
                              />
                              <p>Aktifkan Pop Up Kuis</p>
                            </div>
                          )}
                          {type === "VIDEO" && sb?.popupQuiz?.active && (
                            <div className={Styles.popupQuiz}>
                              <InputField
                                title={"Pop Up Kuis"}
                                placeholder="Pertanyaan"
                                value={sb.popupQuiz?.quiz}
                                setValue={(newVal) => {
                                  const newSubjects = sF?.subjects?.map(
                                    (obj) => {
                                      if (obj?.id === sb?.id) {
                                        return {
                                          ...obj,
                                          popupQuiz: {
                                            ...obj?.popupQuiz,
                                            quiz: newVal,
                                          },
                                        };
                                      }
                                      return obj;
                                    }
                                  );

                                  handleChangeForm(
                                    "sessions",
                                    newSubjects,
                                    "subjects",
                                    sF?.id
                                  );
                                }}
                                isTextArea
                                // required={
                                //   sFI !== 0
                                //   ||
                                //   (sFI === 0 && sbI > 2)
                                // }
                              />
                              <div className={Styles.answers}>
                                <InputTitle
                                  title="Jawaban Pertanyaan"
                                  required={sb?.popupQuiz?.quiz}
                                />
                                <PopupAnswer
                                  isCorrect={sb?.popupQuiz?.isCorrect}
                                  setIsCorrect={(newVal) => {
                                    const newSubjects = sF?.subjects?.map(
                                      (obj) => {
                                        if (obj?.id === sb?.id) {
                                          return {
                                            ...obj,
                                            popupQuiz: {
                                              ...obj?.popupQuiz,
                                              isCorrect: newVal,
                                            },
                                          };
                                        }
                                        return obj;
                                      }
                                    );

                                    handleChangeForm(
                                      "sessions",
                                      newSubjects,
                                      "subjects",
                                      sF?.id
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {sb.type === "QUIZ" && (
                      <div
                        key={sbI}
                        className={`${Styles.section} ${Styles.subject}`}
                      >
                        <div className={Styles.sectionHeader}>
                          <InputTitle
                            title={`Asesmen Sumatif Teori`}
                            className={Styles.sectionTitle}
                            required
                          />

                          <button>
                            <Icon
                              onClick={() => {
                                const newSubjects = sF?.subjects?.filter(
                                  (obj) => obj?.id !== sb?.id
                                );
                                handleChangeForm(
                                  "sessions",
                                  newSubjects,
                                  "subjects",
                                  form?.sessions[activeIndex]?.id
                                );
                              }}
                              icon={"delete-outline"}
                              color={"#D1421A"}
                              size={"22px"}
                            />
                          </button>
                        </div>

                        <div className={Styles?.sectionFields}>
                          <InputField
                            title={"Judul Kuis"}
                            value={sb?.title}
                            setValue={(newVal) => {
                              const newQuizes = sF?.subjects?.map((obj) => {
                                if (obj?.id === sb?.id) {
                                  return {
                                    ...obj,
                                    title: newVal,
                                  };
                                }
                                return obj;
                              });
                              handleChangeForm(
                                "sessions",
                                newQuizes,
                                "subjects",
                                form?.sessions[activeIndex]?.id
                              );
                            }}
                            placeholder="Judul kuis"
                          />
                          {sb?.questions?.map((q, qI) => (
                             <QuestionGenerator
                             key={qI}
                             question={`Soal ${qI + 1}`}
                             type={q.type}
                             setType={(e) => {
                               const newQuestions = sb?.questions?.map(
                                 (obj) => {
                                   if (obj?.id === q?.id) {
                                     return {
                                       ...obj,
                                       score: "",
                                       options: [
                                         {
                                           id: makeRandomString(5),
                                           option: "",
                                           isCorrect: true,
                                           image: null,
                                         },
                                         {
                                           id: makeRandomString(5),
                                           option: "",
                                           isCorrect: false,
                                           image: null,
                                         },
                                         {
                                           id: makeRandomString(5),
                                           option: "",
                                           isCorrect: false,
                                           image: null,
                                         },
                                         {
                                           id: makeRandomString(5),
                                           option: "",
                                           isCorrect: false,
                                           image: null,
                                         },
                                       ],
                                       type: e,
                                     };
                                   }
                                   return obj;
                                 }
                               );
                               const newQuizes = form?.sessions[
                                 activeIndex
                               ]?.subjects?.map((obj) => {
                                 if (obj?.id === sb?.id) {
                                   return {
                                     ...obj,
                                     questions: newQuestions,
                                   };
                                 }
                                 return obj;
                               });
                               handleChangeForm(
                                 "sessions",
                                 newQuizes,
                                 "subjects",
                                 form?.sessions[activeIndex]?.id
                               );
                             }}
                             withDeleteOption={qI !== 0}
                             onDelete={() => {
                               const newQuizes = sF?.subjects?.map((obj) => {
                                 if (obj?.id === sb?.id) {
                                   return {
                                     ...obj,
                                     questions: obj?.questions?.filter(
                                       (obj) => obj?.id !== q?.id
                                     ),
                                   };
                                 }
                                 return obj;
                               });
                               handleChangeForm(
                                 "sessions",
                                 newQuizes,
                                 "subjects",
                                 form?.sessions[activeIndex]?.id
                               );
                             }}
                             valueTextEditor={q?.question}
                             setValueTextEditor={(newVal) => {
                               const newQuestions = sb?.questions?.map(
                                 (obj) => {
                                   if (obj?.id === q?.id) {
                                     return {
                                       ...obj,
                                       question: newVal,
                                     };
                                   }
                                   return obj;
                                 }
                               );
                               const newQuizes = form?.sessions[
                                 activeIndex
                               ]?.subjects?.map((obj) => {
                                 if (obj?.id === sb?.id) {
                                   return {
                                     ...obj,
                                     questions: newQuestions,
                                   };
                                 }
                                 return obj;
                               });
                               handleChangeForm(
                                 "sessions",
                                 newQuizes,
                                 "subjects",
                                 form?.sessions[activeIndex]?.id
                               );
                             }}
                             score={q.score}
                             image={q.image}
                             setScore={(score) => {
                               const newQuestions = sb?.questions?.map(
                                 (obj) => {
                                   if (obj?.id === q?.id) {
                                     return {
                                       ...obj,
                                       score,
                                     };
                                   }
                                   return obj;
                                 }
                               );
                               const newQuizes = form?.sessions[
                                 activeIndex
                               ]?.subjects?.map((obj) => {
                                 if (obj?.id === sb?.id) {
                                   return {
                                     ...obj,
                                     questions: newQuestions,
                                   };
                                 }
                                 return obj;
                               });
                               handleChangeForm(
                                 "sessions",
                                 newQuizes,
                                 "subjects",
                                 form?.sessions[activeIndex]?.id
                               );
                             }}
                             setImage={(image) => {
                               const newQuestions = sb?.questions?.map(
                                 (obj) => {
                                   if (obj?.id === q?.id) {
                                     return {
                                       ...obj,
                                       image,
                                     };
                                   }
                                   return obj;
                                 }
                               );
                               const newQuizes = form?.sessions[
                                 activeIndex
                               ]?.subjects?.map((obj) => {
                                 if (obj?.id === sb?.id) {
                                   return {
                                     ...obj,
                                     questions: newQuestions,
                                   };
                                 }
                                 return obj;
                               });
                               handleChangeForm(
                                 "sessions",
                                 newQuizes,
                                 "subjects",
                                 form?.sessions[activeIndex]?.id
                               );
                             }}
                             options={q?.options}
                             setOptions={(newOptions) => {
                               const newQuizes = sF?.subjects?.map((obj) => {
                                 if (obj?.id === sb?.id) {
                                   return {
                                     ...obj,
                                     questions: obj?.questions?.map((qs) => {
                                       if (qs?.id === q?.id) {
                                         return {
                                           ...qs,
                                           options: newOptions,
                                         };
                                       }
                                       return qs;
                                     }),
                                   };
                                 }
                                 return obj;
                               });
                               handleChangeForm(
                                 "sessions",
                                 newQuizes,
                                 "subjects",
                                 form?.sessions[activeIndex]?.id
                               );
                             }}
                             placeholderRadio={"Ketik opsi jawaban"}
                             readOnly={false}
                           />
                          ))}
                          <Button
                            text={"Tambah Soal"}
                            variant="outlined"
                            startIcon={"plus"}
                            iconSize={"11px"}
                            onClick={() => {
                              const newQuizes = sF?.subjects?.map((obj) => {
                                if (obj?.id === sb?.id) {
                                  return {
                                    ...obj,
                                    questions: [
                                      ...obj?.questions,
                                      {
                                        id: makeRandomString(5),
                                        type: "Pilihan Ganda",
                                        question: "",
                                        score: "",
                                        image: null,
                                        options: [
                                          {
                                            id: makeRandomString(5),
                                            option: "",
                                            isCorrect: true,
                                            image: null,
                                          },
                                          {
                                            id: makeRandomString(5),
                                            option: "",
                                            isCorrect: false,
                                            image: null,
                                          },
                                          {
                                            id: makeRandomString(5),
                                            option: "",
                                            isCorrect: false,
                                            image: null,
                                          },
                                          {
                                            id: makeRandomString(5),
                                            option: "",
                                            isCorrect: false,
                                            image: null,
                                          },
                                        ],
                                      },
                                    ],
                                  };
                                }
                                return obj;
                              });
                              handleChangeForm(
                                "sessions",
                                newQuizes,
                                "subjects",
                                form?.sessions[activeIndex]?.id
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {sb.type === "ABP" && (
                      <div className={Styles.section}>
                        <div className={Styles.sectionHeader}>
                          <InputTitle
                            title="Asesmen Berbasis Proyek"
                            className={Styles.sectionTitle}
                            // required
                          />

                          <button>
                            <Icon
                              onClick={() => {
                                const newSubjects = form?.sessions[
                                  activeIndex
                                ]?.subjects?.filter(
                                  (obj) => obj?.id !== sb?.id
                                );
                                handleChangeForm(
                                  "sessions",
                                  newSubjects,
                                  "subjects",
                                  form?.sessions[activeIndex]?.id
                                );
                              }}
                              icon={"delete-outline"}
                              color={"#D1421A"}
                              size={"22px"}
                            />
                          </button>
                        </div>
                        {/* <h5>{`Materi ${sI + 1}`}</h5> */}
                        <div className={Styles.sectionFields}>
                          <InputField
                            title={"Panduan Asesmen Berbasis Proyek"}
                            value={sb?.file}
                            setValue={(newVal) => {
                              const newSubjects = sF?.subjects?.map((obj) => {
                                if (obj?.id === sb?.id) {
                                  return {
                                    ...obj,
                                    file: newVal,
                                  };
                                }
                                return obj;
                              });
                              handleChangeForm(
                                "sessions",
                                newSubjects,
                                "subjects",
                                sF?.id
                              );
                            }}
                            placeholder="Pilih file panduan Asesmen Berbasis Proyek"
                            speed={30}
                            isFile
                            isPDFOnly
                            // forceConvertFile
                            // required
                          />
                          <InputField
                            title={"Deskripsi"}
                            value={sb.description}
                            setValue={(newVal) => {
                              const newSubjects = sF?.subjects?.map((obj) => {
                                if (obj?.id === sb?.id) {
                                  return {
                                    ...obj,
                                    description: newVal,
                                  };
                                }
                                return obj;
                              });
                              handleChangeForm(
                                "sessions",
                                newSubjects,
                                "subjects",
                                sF?.id
                              );
                            }}
                            placeholder="Deskripsi"
                            speed={30}
                            isTextArea
                            // required
                          />
                        </div>
                      </div>
                    )}
                    {sb.type === "ASSESSMENT" && (
                      <div className={Styles.section}>
                        <div className={Styles.sectionHeader}>
                          <InputTitle
                            title={`Asesmen ${
                              sF.subjects
                                .filter((el) => el.type === "ASSESSMENT")
                                .findIndex((el) => el.id === sb.id) + 1
                            } `}
                            className={Styles.sectionTitle}
                            // required
                          />
                          {sbI > 0 && (
                            <button>
                              <Icon
                                onClick={() => {
                                  const newSubjects = form?.sessions[
                                    activeIndex
                                  ]?.subjects?.filter(
                                    (obj) => obj?.id !== sb?.id
                                  );
                                  handleChangeForm(
                                    "sessions",
                                    newSubjects,
                                    "subjects",
                                    form?.sessions[activeIndex]?.id
                                  );
                                }}
                                icon={"delete-outline"}
                                color={"#D1421A"}
                                size={"22px"}
                              />
                            </button>
                          )}
                        </div>

                        {/* <h5>{`Materi ${sI + 1}`}</h5> */}
                        <div className={Styles.sectionFields}>
                          <InputField
                            title={"Panduan Asesmen"}
                            value={sb?.file}
                            setValue={(newVal) => {
                              const newSubjects = sF?.subjects?.map((obj) => {
                                if (obj?.id === sb?.id) {
                                  return {
                                    ...obj,
                                    file: newVal,
                                  };
                                }
                                return obj;
                              });
                              handleChangeForm(
                                "sessions",
                                newSubjects,
                                "subjects",
                                sF?.id
                              );
                            }}
                            placeholder="Pilih file panduan Asesmen"
                            speed={30}
                            isFile
                            isPDFOnly
                            // forceConvertFile
                            // required
                          />
                          <InputField
                            title={"Deskripsi"}
                            value={sb.description}
                            setValue={(newVal) => {
                              const newSubjects = sF?.subjects?.map((obj) => {
                                if (obj?.id === sb?.id) {
                                  return {
                                    ...obj,
                                    description: newVal,
                                  };
                                }
                                return obj;
                              });
                              handleChangeForm(
                                "sessions",
                                newSubjects,
                                "subjects",
                                sF?.id
                              );
                            }}
                            placeholder="Deskripsi"
                            speed={30}
                            isTextArea
                            // required
                          />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        ),
      },
    ];
  }, [
    activeIndex,
    activeSessionTabId,
    form?.schedules,
    form?.sessions,
    handleChangeForm,
    setActiveSessionTabId,
    setForm,
    type,
  ]);

  const templateToMap = useMemo(() => {
    if (type === "VIDEO") {
      return template?.filter((obj) => obj?.title !== "Jadwal Kursus");
    }
    return template;
  }, [template, type]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>{type === "VIDEO" ? "Kurikulum Kelas" : "Jadwal dan Kurikulum"}</h3>
        <span>{`Isi dan lengkapi data ${
          type !== "VIDEO" ? "jadwal dan " : ""
        }kurikulum untuk materi pembelajaran di dalam kelas ${type?.toLowerCase()}`}</span>
      </div>
      <div className={Styles.content}>
        {templateToMap?.map((t, tI) => (
          <div key={tI} className={Styles.each}>
            {(!!t?.title || !!t?.description) && (
              <div className={Styles.eachHeader}>
                {!!t?.title && <h4>{t?.title}</h4>}
                {!!t?.description && <span>{t?.description}</span>}
              </div>
            )}

            {t?.component}
          </div>
        ))}
      </div>
    </div>
  );
}
