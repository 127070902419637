import LoadingContent from "@Molecule/Loading";
import ClassesRules from "@Organism/LearnClass/Rules";
import React, { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { userCourseStore } from "store";

export default function RulesPage() {
  const { userSheetData } = userCourseStore();

  if (!userSheetData) {
    return <LoadingContent />;
  }
  return <ClassesRules data={userSheetData} userSheet={userSheetData} />;

  // return (
  //   <Suspense fallback={<p>loading....</p>}>
  //     <Await
  //       resolve={classData}
  //       errorElement={
  //         <div style={{ height: "100vh" }}>
  //           <p>error...</p>
  //         </div>
  //       }
  //     >
  //       {(dataClass) => (
  //         <Await
  //           resolve={userSheet}
  //           errorElement={
  //             <div style={{ height: "100vh" }}>
  //               <p>error...</p>
  //             </div>
  //           }
  //         >
  //           {(userSheet) => (
  //             <ClassesRules data={dataClass.data} userSheet={userSheet.data} />
  //           )}
  //         </Await>
  //       )}
  //     </Await>
  //   </Suspense>
  // );
}
