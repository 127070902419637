import React, { useEffect, useRef, useState } from "react";
import style from "./index.module.scss";
import RadioButton from "@Atom/RadioButton";
import Icon from "@Atom/Icon";
import useOutsideClick from "@Hooks/useOutsideClick";
import { fileBaseUrl } from "@Config/index";

export default function RadioTextArea({
  onClick,
  isSelected,
  value,
  onChange,
  placeholder = "Placeholder",
  name,
  readOnly = false,
  setImage,
  image,
}) {
  const textAreaRef = useRef(null);
  const [focus, setFocus] = useState(false);
  const ref = useRef();
  const containerRef = useRef();
  const boxOutsideClick = useOutsideClick(containerRef);

  useEffect(() => {
    if (boxOutsideClick && focus) {
      setFocus(false);
    }
  }, [boxOutsideClick, focus]);

  const textAreaAdjust = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "1px";
      textAreaRef.current.style.height = `${
        0 + textAreaRef.current.scrollHeight
      }px`;
    }
  };

  return (
    <div className={style.body}>
      <div
        className={style.container}
        onClick={() => setFocus(true)}
        ref={containerRef}
      >
        <RadioButton
          onClick={!readOnly ? onClick : () => {}}
          isSelected={!readOnly ? isSelected : false}
        />
        {readOnly ? (
          <div className={style.option}>{value || "-"}</div>
        ) : (
          <textarea
            ref={textAreaRef}
            onChange={onChange}
            value={value}
            name={name}
            cols="auto"
            rows="1"
            onFocus={() => {
              textAreaAdjust();
            }}
            onF
            onInput={() => textAreaAdjust()}
            onBlur={() => {
              textAreaAdjust();
            }}
            className={style.autoHeightTextarea}
            placeholder={placeholder}
            readOnly={readOnly}
          />
        )}

        {focus && (
          <Icon
            icon={"image"}
            size={20}
            style={{ cursor: "pointer" }}
            color={"#757575"}
            onClick={() => ref?.current?.click()}
          />
        )}
        {readOnly && isSelected && (
          <Icon icon={"checked"} size={20} color={"#42CAB4"} />
        )}
      </div>
      {image &&
        ((typeof image === "object" && image?.type) ||
          typeof image === "string") && (
          <div className={style.imageContainer}>
            <div className={style.imageWrapper}>
              {!readOnly && (
                <div className={style.deleteIcon}>
                  <Icon
                    icon={"bold-circle"}
                    size={24}
                    color={"#9360A8"}
                    onClick={() => setImage(null)}
                  />
                </div>
              )}
              <img
                alt=""
                src={
                  typeof image === "object"
                    ? URL.createObjectURL(image)
                    : fileBaseUrl + image
                }
              />
            </div>
          </div>
        )}
      <input
        ref={ref}
        type="file"
        accept={"image/*"}
        hidden
        onChange={(e) => {
          if (e?.target?.files?.length) {
            setImage(e?.target?.files[0]);
          }
        }}
      />
    </div>
  );
}
