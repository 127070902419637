import React from "react";
import styles from "./index.module.scss"
import Icon from "../Icon";
export default function CheckBox({ isChecked, onClick }) {
  return (
    <div>
      {isChecked ? (
        <div className={styles.checkboxFilled} onClick={onClick}>
        <Icon icon="checked" color={"white"} size="20" />
        </div>
      ) : (
        <div className={styles.checkboxOutline} onClick={onClick}>
            
        </div>
      )}
    </div>
  );
}
