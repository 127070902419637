import React, { useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Breadcrumbs from "@Atom/Breadcrumbs";
import useQuery from "@Hooks/useQuery";
import LoadingContent from "@Molecule/Loading";
import NotFound from "@Molecule/NotFound";
import InputField from "@Molecule/InputField";
import Avatar from "@Atom/Avatar";
import { fileBaseUrl } from "@Config/index";
import { useNavigate } from "react-router-dom";

export default function ClassByGradeYearList({ data, isLoading }) {
  const query = useQuery();
  const grade = query.get("grade");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const dataToMap = useMemo(() => {
    return data.filter((el) =>
      el.subjectName.toLowerCase().includes(search.toLowerCase())
    );
  }, [data, search]);

  return (
    <div className={Styles.container}>
      <Breadcrumbs data={[{ title: "Daftar Mapel" }, { title: grade }]} />
      <InputField
        width="100%"
        placeholder="Cari mapel"
        value={search}
        setValue={setSearch}
        className={Styles.searchbar}
        startIcon="search-normal"
        endIcon="none"
        isDropdown
        dropdownOptions={data.map((el) => el.subjectName)}
      />
      <div className={Styles.body}>
        {isLoading ? (
          <LoadingContent />
        ) : (
          <div className={Styles.bodyWrapper}>
            {dataToMap.length > 0 ? (
              dataToMap.map((el, idx) => (
                <div
                  className={Styles.classCard}
                  onClick={() => {
                    navigate(el._id);
                  }}
                  key={idx}
                >
                  <div className={Styles.cardImage}>
                    <div className={Styles.imageOverlay}>
                      <p>{el.subjectName}</p>
                    </div>
                    <img src={fileBaseUrl + el.asset.thumbnail.url} alt="" />
                  </div>
                  <div className={Styles.cardBody}>
                    <div className={Styles.cardText}>
                      <p>Dibuat Oleh</p>
                      <div className={Styles.teacher}>
                        <Avatar
                          iconName="user"
                          iconSize="20px"
                          size="24px"
                          className={Styles.icon}
                          imgUrl={fileBaseUrl + el.instructor.profilePicture}
                        />
                        <p>{el.instructor.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={Styles.emptHandleWrapper}>

                <NotFound
                  title="Mata Pelajaran Tidak Ditemukan"
                  desc="Mata pelajaran yang kamu cari belum ditambahkan oleh guru pelajaran tersebut"
                  isStudent={false}
                  startIcon={"add-circle"}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
