import InstructorDashboardClass from "@Organism/InstructorDashboard/Class";
import { getClassDashboardInstructor } from "@Services/userCourse";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader() {
  const getClassData = getClassDashboardInstructor();
  return defer({
    getData: getClassData,
  });
}
export default function InstructorDashboardClassPage() {
  const { getData } = useLoaderData();
  return (
    <Suspense fallback={<p>loading....</p>}>
      <Await
        resolve={getData}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <InstructorDashboardClass data={value.data.classes} />}
      </Await>
    </Suspense>
  );
}
