import React, { useState } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import TransparentSearchbar from "@Atom/TransparentSearchbar";
import ClassesCard from "@Molecule/ClassesCard";
import Button from "@Atom/Button";
import { useNavigate } from "react-router-dom";
import NotFound from "@Molecule/NotFound";
import { decryptStorageData } from "@Helpers/encryptDecrypt";

export default function InstructorDashboardClass({ data = [] }) {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = data.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const isNull = filteredData.length === 0 || data.length === 0;
  const user = decryptStorageData("user", localStorage);

  return (
    <div className={Styles.container}>
      <DoodleGradientCard>
        <div className={Styles.topCard}>
          <div className={Styles.leftSection}>
            <p className={Styles.title}>
              Selamat Datang <span> {user?.name} </span>
            </p>
            <p className={Styles.desc}>
              Berikut adalah Dashboard kelas, serta detail kelas untuk kamu
              melakukan absensi & penilaian
            </p>
            <div className={Styles.inputWrapper}>
              <TransparentSearchbar
                placeholder="Cari Kelasmu"
                width="100%"
                setValue={setSearchQuery}
                value={searchQuery}
              />
            </div>
          </div>
          <div className={Styles.rightSection}>
            {/* <Button
              variant="neutral-contained"
              text={"Tambah Kelas"}
              startIcon={"add"}
              onClick={() => navigate("/classes/new")}
              className={Styles.bannerAction}
            /> */}
          </div>
        </div>
      </DoodleGradientCard>
      <div className={isNull ? Styles.notFound : Styles.classCardWrapper}>
        {isNull ? (
          <div>
            <NotFound />
          </div>
        ) : (
          <>
            {filteredData?.map((item, idx) => (
              <ClassesCard
                key={idx}
                image={item.asset.thumbnail.imageURL}
                level={item.level}
                title={item.title}
                type={item.type}
                platform={item.platform}
                id={item.id}
                buttonText={item.isPublished ? "Edit Kelas" : "Lengkapi Kelas"}
                navigateTo={`/classes/edit/${item.platform.toLowerCase()}/${item._id}`}
                data={item}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}
