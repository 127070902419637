import React from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import Button from "@Atom/Button";

export default function FileUploadSuccess({
  text,
  onClose,
  status = "success",
  headText="File Berhasil Diunggah"
}) {
  return (
    <div className={Styles.container}>
      <p>{headText}</p>
      <div className={Styles.modalBody}>
        {status === "success" ? (
          <img alt="" src={Images.CHECKLIST} />
        ) : (
          <img alt="" src={Images.REJECTED} />
        )}
        <p>{text}</p>
      </div>
      <div className={Styles.modalAction}>
        <Button onClick={onClose} text={"Tutup"} variant="outlined" />
      </div>
    </div>
  );
}
