import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function Tag({
  value,
  onDelete = () => { }
}) {

  return (
    <div className={Styles.container}>
      <span>{value}</span>
      <button onClick={onDelete}>
        <Icon icon={'cross'} size={16} />
      </button>
    </div>
  )
}