import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import { fileBaseUrl } from "@Config/index";
import { transformStringToLinks } from "@Helpers/transformStringToLinks";

const descTemp = `Selamat datang di kelas online UI/UX Design 101! Kelas ini dirancang khusus untuk membekali Anda dengan pengetahuan mendalam tentang desain antarmuka (UI) dan pengalaman pengguna (UX). Dalam kelas ini, Anda akan memahami prinsip-prinsip dasar desain yang melibatkan pengguna, menciptakan antarmuka yang menarik, dan mengoptimalkan pengalaman pengguna untuk produk digital. Setiap sesi akan membahas topik-topik kunci seperti wireframing, prototyping, analisis pengguna, dan tren terkini dalam industri UI/UX.

Tidak peduli apakah Anda seorang pemula atau profesional yang ingin meningkatkan keterampilan, kelas ini akan memberikan wawasan yang dibutuhkan untuk merancang produk digital yang berhasil dan memuaskan pengguna. Bergabunglah dengan kami dalam menjelajahi dunia UI/UX, dan bersiaplah untuk mengubah cara Anda memandang dan mendekati desain digital. Mari kita bangun dasar yang kuat untuk menciptakan pengalaman pengguna yang luar biasa dan tampilan antarmuka yang menarik!

Dalam perjalanan ini, Anda juga akan mendapatkan pengalaman praktis dengan berbagai alat dan teknologi terkini yang digunakan dalam industri desain UI/UX. Mulai dari Adobe XD hingga Figma, Anda akan belajar bagaimana menggunakan perangkat lunak desain terbaik untuk membawa ide-ide kreatif Anda menjadi kenyataan. Selain itu, kelas ini menekankan aspek kolaboratif dalam desain, memberi Anda peluang untuk bekerja sama dengan sesama peserta untuk mengembangkan proyek-proyek praktis yang mencerminkan tantangan sehari-hari dalam industri ini.`;

export default function MaterialDesc({
  desc = descTemp,
  withAdditional = false,
  additional,
}) {
  return (
    <div className={Styles.container}>
      <div>
        <div className={Styles.titleWrapper}>
          <p>Deskripsi</p>
        </div>
        <div className={Styles.descWrapper}>
          <p>{transformStringToLinks(desc)}</p>
        </div>
      </div>
      {withAdditional && (
        <div>
          <div className={Styles.titleWrapper}>
            <p>Materi Penunjang</p>
          </div>
          <div className={Styles.additionalWrapper}>
            <div className={Styles.content}>
              <Icon icon={"file-pdf-box"} size={22} />
              <div className={Styles.title}>
                <p>Materi Penunjang</p>
                {/* <p>size</p> */}
              </div>
              <div>
                <div className={Styles.desktop}>
                  <Button
                    variant="outlined"
                    text={"Lihat File"}
                    onClick={() => window.open(fileBaseUrl + additional)}
                  />
                </div>
                <div className={Styles.mobile}>
                  <Button
                    variant="text"
                    startIcon={"download"}
                    onClick={() => window.open(fileBaseUrl + additional)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
