import React from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import notFoundImage from "@Assets/Images/notFound.png";
export default function NotFound({
  isStudent,
  onClick = () => {},
  title = "Sekarang kamu belum punya kelas aktif nih",
  desc = "Ayo beli kelas di skills.id dan mulai belajar bersama kami!",
  buttonText = "Cari Kelas",
  ...props
}) {
  console.log(props);
  return (
    <div className={Styles.wrapperNotFound}>
      <section>
        <div className={Styles.relativeElement}>
          <img src={notFoundImage} alt="" />
        </div>
        <div>
          <h1>{title}</h1>
          <p>{desc}</p>
        </div>
        {isStudent && (
          <div className={Styles.button}>
            <Button text={buttonText} onClick={onClick} {...props} />
          </div>
        )}
      </section>
    </div>
  );
}
