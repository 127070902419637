import React from "react";
import Styles from "./styles.module.scss";
import Images from "@Theme/Images";
import Button from "@Atom/Button";
import { useNavigate } from "react-router-dom";

export default function ExpiredLayout() {
  const navigate = useNavigate();
  return (
    <div className={Styles.body}>
      <div className={Styles.container}>
        <div className={Styles.imageWrapper}>
          <img alt="" src={Images.EXPIRED_VERCTOR} />
        </div>
        <div className={Styles.content}>
          <p>Tautan Tidak Berlaku</p>
          <p>
            Maaf, tautan yang Anda akses sudah tidak berlaku atau telah
            kedaluwarsa. Silakan buat permintaan baru melalui tautan berikut
          </p>
        </div>
        <div className={Styles.action}>
          <Button
            text={"Kembali ke Halaman Login"}
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    </div>
  );
}
