import AllCoursePage from "@Organism/Courses";
import { getAllCourses } from "@Services/course";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader() {
  return defer({
    packageCourseDetails: getAllCourses(),
  });
}

export default function CoursesPage() {
  const { packageCourseDetails } = useLoaderData();

  return (
    <Suspense fallback={<AllCoursePage isLoading />}>
      <Await resolve={packageCourseDetails} errorElement={<div>error</div>}>
        {(packageCourseDetails) => (
          <AllCoursePage data={packageCourseDetails?.data} />
        )}
      </Await>
    </Suspense>
  );
  return;
}
