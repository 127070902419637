import React from "react";
import Styles from "./style.module.scss";
import rainAnimation from "@Assets/Images/rain.gif";

export default function Score({ score }) {
  return (
    <div className={Styles.scoreWrapper} is-qualified={String(score >= 70)}>
      <div className={Styles.backgroundEclipse} />
      <img src={rainAnimation} alt="" />
      <div className={Styles.scoreText}>
        <p>{score}</p>
      </div>
    </div>
  );
}
