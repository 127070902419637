import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import Maps from "@Atom/Maps";
import Modal from "@Atom/Modal";
import Text from "@Atom/Text";
import { useState } from "react";
import Styles from "./style.module.scss";

export default function PinMaps({
  isOpen,
  setOpen,
  setCoords = () => {},
  setAddress = () => {},
}) {
  const [tempAddress, setTempAddress] = useState("");
  const [tempCoords, setTempCoords] = useState({});

  const handleSubmit = () => {
    setCoords({ ...tempCoords, address :tempAddress });
    setAddress();
    setOpen(false);
  };

  return (
    <Modal isOpen={isOpen}>
      <div className={Styles.modalContainer}>
        <div className={Styles.modalHeader}>
          <Text size={"m"} width={"medium"}>
            Set Pin Point
          </Text>
          <Icon icon={"cross"} size={20} onClick={() => setOpen(false)} />
        </div>
        <div>
          <Maps
            marker={tempCoords}
            setMarker={setTempCoords}
            setAddress={setTempAddress}
            withInput
          />
        </div>
        <div className={Styles.modalAction}>
          <Button
            variant="outlined"
            text="Kembali"
            onClick={() => setOpen(false)}
          />
          <Button variant="contained" text="Simpan" onClick={handleSubmit} />
        </div>
      </div>
    </Modal>
  );
}
