import React, { useRef } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import FileIcon from "@Assets/Icon/file_icon.svg";
import LinkIcon from "@Assets/Icon/link-square.svg";

export default function UploadFileSection({
  file,
  handleChangeFile,
  fileName,
  handleEdit = true,
  withLabel = true,
  handleInputLink = () => {},
  type,
  ...props
}) {
  const inputRef = useRef();
  return (
    <div className={Styles.container}>
      {withLabel && (
        <p className={Styles.label}>
          Upload File <span>*</span>
        </p>
      )}
      <input
        className={Styles.fileInput}
        id={"input-tpm"}
        type={"file"}
        hidden
        onChange={(e) => {
          handleChangeFile(e);
        }}
        ref={inputRef}
        {...props}
        maxSize="1024"
        // max
      />
      {file ? (
        <div className={Styles.fileWrapper}>
          <img src={type === "URL" ? LinkIcon : FileIcon} alt="" />
          {handleEdit ? (
            <div className={Styles.fileTextWrapper}>
              <p className={Styles.fileText}>{fileName}</p>
              <div
                className={Styles.fileAction}
                onClick={() => handleChangeFile(fileName)}
              >
                <Icon icon={"delete_outline"} color={"#D1421A"} size={20} />
                <p>Hapus</p>
              </div>
            </div>
          ) : (
            <p className={Styles.fileText}>{fileName}</p>
          )}
          <div className={Styles.fileSuccess}>
            <Icon icon={"checklist"} size={16} color={"#60AA15"} />
            <p>
              {type === "URL" ? "Link Berhasil Disimpan" : "Upload Berhasil"}
            </p>
          </div>
        </div>
      ) : (
        <div className={Styles.fileWrapper}>
          <Icon icon={"cloud-upload"} size={20} color={"#9360A8"} />
          <p className={Styles.placeholder}>
            Pilih file untuk hasil uji keterampilan
          </p>
          <div className={Styles.buttonWrapper}>
            <Button
              text={"Tulis Link"}
              variant="outlined"
              onClick={handleInputLink}
            />
            <Button
              text={"Pilih File"}
              onClick={() => {
                inputRef?.current?.click();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
