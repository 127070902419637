import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import { Editor } from "react-draft-wysiwyg";
// import draftToHtml from "draftjs-to-html";
// import htmlToDraft from "html-to-draftjs";
// import { ContentState, EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import bold from "@Assets/Icon/bold.svg";
import italic from "@Assets/Icon/italic.svg";
import underline from "@Assets/Icon/underline.svg";
import leftAlign from "@Assets/Icon/left-align.svg";
import centerAlign from "@Assets/Icon/center-align.svg";
import ordered from "@Assets/Icon/ordered.svg";
import unordered from "@Assets/Icon/unordered.svg";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

export default function TextEditor({ value, setValue, type = "full", isSticky = false }) {
  const getInitialState = (content) => {
    const blocksFromHtml = htmlToDraft(`<div>${content}</div>`);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHtml.contentBlocks,
      blocksFromHtml.entityMap
    );
    return EditorState.createWithContent(contentState);
  };
  const [editorState, setEditorState] = useState(getInitialState());

  useEffect(() => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (html !== value) {
      setEditorState(getInitialState(value))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])




  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setValue(html);
  };

  // function urltoFile(url, filename, mimeType) {
  //   return fetch(url)
  //     .then(function (res) {
  //       return res.arrayBuffer();
  //     })
  //     .then(function (buf) {
  //       return new File([buf], filename, { type: mimeType });
  //     });
  // }

  // async function getBase64(file) {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = async () => {
  //       resolve(reader.result);
  //     };
  //     var convertTofile = new File([file], "image");
  //     reader.readAsDataURL(convertTofile);
  //   });
  // }
  //   const uploadImageCallBack = async (file) => {
  //     return new Promise((resolve, reject) => {
  //       var reader = new FileReader();
  //       reader.onloadend = async () => {
  //         const mimeType = reader.result.split(":")[1].split(";")[0];
  //         const fileConverted = await urltoFile(reader.result, "image", mimeType);
  //         let convertTofile = new File([uploadedImage.current], "image");
  //         const compressedFile = await getBase64(convertTofile);
  //         resolve({ data: { link: compressedFile, src: compressedFile } });
  //       };
  //       return reader.readAsDataURL(file);
  //     });
  //   };
  //   const uploadCallback = (file) => {
  //     return new Promise((resolve, reject) => {
  //       var reader = new FileReader();

  //       reader.onloadend = function () {
  //         Meteor.call(
  //           "fileStorage.uploadFile",
  //           reader.result,
  //           file.name,
  //           file.type,
  //           (err, response) => {
  //             console.log(response);
  //             if (err) {
  //               reject(err);
  //             }

  //             resolve({ data: { link: response.data.url } });
  //           }
  //         );
  //       };

  //       reader.readAsDataURL(file);
  //     });
  //   };˝
  //   const blocksFromHTML = htmlToDraft(contractData?.template);
  //   const [contractCodeList, setContractCodeList] = useState([]);
  //   const [codeInputValue, setCodeInputValue] = useState("");
  //   const [loading, setLoading] = useState(false);

  //   const getInitialState = (content) => {
  //     const blocksFromHtml = htmlToDraft(`<div>${content}</div>`);
  //     const contentState = ContentState.createFromBlockArray(
  //       blocksFromHtml.contentBlocks,
  //       blocksFromHtml.entityMap
  //     );
  //     return EditorState.createWithContent(contentState);
  //   };

  const toolbarComponent = useMemo(() => {
    if (type === "full") {
      return {
        options: [
          "inline",
          // "blockType",
          // "fontSize",
          "textAlign",
          "list",
          "history",
          // "image",
          // "fontFamily",
        ],
        inline: {
          inDropdown: false,
          options: ["bold", "italic", "underline"],
          bold: { icon: bold, className: Styles.toolbarIcon },
          italic: { icon: italic, className: Styles.toolbarIcon },
          underline: { icon: underline, className: Styles.toolbarIcon },
        },

        list: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ["ordered", "unordered"],
          ordered: { icon: ordered, className: Styles.toolbarIcon },
          unordered: { icon: unordered, className: Styles.toolbarIcon },
        },
        textAlign: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ["left", "center"],
          left: { icon: leftAlign, className: Styles.toolbarIcon },
          center: { icon: centerAlign, className: Styles.toolbarIcon },
        },
        history: { inDropdown: false },
      };
    } else {
      return {
        options: [
          "inline",
          // "blockType",
          // "fontSize",
          // "textAlign",
          // "list",
          // "history",
          // "image",
          // "fontFamily",
        ],
        inline: {
          inDropdown: false,
          options: ["bold", "italic", "underline"],
          bold: { icon: bold, className: Styles.toolbarIcon },
          italic: { icon: italic, className: Styles.toolbarIcon },
          underline: { icon: underline, className: Styles.toolbarIcon },
        },

        // list: {
        //   inDropdown: false,
        //   className: undefined,
        //   component: undefined,
        //   dropdownClassName: undefined,
        //   options: ["ordered", "unordered"],
        //   ordered: { icon: ordered, className: Styles.toolbarIcon },
        //   unordered: { icon: unordered, className: Styles.toolbarIcon },
        // },
        // textAlign: {
        //   inDropdown: false,
        //   className: undefined,
        //   component: undefined,
        //   dropdownClassName: undefined,
        //   options: ["left", "center"],
        //   left: { icon: leftAlign, className: Styles.toolbarIcon },
        //   center: { icon: centerAlign, className: Styles.toolbarIcon },
        // },
        // history: { inDropdown: false },
      };
    }
  }, [type]);

  return (
    <div className={Styles.editorWrapper}>
      {/* <CustomToolbar /> */}
      <Editor
        editorState={editorState}
        // editorStyle={{margin:'0'}}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName="wrapper-class"
        editorClassName={Styles.editor}
        toolbarClassName={`${Styles.toolbar} ${isSticky ? Styles.sticky : ''}`}
        toolbar={toolbarComponent}
      />
    </div>
  );
}
