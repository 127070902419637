import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function Step({
  type = 'linear',
  title,
  isActive,
  isCompleted,
  onClick = () => { },
  disabled
}) {
  switch (type) {
    default:
      return (
        <Linear
          title={title}
          isActive={isActive}
          isCompleted={isCompleted}
          onClick={onClick}
          disabled={disabled}
        />
      )

  }
}

const Linear = ({
  title,
  isActive,
  isCompleted,
  onClick = () => { },
  disabled
}) => {
  return (
    <div className={`${Styles.linear} ${disabled ? Styles.disabled : ''} ${isActive ? Styles.active : ''} ${isCompleted ? Styles.completed : ''}`} onClick={!disabled ?onClick : ()=> {}}>
      <div className={Styles.line} />
      <p>
        {!!isCompleted && <Icon icon={'check-circle'} size={20} />}
        <span>{title}</span>
      </p>
    </div>
  )
}