import React, { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@Atom/Icon";
import useWindowSize from "@Hooks/useWindowSize";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Images from "@Theme/Images";
import Modal from "@Atom/Modal";
import Button from "@Atom/Button";
import InputField from "@Molecule/InputField";
import FormErrorMessage from "@Atom/FormErrorMessage";
import PhotoField from "@Atom/PhotoField";
import Footer from "@Molecule/Footer";
import { createTeacher, editTeacher, getTeachersData } from "@Services/admin";
import { fileBaseUrl } from "@Config/index";
import { translateError } from "@Helpers/translateError";

export default function AdminCreateTeacher({ isEdit }) {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const user = decryptStorageData("user", localStorage);
  const [success, setSuccess] = useState(false);
  const { id } = useParams();
  const [form, setForm] = useState({
    name: "",
    gender: "",
    email: "",
    img: "",
    avatar: "",
    phoneNumber: "",
    password: "",
    biography: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchDataTeacher = async () => {
    try {
      setLoading(true);
      const res = await getTeachersData(id);

      setForm({
        name: res.name,
        gender: res.gender === "MALE" ? "Laki-laki" : "Perempuan",
        biography: res.bio,
        email: res.email,
        phoneNumber: res?.phoneNumber.startsWith("62")
          ? res?.phoneNumber.slice(2)
          : res?.phoneNumber,
        avatar: fileBaseUrl + res.profilePicture,
        img: fileBaseUrl + res.detailPicture,
        password:res.password,
      });
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchDataTeacher();
    }
  }, [isEdit]);

  const handleChangeForm = useCallback((name, newVal) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: newVal,
      };
    });
  }, []);

  const handleSubmit = async () => {
    const dataToSend = {
      name: form.name,
      email: form.email,
      bio: form.biography,
      phoneNumber: "62" + form.phoneNumber,
      gender: form.gender === "Laki-laki" ? "MALE" : "FEMALE",
      password: form.password,
    };

    if (typeof form.img !== "string") {
      dataToSend.detailPicture = form.img;
      dataToSend.profilePicture = form.avatar;
    }

    try {
      setLoading(true);
      if (isEdit) {
        const res = await editTeacher(id, dataToSend);
      } else {
        const res = await createTeacher(dataToSend);
      }
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.message[0])
      setLoading(false);
      //   setConfirmEdit(false);
      setError(translateError(error.response.data.message[0]));
    }
  };

  console.log(success);

  const template = useMemo(() => {
    return [
      {
        title: "Informasi Akun",
        component: (
          <div className={Styles.informationSection}>
            <PhotoField
              value={form?.img}
              setValue={(newVal) => handleChangeForm("img", newVal)}
              setAvatar={(newVal) => handleChangeForm("avatar", newVal)}
              withDisplay
            />
            <div className={Styles.infoFields}>
              <div className={Styles.grid2}>
                <InputField
                  title={"Nama"}
                  value={form?.name}
                  setValue={(newVal) => handleChangeForm("name", newVal)}
                  placeholder="Masukkan nama siswa"
                  required
                />
                <InputField
                  title={"Jenis Kelamin"}
                  value={form?.gender}
                  setValue={(newVal) => handleChangeForm("gender", newVal)}
                  placeholder="Belum Dipilih"
                  required
                  isDropdown
                  dropdownOptions={["Laki-laki", "Perempuan"]}
                />
              </div>
              <InputField
                title={"No. Handphone"}
                required
                value={form?.phoneNumber}
                setValue={(newVal) => handleChangeForm("phoneNumber", newVal)}
                placeholder="Masukkan no hanphone instruktur"
                isPhoneNumber
              />
              <InputField
                title={"Email"}
                value={form?.email}
                setValue={(newVal) => handleChangeForm("email", newVal)}
                placeholder="Masukkan email"
                required
              />
              <InputField
                title={"Password"}
                value={form?.password}
                setValue={(newVal) => handleChangeForm("password", newVal)}
                placeholder="Masukkan email"
                required
                isPassword
              />
            </div>
          </div>
        ),
      },
      {
        title: "Biografi Guru",
        component: (
          <div>
            <InputField
              title={"Biografi"}
              value={form?.biography}
              setValue={(newVal) => handleChangeForm("biography", newVal)}
              placeholder="Tuliskan biografi guru disini"
              isTextArea
              required
            />
            <FormErrorMessage message={error} />
            <div className={Styles.submit}>
              <Button
                text={loading ? "Loading..." : "Simpan"}
                disabled={
                  !form?.name ||
                  !form?.email ||
                  !form?.phoneNumber ||
                  !form?.password ||
                  !form?.gender ||
                  !form?.img ||
                  !form?.biography ||
                  loading
                }
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, form, handleChangeForm, loading]);

  return (
    <div className={Styles.container}>
      <div className={Styles.breadcrumbsWrapper}>
        <div
          className={Styles.backIcon}
          onClick={() => navigate("/admin/teacher")}
        >
          <Icon size={16} icon={"chevron-left"} />
        </div>
        <div className={Styles.breadcrumbs}>
          <p>Daftar Guru</p>
          <Icon size={16} icon={"chevron-right"} />
          <p>{isEdit ? "Detail Guru" : "Tambah Guru"}</p>
        </div>
      </div>
      <div className={Styles.wrapper}>
        <DoodleGradientCard className={Styles.header}>
          <div className={Styles.title}>
            <h3>Selamat Datang {<span>{user?.name}</span>}</h3>
            <span>
              Silahkan lengkapi data dibawah ini untuk menambah guru baru
            </span>
          </div>
          {width > 768 && (
            <img className={Styles.img} src={Images.ID_CARD} alt="" />
          )}
        </DoodleGradientCard>
        <div className={Styles.content}>
          {template?.map((t, tI) => (
            <div key={tI} className={Styles.each}>
              <span>{t?.title}</span>
              {t?.component}
            </div>
          ))}
        </div>
      </div>
      <Modal isOpen={success}>
        <div className={Styles.successModal}>
          <div className={Styles.modalBody}>
            <p>
              {isEdit
                ? "Perubahan Berhasil Disimpan"
                : "Guru Berhasil Ditambahkan"}
            </p>
            <img src={Images.SUCCESS_MODAL} alt="" />
            <p>
              {isEdit
                ? "Data Guru telah berhasil diperbaharui"
                : `Guru ${form.name} ditambahkan.`}
            </p>
          </div>
          <div className={Styles.modalAction}>
            <Button text="Tutup" onClick={() => navigate("/admin/teacher")} />
          </div>
        </div>
      </Modal>
      <Footer />
    </div>
  );
}
