import React, { useState } from "react";
import Styles from "./style.module.scss";
import ClassesDesc from "@Molecule/ClassesDesc";
import Button from "@Atom/Button";
import Images from "@Theme/Images";
import Modal from "@Atom/Modal";
import JournalQuestions from "./JournalQuestions";
import { postAnswerSurvey } from "@Services/userSheet";
import { useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";
import NextPrevButton from "@Molecule/NextPrevButton";

const surveyQuestions = [
  "Sebutkan 3 (tiga) pelajaran yang paling berkesan untuk Anda selama mengikuti pelatihan?",
  "Jelaskan hal yang ingin Anda terapkan setelah mengikuti pelatihan ini?",
  "Berikan pendapatmu mengenai pengalaman belajar di kelas pelatihan ini dengan kami?",
  "Berikan pendapatmu mengenai hal yang dapat diperbaiki dari pelatihan ini?",
];

export default function ReflectiveJournal({ data, userSheet }) {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const query = useQuery();
  const title = query.get("t");



  const handleSubmitSurvey = async (answer) => {
    const dataToSend = {
      userSheetID: userSheet._id,
      survey: Object.values(answer).map((el, idx) => ({
        question: surveyQuestions[idx],
        answer: el,
      })),
    };
    try {
      const res = await postAnswerSurvey(dataToSend);
      if (res.status) {
        setModalOpen(false);
        navigate("", { replace: true });
      }
    } catch (error) {}
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.journalHeader}>
        <div className={Styles.leftSection}>
          <div className={Styles.desc}>
            <p>Reflective Journal</p>
            <p>
              Terima kasih atas kritik dan saran yang jujur dan membangun yang
              telah Anda berikan, untuk perbaikan kami di sesi berikutnya.
            </p>
          </div>
          <div>
            <Button
              text={
                userSheet.surveys.length > 0
                  ? "Kritik & Saran Telah Kami Terima"
                  : "Isi Reflective Journal"
              }
              variant="neutral-outlined"
              onClick={() => setModalOpen(true)}
              disabled={userSheet.surveys.length > 0}
            />
          </div>
        </div>
        <div className={Styles.rightSection}>
          <img src={Images.DOC_ICON} alt="icon" />
        </div>
      </div>
      <NextPrevButton
        nextText="Lihat Sertifikat"
        prevText="Sebelumnya"
        disabledPrev={userSheet.score.postTest < 59}
        handleNext={() =>
          navigate(`/classes/learn/${data._id}/certificate?t=${title}`)
        }
        handlePrev={() => navigate(`/classes/learn/${data._id}/uk?t=${title}`)}
      />
      <Modal isOpen={modalOpen}>
        <JournalQuestions
          questions={surveyQuestions}
          handleSubmit={(answer) => handleSubmitSurvey(answer)}
        />
      </Modal>
      <ClassesDesc data={data} />
    </div>
  );
}
