import ABPDetailLayout from "@Organism/InstructorDashboard/ABPDetail";
import { fetchInstructorClassProgressABP } from "@Services/instructor";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader(route) {
  const params = route.params;
  console.log(params.id);
  const getStudentAssesment = fetchInstructorClassProgressABP(
    params.userSheetId,
    params.sessionNum
  );
  return defer({
    studentAssesment: getStudentAssesment,
  });
}

export default function ABPDetailPage() {
  const { studentAssesment } = useLoaderData();

  return (
    <Suspense fallback={<ABPDetailLayout data={[]} isLoading />}>
      <Await
        resolve={studentAssesment}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <ABPDetailLayout data={value?.data} />}
      </Await>
    </Suspense>
  );
}
