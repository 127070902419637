import React, { useState } from "react";
import style from "./index.module.scss";
export default function ToolTip({
  headerText = "Informasi",
  description = "Uji keterampilan dapat Anda akases setelah Anda menyelesaikan Post Test",
  position= "left" || "top",
  children,
}) {
  const [isHover, setIsHover] = useState(false);
  const handleEnter = () => {
    setTimeout(() => {
      setIsHover(true);
    }, 200);
  };

  return (
    <div>
      <div className={style.container}>
        <div
          onMouseEnter={handleEnter}
          className={isHover ? style.targetHovered : ""}
          onMouseLeave={() => setIsHover(false)}
          onClick={() => setIsHover(!isHover)}
          onBlur={() => setIsHover(false)}
        >
          {children}
        </div>
        <div className={`${style.hovered} ${style[position]}`}>
          <div className={style.headerToolTip}>{headerText}</div>
          <p className={style.description}> {description} </p>
        </div>
      </div>
    </div>
  );
}
