import React, { useCallback, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import faqIcon from "@Assets/Images/Faq-Chat.png";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import leftArrow from "@Assets/Icon/gradientArrowRight.svg";
import { Link } from "react-router-dom";
import FooterLandingPage from "@Molecule/FooterLandingPage";
import arrowAccordion from "@Assets/Icon/arrowAccordion.svg";

export default function FaqPageLayout() {
  const question = useMemo(() => {
    return [
      {
        question: "Bagaimana Cara Melakukan Pembelian Pelatihan Kelas Prakerja",
        answer: (
          <div>
            <br />
            Untuk pembelian pelatihan menggunakan prakerja. Silakan mengikuti
            langkah-langkah berikut:
            <ol>
              <li>
                Silakan kunjungi media platform seperti Tokopedia,Pintaria atau
                Bukalapak
              </li>
              <li>
                Ketik "Kartu Prakerja" di kolom pencarian dan pilih lembaga
                Pelatihan “Skills.id”
              </li>
              <li>
                Klik pelatihan yang diminati dan pilih jadwal serta lokasi yang
                tersedia 
              </li>
              <li>
                Masukan 16 digit nomor Kartu Prakerja dan lakukan verifikasi
                melalui SMS
              </li>
              <li>
                Setelah transaksi berhasil, cek redemption code pada email
                peserta kartu prakerja atau dashboard kartu prakerja
              </li>
              <li>
                Lakukan penukaran kode voucher dan redeem code di laman
                Skills.id 
              </li>
              <li>
                Setelah berhasil login, masukkan kode voucher yang di dapatkan
                dari Mitra Platform Prakerja pada kolom yang sudah disediakan,
                lalu klik Tukarkan sekarang
              </li>
              <li>
                Klik jadwal sesuai dengan yang kamu pilih pada platform, lalu
                tukarkan kode voucher
              </li>
              <li>
                Setelah itu kamu akan diarahkan ke halaman kelas saya klik
                “Lanjutkan belajar” dan akan muncul permintaan untuk memasukan
                kode redeem yang bisa kamu temukan di dashboard prakerja dan
                klik “Redeem sekarang”.
              </li>
              <li>
                Setelah penukaran kode redeem berhasil dilakukan, kamu akan
                diarahkan untuk login ke akun prakerjamu untuk melakukan scan
                wajah, pastikan mengikuti instruksinya ya! 
              </li>
              <br />
            </ol>
            <span>
              (Penukaran dapat dilakukan 1 jam sebelum pelatihan dimulai dan
              maksimal 1 jam setelah pelatihan dimulai)
            </span>
          </div>
        ),
      },
      {
        question: "Cara melihat kode voucher untuk peserta kartu Prakerja",
        answer: (
          <div>
            <span>
              <br />
              Berikut Langkah – Langkah untuk melihat kode voucher yang sudah
              kamu beli di Platform digital :
              <br />
            </span>
            <br />
            <span>A. Untuk melihat Kode Voucher Dari Tokopedia:</span>
            <ol>
              <li>Masuk ke aplikasi Tokopedia </li>
              <li>Klik “Daftar Transaksi”</li>
              <li>Klik “Lihat Detail Transaksi”</li>
              <li>Kode voucher akan ada di detail pembelian.  </li>
            </ol>
            <br />
            <span>B. Untuk Melihat Kode Voucher Dari Bukalapak:</span>

            <ol>
              <li>Buka Aplikasi Bukalapak</li>
              <li>Klik menu “ Transaksi”</li>
              <li>Klik Transaksi pembelian kelas Skills.id</li>
              <li>Scroll ke bawah dan cari kode kupon</li>
            </ol>
            <br />
            <span>
              Atau kamu juga bisa cek kode vouchernya di email yang dikirimkan
              oleh pihak Mitra Platform digital ( Tokopedia, Bukalapak dan
              PINTAR).
            </span>
          </div>
        ),
      },
      {
        question: "Bagaimana cara mendapatkan Redeem code",
        answer: (
          <div>
            <span>
              <br />
              Cara mendapatkan kode redeem :
            </span>
            <span>
              <br />
              kamu bisa log in ke akun Prakerjamu lalu klik menu “Pelatihan” dan
              lihat “Riwayat pelatihan” lalu temukan kode redeem atau kamu bisa
              lihat di email yang dikirimkan oleh Prakerja. Kamu sudah bisa
              menukarkan kode redeem mu 1 jam sebelum pelatihan dimulai dan
              maksimal 1 jam setelah pelatihan dimulai.
              <br />
              <br />
            </span>
            <span>
              Untuk kelas luring kamu wajib melakukan scan QR code pada hari
              pertama pelatihan dan dilakukan langsung di lokasi pelatihan.
              Pelatihan akan dimulai dengan mengerjakan pretest dan diakhiri
              dengan pengerjaan uji keterampilan. 
              <br />
            </span>

            <br />
            <span>
              Pelatihan dapat diselesaikan jika kelas daring (online)
              kehadirannya sudah mencapai 80% dan kelas luring/ offline harus
              100% .
            </span>
          </div>
        ),
      },
      {
        question:
          "Cara mengakses dan menyelesaikan pelatihan yang sudah dibeli",
        answer: (
          <div>
            <span>
              <br />
              Untuk mengikuti pelatihan di Skills.ID kamu bisa mengikuti Langkah
              - Langkah berikut : <br />
            </span>
            <ol>
              <li>
                Silahkan klik link:{" "}
                <a href="https://skills.id/register ">
                  <span style={{ color: "#9360A8" }}>
                    https://skills.id/register
                  </span>
                </a>{" "}
                untuk melakukan registrasi akun di skills.id . Isi semua data
                dengan benar.
              </li>
              <li>
                ⁠Cek nomor whatsapp untuk kode OTP dan verifikasi akun. Lalu
                masukkan username dan password kamu, klik tombol log in.
              </li>
              <li>
                Setelah berhasil Log in, segera tukarkan kode voucher kamu di
                kolom yang telah disediakan. Kemudian klik tombol "Tukarkan
                Sekarang".
              </li>
              <li>
                ⁠Masukan kode voucher dari Mitra platform seperti Tokopedia,
                Pintar dan Bukalapak di kolom laman awal Skills.ID, lalu klik
                “Tukarkan sekarang” .
              </li>
              <li>
                Lalu pilih jadwal pelatihan sesuai dengan jadwal yang kamu pilih
                di Mitra Platform dan klik “Tukarkan kode Voucher”
              </li>
              <li>
                Lalu kamu akan diarahkan kehalaman kelas saya klik “Lanjutkan
                belajar” dan akan muncul permintaan untuk memasukan kode redeem
                yang bisa kamu temukan di dashboard prakerja dan klik “Redeem
                sekarang”.
              </li>
              <li>
                Setelah penukaran kode redeem berhasil dilakukan, kamu akan
                diarahkan untuk login ke akun prakerjamu untuk melakukan scan
                wajah, pastikan mengikuti instruksinya ya!
              </li>
              <li>
                Kerjakan pretest hingga uji keterampilan untuk bisa
                menyelesaikan pelatihan dan dapatkan sertifikat serta
                insentifmu. 
              </li>
            </ol>
            <span>
              Selesai, pelatihan bisa dimulai sesuai jadwal yang dipilih.
              Selamat mengikuti Pelatihanmu!
              <br />
            </span>
            <span>
              <br />
              Atau kamu bisa melihat video Langkah-langkah penukarannya melalui
              link berikut :
              <a href="https://bit.ly/Penukaranskillsxprakerja">
                <span style={{ color: "#9360A8" }}>
                  https://bit.ly/Penukaranskillsxprakerja
                </span>
              </a>
            </span>
          </div>
        ),
      },
      {
        question: "Kapan peserta harus melakukan verifikasi muka?",
        answer: (
          <div>
            <strong>
              {" "}
              <br />
              A. Pelatihan Webinar
            </strong>
            <ul>
              <li>Redeem pelatihan atau sebelum mulai pelatihan;</li>
              <li>
                Setiap sebelum pertemuan/ sesi baru dimulai dan sebelum post
                test.
              </li>
              <li>
                Scan wajah bisa dilakukan 1 jam sebelum pelatihan dimulai dan
                maksimal 1 jam setelah pelatihan dimulai
              </li>
            </ul>
            <strong>B. Pelatihan SPL (Self-Paced Learning)</strong>
            <ul>
              <li>Redeem pelatihan atau sebelum mulai pelatihan;</li>
              <li>Setiap sebelum sesi pelatihan dan sebelum post test.</li>
              <li>
                Scan wajah bisa dilakukan 1 jam sebelum pelatihan dimulai dan
                maksimal 1 jam setelah pelatihan dimulai
              </li>
            </ul>
            <strong>C. Pelatihan Offline</strong>
            <ul>
              <li>Setiap sebelum pertemuan dimulai.</li>
              <li>
                Scan wajah bisa dilakukan 1 jam sebelum pelatihan dimulai dan
                maksimal 2 jam setelah pelatihan dimulai
              </li>
            </ul>
            <span>
              <br />
              Untuk tutorial melakukan scan wajah, peserta bisa lihat di link
              berikut. (Ingat, scan wajah bersifat WAJIB!) :
              <a href="https://www.youtube.com/watch?v=sVhcS-EV-Tw&feature=youtu.be">
                <span style={{ color: "#9360A8" }}>
                  https://www.youtube.com/watch?v=sVhcS-EV-Tw&feature=youtu.be 
                </span>
              </a>
            </span>
          </div>
        ),
      },
      {
        question:
          "Jika saya tidak bisa mengikuti salah satu hari pelatihan apakah boleh melakukan perubahan jadwal",
        answer: (
          <div>
            <p>
              Untuk pelatihan daring (Online) tidak bisa melakukan perubahan
              jadwal. 
            </p>
            <p>
              Untuk Pelatihan luring (Offline) bisa dilakukan perubahan jadwal
              maksimal H-3 sebelum hari pertama pelatihan dimulai. Permintaan
              perubahan jadwal bisa dilakukan melalui customer service Skills.id
              diluar itu sudah tidak berlaku perubahan.{" "}
            </p>
            <p>
              {" "}
              Untuk terhubung dengan Customer service Skills, peserta bisa klik
              logo whatsapp yang ada di website ini. 
            </p>
          </div>
        ),
      },
      {
        question: "Bagaimana alur untuk mengikuti pelatihan daring?",
        answer: (
          <div>
            <p>
              Jika sudah melakukan pembelian kelas di Mitra Platform, lakukan
              penukaran kode voucher dan kode redeem sesuai langkah-langkah yang
              bisa ditemukan di FaQ ini, ikuti langkah-langkah berikut :
            </p>
            <p>
              1. Masuk ke akun Skills.id melalui website skills.id (Pastikan
              masuk dengan akun yang sama pada saat penukaran kode voucher dan
              kode redeem) Masuk ke menu dashboard / kelas saya Klik “Lanjutkan
              belajar” lalu kerjakan pretest. Untuk bisa melihat link webinar
              kamu bisa klik “Materi” akan ada link diatas materi lalu bisa kamu
              klik dan join kelas webinar. Ikuti seluruh sesi dari hari pertama
              hingga hari terakhir. Pastikan tidak ada tugas yang terlewat.{" "}
            </p>
            <p>
              {" "}
              Total durasi pelatihan daring adalah 15 jam, namun dalam
              pelaksanaanya akan terbagi dalam 5 hari. Dalam 1 (satu) hari
              peserta akan mengikuti sesi daring maksimal 3 jam. Pastikan untuk
              mengikuti seluruh rangkaian sesi webinar dari pertemuan pertama
              hingga terakhir ya.
            </p>
          </div>
        ),
      },
      {
        question:
          "Bagaimana alur untuk mengikuti pelatihan secara luring/offline ?",
        answer: (
          <div>
            <p>
              Jika sudah melakukan pembelian kelas di Mitra Platform, lakukan
              penukaran kode voucher di Skills.id sesuai langkah-langkah yang
              bisa ditemukan di FaQ.
            </p>
            <p>
              Setelah berhasil menukarkan kode voucher, ikuti langkah-langkah
              berikut.
            </p>
            <ol>
              <li>
                Datang ke lokasi pelatihan pada jam yang sudah dipilih saat
                melakukan pembelian di Mitra Platform. (Maksimal keterlambatan 1
                jam setelah pelatihan dimulai)
              </li>
              <li>
                Datangi Admin yang bertugas dan lakukan scan QR code. Cek QR
                code di Dashboard Prakerja.
              </li>
              <li>
                Sebagai bukti kehadiran, Anda akan diminta untuk melakukan
                verifikasi wajah
              </li>
              <li>
                Total durasi pelatihan luring adalah 15 jam, namun dalam
                pelaksanaannya akan terbagi menjadi 2 hari. Sehingga dalam 1
                hari, peserta akan mengikuti sesi pelatihan maksimal 8 jam. -
                Pastikan untuk mengikuti seluruh rangkaian sesi pelatihan dari
                pertemuan pertama hingga terakhir.
              </li>
            </ol>
          </div>
        ),
      },
      {
        question: "Kapan saya bisa mendapatkan sertifikat? ",
        answer: (
          <div>
            <p>
              Jika sudah menyelesaikan pelatihan, verifikasi, dan cetak
              sertifikat di Skills.id. Kamu bisa menunggu sertifikat muncul di
              dashboard prakerja dalam 7 hari kerja (tidak termasuk hari libur &
              tanggal merah). Hasil pelatihan kamu kami laporkan ke pihak
              terkait pada H+1 selesainya pelatihan. Selanjutnya sertifikat akan
              diproses oleh pihak Marketplace (Tokopedia/Bukalapak/Pintar) ke
              pihak Prakerja. Jika dalam 7 hari kerja sertifikat masih belum
              muncul, silakan hubungi CS Marketplace
              (Tokopedia/Bukalapak/Pintar) karena Skills.id tidak memiliki
              wewenang untuk melaporkan langsung sertifikatnya ke pihak
              Prakerja. Jangan lupa untuk mengisi rating dan ulasan di dashboard
              Prakerja kamu, karena pengisian rating dan ulasan adalah salah
              satu syarat agar insentif bisa dicairkan.  Pastikan untuk
              melakukan pembelian pelatihan maksimal 15 hari sejak pelatihan
              dibeli. Mohon untuk sabar menunggu.
            </p>
          </div>
        ),
      },
      {
        question: "Kapan insentif bisa dicairkan?",
        answer: (
          <div>
            <p>
              Jika Anda sudah lolos menjadi Penerima Kartu Prakerja, insentif
              biaya mencari kerja bisa didapatkan setelah menyelesaikan
              Pelatihan yang ditandai dengan adanya sertifikat. Penyelesaian
              seluruh aktivitas pelatihan wajib segera diselesaikan setelah
              pelatihan hari terakhir. Pastikan juga setelah menyelesaikan
              kelas, Anda sudah melakukan beberapa hal berikut.
            </p>
            <ol>
              <li>
                Telah memberikan ulasan (review) dan penilaian (rating) terhadap
                pelatihan di dashboard Prakerja
              </li>
              <li>
                Jika Penerima Kartu Prakerja mengikuti lebih dari satu
                pelatihan, insentif biaya mencari kerja hanya diberikan pada
                saat penyelesaian pelatihan yang pertama. Tidak ada insentif
                untuk pelatihan kedua dan seterusnya.
              </li>
              <li>
                Telah berhasil menyambungkan nomor rekening bank atau e-money di
                akun situs www.prakerja.go.id
              </li>
              <li>
                Nomor rekening bank atau e-money yang didaftarkan sudah
                divalidasi (menggunakan NIK yang sama dengan NIK terdaftar di
                Kartu Prakerja dan sudah KYC atau akun e-money sudah
                premium/upgrade) oleh bank/perusahaan e-money terkait.
              </li>
            </ol>
            <p>
              Insentif pengisian survei evaluasi Insentif survey akan diterima
              jika Penerima Kartu Prakerja telah mengisi survei evaluasi pada
              dashboard akun di situs www.prakerja.go.id untuk mengetahui
              evaluasi efektivitas Program Kartu Prakerja. Pastikan mengisi
              evaluasi dengan jujur ya. Apabila mengalami kendala terkait
              insentif, silakan hubungi Customer Service Prakerja melalui:
              <ol>
                <li>
                  Formulir pengaduan <br />
                  <a
                    href="https://bantuan.prakerja.go.id/hc/id/requests/new"
                    islinktext="true"
                  >
                    {" "}
                    <span>
                      https://bantuan.prakerja.go.id/hc/id/requests/new
                    </span>{" "}
                  </a>
                </li>
                <li>
                  {" "}
                  Call Center
                  <br />
                  08001503001 (Senin-Minggu 08.00-20.00 WIB)
                </li>
                <li>
                  {" "}
                  Fitur Live Chat <br />
                  <a href="prakerja.go.id/livechat" islinktext="true">
                    {" "}
                    <span>prakerja.go.id/livechat</span>{" "}
                  </a>
                </li>
                Anda juga bisa mengunjungi prakerja.go.id/tanya-jawab untuk
                informasi lebih lanjut.
              </ol>
            </p>
          </div>
        ),
      },
      {
        question: "Apa saja syarat untuk bisa mendapatkan insentif Prakerja?",
        answer: (
          <div>
            <p>
              nsentif atau biaya mencari kerja adalah bantuan dana yang
              diberikan oleh pemerintah bagi peserta Prakerja yang sudah
              menyelesaikan kelas pertama. Peserta Prakerja akan mendapatkan 2
              jenis insentif, yaitu: Insentif biaya mencari kerja sebesar
              Rp600.000,- (enam ratus ribu rupiah) sebanyak 1 (satu) kali
              Insentif pengisian survei evaluasi sebesar Rp50.000,- (lima puluh
              ribu rupiah) per survei dengan total 2 survei. Kami informasikan
              apabila Anda sudah terverifikasi setidaknya menyelesaikan 1 kelas
              di dashboard Kartu Prakerja, maka selanjutnya proses pembayaran
              insentif dilakukan oleh pemerintah, bukan oleh lembaga pelatihan
              (Skills.id) ataupun Mitra Platform Prakerja (Bukalapak, Tokopedia
              dan Pintar). Pastikan beberapa hal berikut untuk mendapatkan
              insentif Prakerja:
              <ul>
                <li>Anda membeli kelas menggunakan Kartu Prakerja.</li>
                <li>
                  Melakukan pembelian kelas maksimal 15 hari kalender sejak
                  pengumuman lolos gelombang
                </li>
                <li>
                  Menyelesaikan kelas yang sudah dibeli, dibuktikan dengan
                  mendapatkan sertifikat dari Skills.id
                </li>
                <li>
                  Telah berhasil menyambungkan nomor rekening Bank atau e-wallet
                  di akun website prakerja.go.id.
                </li>
                <li>
                  Nomor rekening Bank atau e-wallet yang didaftarkan telah
                  tervalidasi oleh bank/perusahaan e-wallet yang bersangkutan.
                </li>
                <li>
                  Telah melakukan pengisian rating dan review pada kelas
                  pelatihan di dashboard Prakerja Anda.
                </li>
              </ul>
              <p>
                Setelah menyelesaikan kelas pertama, Tim Skills.id akan
                melaporkan status penyelesaian Anda kepada Mitra Platform
                Prakerja tempat pembelian kelas. Selanjutnya, Mitra Platform
                yang akan menyampaikan data penyelesaian kelas Anda kepada Tim
                Prakerja. Tim Prakerja selanjutnya akan memverifikasi status
                penyelesaian kelas Anda dalam waktu 7 hari kerja (tidak termasuk
                hari libur atau tanggal merah). Setelah terverifikasi, Tim
                Prakerja akan mengirimkan dana insentif ke channel pembayaran
                yang sudah Anda pilih di dashboard Prakerja. Apabila mengalami
                kendala terkait insentif, silakan hubungi Customer Service
                Prakerja melalui:
              </p>
            </p>
            <p>
              Formulir pengaduan <br />
              <a
                href="https://bantuan.prakerja.go.id/hc/id/requests/new"
                islinktext="true"
              >
                <span>
                  {" "}
                  🔗 https://bantuan.prakerja.go.id/hc/id/requests/new. 
                </span>
              </a>
            </p>
            <p>
              Call Center <br />
              <span>08001503001 (Senin-Minggu 08.00-20.00 WIB)</span>
            </p>
            <p>
              Fitur Live Chat <br />
              <a href="prakerja.go.id/livechat" islinktext="true">
                <span>🔗 prakerja.go.id/livechat</span>
              </a>
            </p>
            <p>
              Anda juga bisa mengunjungi prakerja.go.id/tanya-jawab untuk
              informasi lebih lanjut.
            </p>
          </div>
        ),
      },
    ];
  }, []);
  const [isOpenArray, setIsOpenArray] = useState(
    new Array(question.length).fill(false)
  );

  const handleToggle = useCallback((index) => {
    setIsOpenArray((prevIsOpenArray) => {
      const updatedIsOpenArray = [...prevIsOpenArray];
      updatedIsOpenArray[index] = !updatedIsOpenArray[index];
      return updatedIsOpenArray;
    });
  }, []);
  return (
    <div className={Styles.wrapperFaqPageLayout}>
      <div className={Styles.buttonBack}>
        <Link to="/" className={Styles.link}>
          <img src={leftArrow} alt="arrow-back" className={Styles.arrowLeft} />
          <span>Kembali</span>
        </Link>
      </div>
      <DoodleGradientCard>
        <div className={Styles.wrapperText}>
          <div>
            <h1>FAQ</h1>
            <p>
              Berikut Adalah Pertanyaan Yang Sering Di Tanyakan Oleh Banyak
              Orang
            </p>
          </div>
          <div>
            <img src={faqIcon} alt="faq-icon" />
          </div>
        </div>
      </DoodleGradientCard>
      <div className={Styles.faqsText}>
      <div className={Styles.wrapperAccordion}>
        {question.map((item, idx) => (
          <main key={idx}>
            <div className={Styles.contain}>
              <div
                className={Styles.isClickIndicator}
                onClick={() => handleToggle(idx)}
              >
                <p active={isOpenArray[idx] ? "true" : "false"}>
                  {item.question}{" "}
                </p>
                <img
                  src={arrowAccordion}
                  active={isOpenArray[idx] ? "true" : "false"}
                  alt="arrow"
                />
              </div>
              {isOpenArray[idx] && (
                <p className={Styles.answer}>{item.answer}</p>
              )}
            </div>
            <div className={Styles.lineSeperator}></div>
          </main>
        ))}
      </div>
      </div>
      <FooterLandingPage />
    </div>
  );
}
