import EditClassWithTypeLayout from "@Organism/EditClass";
import { getCourse } from "@Services/course";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
export const loader = ({ params }) => {
  const id = params.id;
  const getDataCourse = getCourse(id);
  return defer({
    getDataCourse,
  });
};

export default function EditClass({ type }) {
  const { getDataCourse } = useLoaderData();

  return (
    <Suspense fallback={<p>loading....</p>}>
      <Await
        resolve={getDataCourse}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(dataClass) => (
          <EditClassWithTypeLayout type={type} data={dataClass.course} />
        )}
      </Await>
    </Suspense>
  );
}
