import React from "react";
import Styles from "./style.module.scss";
import RadioButton from "@Atom/RadioButton";
import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/index";

export default function QuesionReviewCard({
  data,
  quizNum,
  isEdit = false,
  handleChange,
  isError = false,
}) {
  return (
    <div className={Styles.container}>
      <div className={Styles.questionHeader}>
        <p>Soal {quizNum}</p>
        {data.quizType === "Essay" && isEdit && (
          <div className={Styles.scoring}>
            <input
              value={data.score === -1 ? "" : data.score}
              onChange={(e) => {
                if (+e.target.value <= +data.maxScore)
                  handleChange(data.quizNum - 1, +e.target.value);
              }}
              className={`${
                isError && data.score === -1 ? Styles.inputError : ""
              }`}
            />
            {isError && data.score === -1 && (
              <div className={Styles.errorText}>
                
                <Icon icon={"information-solid"} color={"#D42701"} size={16} />
                <p>Skor soal belum diisi</p>
              </div>
            )}
            <p>/ {data.maxScore}</p>
          </div>
        )}
        {data.quizType === "Essay" && !isEdit && <p>Skor : {data.score}</p>}
      </div>
      <div className={Styles.questionWrapper}>
        {data.contentIMG && <img src={fileBaseUrl + data.contentIMG} alt="" />}
        <div
          dangerouslySetInnerHTML={{
            __html: data?.contentHTML,
          }}
          className={Styles.question}
        />
      </div>
      {data.quizType === "Pilihan Ganda" ? (
        <div className={Styles.optionWrapper}>
          {data.options.map((el, idx) => (
            <div
              className={Styles.option}
              is-incorrect={
                !el.isCorrect && el.optionNum === data.answer ? "true" : "false"
              }
              is-correct={String(el.isCorrect)}
              key={idx}
            >
              <RadioButton isSelected={el.optionNum === data.answer} />
              <p>{el.optionValue}</p>
              {el.isCorrect && (
                <Icon icon={"checked"} size={20} color={"#42CAB4"} />
              )}
              {!el.isCorrect && el.optionNum === data.answer && (
                <Icon icon={"cross"} size={20} color={"#B22A12"} />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className={Styles.answerWrapper}>
          <p>Jawaban</p>
          <p>{data.answer}</p>
        </div>
      )}
    </div>
  );
}
