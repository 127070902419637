import { API } from "@Config/index";

export function getCourseForLearn(code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/course/${code}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function exchangeCourse(usergradeID, code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/courses/code/${code}`,
        { usergradeID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClassDashboardStudent() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/usergrade`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getClassByGradeStudent(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/usersheet/grade/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClassDashboardInstructor(page, limit, type) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/instructor/courses?page=${page}&limit=${limit}&platform=${
          type || ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
