import Button from '@Atom/Button'
import RadioButton from '@Atom/RadioButton'
import Text from '@Atom/Text'
import Images from '@Theme/Images'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function CreateClassLayout() {
  const [type, setType] = useState('')
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const template = [
    {
      logo: Images.WEBINAR,
      title: 'Webinar',
      desc: 'sebuah seminar online yang Anda inginkan',
      path: 'webinar',
      disabled:false
    },
    {
      logo: Images.OFFLINE,
      title: 'Kelas Offline',
      desc: 'kursus offline dengan video ataupun presentasi online',
      path: 'offline',
      disabled:false
    },
    {
      logo: Images.VIDEO,
      title: 'Video',
      desc: 'kursus dengan video ataupun presentasi online',
      path: 'video',
      disabled:false
    },
  ]

  return (
    <div className={Styles.container}>
      <div className={Styles.button}>
        <Button
          text={'Kembali'}
          variant={'text'}
          textSize='l'
          startIcon={'chevron-left'}
          onClick={() => navigate(-1)}
        />
      </div>

      <div className={Styles.main}>
        <div className={Styles.header}>
          <h3>Jenis Kelas</h3>
          <span>Silahkan pilih jenis kelas yang ingin Anda buat.</span>
        </div>
        <div className={Styles.content}>
          {
            template?.map((t, tI) => (
              <div key={tI}>
                <ClassCard
                  key={tI}
                  logoSrc={t?.logo}
                  title={t?.title}
                  desc={t?.desc}
                  isSelected={type === t?.path}
                  onClick={() => !t.disabled && setType(t?.path)}
                  disabled={t.disabled}
                />
              </div>

            ))
          }
        </div>
        <Button
          text={'Lanjutkan'}
          endIcon={'chevron-right'}
          iconSize={'12px'}
          textWidth='medium'
          disabled={!type}
          onClick={() => navigate(`${pathname}/${type}`)}
        />
      </div>
    </div>
  )
}

const ClassCard = ({
  logoSrc,
  title,
  desc,
  onClick = () => { },
  isSelected = false,
  disabled
}) => {

  return (
    <div className={`${Styles.cardWrapper}  ${isSelected ? Styles.selected : ''}`} is-disabled={disabled ? "true" : "false"}>
      <div className={`${Styles.card}`} onClick={onClick}>
        <div className={Styles.radioWrapper}>
          <RadioButton isSelected={isSelected} />
        </div>
        <img src={logoSrc} alt='' />
        <div className={Styles.desc}>
          <Text size={'l'} width={'semibold'}>
            {title}
          </Text>
          <Text size={'l'}>
            {desc}
          </Text>
        </div>

      </div>
    </div>

  )
}