import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import { useCallback } from 'react'

export default function Range({
  value,
  setValue,
  min = 0,
  max = 100,
  step = 1
}) {
  const onSubtract = useCallback(() => {
    if (+value - +step === 0 || +value - +step > 0) {
      setValue(+value - + step)
    } else {
      setValue(0)
    }
  }, [setValue, step, value])

  const onAdd = useCallback(() => {
    if (+value + +step === 100 || +value + +step < 100) {
      setValue(+value + + step)
    } else {
      setValue(100)
    }
  }, [setValue, step, value])

  const getBackgroundSize = () => { 
    return { backgroundSize: `${(value * 100) / max}% 100%` }; }; 

  return (
    <div className={Styles.container}>
      <button onClick={onSubtract}>
        <Icon icon={'minus-circle'} size={16} />
      </button>
      <input
        type='range'
        value={value}
        onChange={(e) => setValue(e?.target?.value)}
        min={min}
        max={max}
        step={`${step}`}
        style={getBackgroundSize()}
      />
      <button onClick={onAdd}>
        <Icon icon={'circle-plus'} size={16} />
      </button>

    </div>
  )
}