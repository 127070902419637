import Styles from './style.module.scss'

export default function Spinner({
  size = '48px',
  className=''
}) {

  return (
    <span style={{ width:size }} className={`${Styles.spinner} ${className}`}></span>
  )
}