import React from "react";
import Styles from "./styles.module.scss";
import Button from "@Atom/Button";
import NextPrevButton from "@Molecule/NextPrevButton";
import { useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";
import Text from "@Atom/Text";

const ruleList = [
  "Mengaktifkan kamera",
  "Berpartisipasi aktif pada saat diskusi",
  "Mengatur posisi mic dalam keadaan mute",
  "Menekan tombol Raise hand atau",
  "Menggunakan fitur chat jika ingin bertanya",
];

export default function ClassesRules({ data }) {
  const navigate = useNavigate();
  const query = useQuery();
  const title = query.get("t");

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Aturan Kelas</h3>
        <span>
          Berikut ini adalah beberapa syarat, tata tertib & peraturan yang harus
          diikuti ya!
        </span>
      </div>
      <div className={Styles.divider} />
      <div className={Styles.content}>
        {data.regulationHTML !== "" ? (
          <div dangerouslySetInnerHTML={{ __html: data.regulationHTML }} className={Styles.classRules}></div>
        ) : data.platform === "OFFLINE" ? (
          <p
            style={{ whiteSpace: "pre-line" }}
          >{`1.⁠ ⁠Sebelum mengikuti pelatihan peserta diwajibkan untuk melakukan verifikasi kehadiran atau absensi kehadiran

2. Kehadiran peserta luring wajib 100%

3. Bagi peserta yang berhalangan hadir karena alasan tertentu harus mendapat persetujuan terlebih dahulu dari lembaga pelatihan.

4.⁠ ⁠Peserta wajib mengikuti seluruh rangkaian kegiatan pelatihan dari awal hingga akhir

5. Peserta tidak diperkenankan meninggalkan ruang pelatihan sebelum pelatihan selesai`}</p>
        ) : (
          <div>
            <div>
              <Text size="h4" width={"bold"}>
                Syarat :
              </Text>
              <div style={{ marginTop: "12px" }}>
                <p>
                  1. Peserta Wajib mengikuti kelas Zoom/ webinar 100% (5 hari
                  pertemuan)
                </p>
                <p>2. Laptop yang memadai</p>
                <p>2. Jaringan internet yang stabil</p>
              </div>
            </div>
            <div style={{ marginTop: "24px" }}>
              <Text size="h4" width={"bold"}>
                Tata Tertib & Peraturan :
              </Text>
              <div style={{ marginTop: "12px" }}>
                {ruleList.map((el, idx) => (
                  <p key={idx}>
                    {idx + 1}. {el}
                  </p>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <NextPrevButton
        nextText={"Lanjut Ke Bagian 1"}
        // prevText="Sebelumnya"

        handleNext={() =>
          navigate(`/classes/learn/${data._id}/chapter1-material1`)
        }
        // handlePrev={() =>
        //   navigate(`/classes/learn/${data._id}/pretest?t=${title}`)
        // }
        noPrev
      />
    </div>
  );
}
