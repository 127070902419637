import React, { useState, useEffect } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import ClassesCard from "@Molecule/ClassesCard";
import TransparentSearchbar from "@Atom/TransparentSearchbar";
import Button from "@Atom/Button";
import NotFound from "@Molecule/NotFound";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import { getClassDashboardInstructor } from "@Services/userCourse";
import { useNavigate } from "react-router-dom";
import LoadingContent from "@Molecule/Loading";
import { debounce } from "@Helpers/debounce";

const tabTypes = [
  { type: "Semua", value: "" },
  { type: "Video", value: "VIDEO" },
  { type: "Online", value: "ONLINE" },
  { type: "Offline", value: "OFFLINE" },
];

export default function InstructorDashboardActivity() {
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingInitial, setloadingInitial] = useState(false);
  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(true);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const [tab, setTab] = useState("");
  const user = decryptStorageData("user", localStorage);

  const fetchClass = async () => {
    if (!hasMore || page === 1) return;
    try {
      // setLoading(true);
      if (page === 1) {
        setloadingInitial(true);
      }
      const res = await getClassDashboardInstructor(page, 6, tab);
      setData((prev) => [...prev, ...res.data]);
      setLoading(false);
      setloadingInitial(false);

      setHasMore(page <= res.totalData / 6);
    } catch (error) {
      setLoading(false);
    }
  };
  const renewInitial = async () => {
    try {
      setloadingInitial(true);
      const res = await getClassDashboardInstructor(1, 6, tab);
      setData(res.data);
      setLoading(false);
      setloadingInitial(false);

      setHasMore(page <= res.totalData / 6);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFetch]);

  useEffect(() => {
    setPage(1);
    renewInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  // useEffect(() => {
  //   // add event listener to detect scroll events on the body element
  //   window.addEventListener("scroll", debounce(handleScroll, 500));

  //   // return () => {
  //   //   // remove event listener when the component unmounts
  //   //   window.removeEventListener("scroll", debounce(handleScroll, 500));
  //   // };
  // }, []);

  useEffect(() => {
    if (loading) {
      setPage((prevPage) => prevPage + 1);
      setTriggerFetch((prev) => !prev);
    }
  }, [loading]);

  const handleScroll = () => {
    if (
      document.body.scrollHeight - 300 < window.scrollY + window.innerHeight &&
      hasMore
    ) {
      setLoading(true);
    }
  };
  window.addEventListener("scroll", debounce(handleScroll, 500));
  return (
    <div className={Styles.container}>
      <DoodleGradientCard>
        <div className={Styles.topCard}>
          <div className={Styles.leftSection}>
            <p className={Styles.title}>
              Selamat Datang <span>{user?.name}</span>
            </p>
            <p className={Styles.desc}>
              Berikut adalah Dashboard kelas, serta detail kelas untuk kamu
              melakukan absensi & penilaian
            </p>
            <div className={Styles.inputWrapper}>
              <TransparentSearchbar
                placeholder="Cari Kelasmu"
                width="100%"
                setValue={setSearchQuery}
                value={searchQuery}
              />
            </div>
          </div>
          <div className={Styles.rightSection}>
            <Button
              variant="neutral-contained"
              text={"Tambah Kelas"}
              startIcon={"add"}
              onClick={() => navigate("/classes/new/video")}
              className={Styles.bannerAction}
            />
          </div>
        </div>
      </DoodleGradientCard>
      {/* <div className={Styles.section}>
        <div className={Styles.sectionTitle}>
          <p>Dashboard</p>
        </div>

        <div className={Styles.cardContainer}>
          {classSync.map((el, idx) => (
            <div className={Styles.card} key={idx}>
              <div className={Styles.cardIcon} style={{ background: el.color }}>
                <Icon icon={el.icon} size={32} />
              </div>
              <div className={Styles.cardText}>
                <p>{el.type}</p>
                <p>{Number(el.value).toLocaleString("id")}</p>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div className={Styles.section}>
        {/* <div className={Styles.sectionTitle}>
          <p>Kelas Saya</p>
        </div> */}
        {/* <div className={Styles.tabWrapper}>
          {tabTypes.map((el, idx) => (
            <React.Fragment key={idx}>
              <Button
                variant="tab"
                text={el.type}
                isActive={tab === el.value}
                onClick={() => setTab(el.value)}
              />
            </React.Fragment>
          ))}
        </div> */}
        <div
          className={
            data.length < 1 || loadingInitial
              ? Styles.notFound
              : Styles.classCardWrapper
          }
        >
          {data.length < 1 && !loadingInitial ? (
            <NotFound
              title="Belum Ada Kelas Aktif di Daftar Kelas Kamu Nih"
              desc="Buat kelas terlbeih dahulu untuk dapat menampilkan kelas aktif"
            />
          ) : loadingInitial ? (
            <LoadingContent />
          ) : (
            <React.Fragment>
              {data.map((item, idx) => (
                <ClassesCard
                  key={idx}
                  image={item?.asset?.thumbnail?.url}
                  level={item.grades}
                  type={item.type}
                  platform={item.platform}
                  title={item.subjectName}
                  id={item._id}
                  data={item}
                  withEdit
                />
              ))}
            </React.Fragment>
          )}
        </div>
        {/* {loading && hasMore && <p>Loading...</p>} */}
      </div>
    </div>
  );
}
