import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import RadioButton from "@Atom/RadioButton";
export default function MultipleChoice({
  type,
  question = [],
  answerQuestion = [],
  numberQuestion,
  onChange,
  value,
  onNextButtonClick = () => {},
  onPrevButtonClick = () => {},
  totalQuestion,
  onSubmitQuiz = () => {},
  isSelectedRadio,
  onClickRadio = () => {},
  totalValueRadio,
  totalValueEssay,
  isShuffle = false,
  questionNum,
}) {
  const moreThan = numberQuestion > 1;
  const lessThan = totalQuestion > numberQuestion;
  return (
    <section className={Styles.section}>
      <div className={Styles.buttonTabs}>
        <div
          className={Styles.backButton}
          onClick={moreThan ? onPrevButtonClick : undefined}
        >
          <Icon
            icon={"keyboard_arrow_down"}
            size={"16"}
            className={Styles.arrowIconLeft}
          />
          <span prevButton={moreThan ? "true" : "false"}>Kembali</span>
        </div>
        <div className={Styles.papperNumber}>
          {numberQuestion}/{totalQuestion}
        </div>
        <div
          className={Styles.nextButton}
          onClick={
            !lessThan
              ? undefined
              : isSelectedRadio || !!value
              ? onNextButtonClick
              : undefined
          }
        >
          <span
            nextButton={
              !lessThan
                ? "false"
                : isSelectedRadio || !!value
                ? "true"
                : "false"
            }
          >
            Selanjutnya
          </span>
          <Icon
            icon={"keyboard_arrow_down"}
            size={"16"}
            className={Styles.arrowIconRight}
          />
        </div>
      </div>
      {type === "essay" ? (
        <div className={Styles.container}>
          <div className={Styles.headerQuestion}>
            {"Pertanyaan "}
            {numberQuestion}
          </div>
          {question.map((item, idx) => (
            <div
              className={Styles.descriptionQuestion}
              key={idx}
              dangerouslySetInnerHTML={{ __html: item }}
            ></div>
          ))}
          <div className={Styles.answerQuestion}>
            <textarea
              placeholder="Jawaban Anda"
              cols="30"
              rows="10"
              onChange={onChange}
              value={value}
            />
          </div>
        </div>
      ) : (
        <div className={Styles.container}>
          <Text color={"#404040"} width={"semibold"} size={"l"}>
            {"Soal "}
            {numberQuestion}
          </Text>
          {question.map((item, idx) => (
            <div
              nth={"1"}
              key={idx}
              dangerouslySetInnerHTML={{ __html: item }}
            ></div>
          ))}
          <div nth={"2"}>
            {answerQuestion.map((item, idx) => (
              <div key={idx}>
                <RadioButton
                  isSelected={isSelectedRadio === item}
                  onClick={() => isShuffle ? onClickRadio(item, questionNum) : onClickRadio(item)}
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {!lessThan && (
        <div className={Styles.buttonSubmit}>
          <button
            isDisabled={
              type === "essay"
                ? totalQuestion !== Object.keys(totalValueEssay).length &&
                  "true"
                : totalQuestion !== Object.keys(totalValueRadio).length &&
                  "true"
            }
            disabled={
              type === "essay"
                ? totalQuestion !== Object.keys(totalValueEssay).length
                : totalQuestion !== Object.keys(totalValueRadio).length
            }
            onClick={onSubmitQuiz}
          >
            Submit
          </button>
        </div>
      )}
    </section>
  );
}
