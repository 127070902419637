import ASTDetail from "@Organism/InstructorDashboard/AstDetail";
import { fetchInstructorClassProgressAST } from "@Services/instructor";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
export async function loader(route) {
  const params = route.params;
  console.log(params.id);
  const getStudentAssesment = fetchInstructorClassProgressAST(
    params.userSheetId,
    params.sessionNum
  );
  return defer({
    studentAssesment: getStudentAssesment,
  });
}

export default function ASTDetailPage() {
  const { studentAssesment } = useLoaderData();

  return (
    <Suspense fallback={<ASTDetail data={[]} isLoading />}>
      <Await
        resolve={studentAssesment}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <ASTDetail data={value?.data} />}
      </Await>
    </Suspense>
  );
}
