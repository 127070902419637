import TeacherClassDetail from "@Organism/InstructorDashboard/ClassDetail";
import React from "react";

export default function InstructorActivityDetailPage() {
  return (
    <div>
      <TeacherClassDetail />
    </div>
  );
}
