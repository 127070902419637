import Modal from "@Atom/Modal";
import React, { useEffect } from "react";
import Styles from "./style.module.scss";
import InputField from "@Molecule/InputField";
import Button from "@Atom/Button";
import { useState } from "react";
import FormErrorMessage from "@Atom/FormErrorMessage";

export default function SingleInputModal({
  isOpen,
  headTitle,
  inputTitle = "",
  error,
  handleClose,
  handleSubmit,
}) {
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue("");
  }, [isOpen]);
  // useEffect(() => {
  //   setErrorValue("");
  // }, [value]);

  return (
    <Modal isOpen={isOpen}>
      <div className={Styles.container}>
        <p>{headTitle}</p>
        <div>
          <InputField title={inputTitle} value={value} setValue={setValue} />
          {error && <FormErrorMessage message={error} fontSize="14px" />}
        </div>
        <div className={Styles.buttonWrapper}>
          <Button
            text={"Kembali"}
            onClick={() => {
              handleClose();
              setValue("");
            }}
            variant="outlined"
          />
          <Button text={"Simpan"} onClick={() => handleSubmit(value)} />
        </div>
      </div>
    </Modal>
  );
}
