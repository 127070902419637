import CreateClassLayout from "@Organism/CreateClass";
import CreataeClassWithTypeLayout from "@Organism/CreateClassWithType";

const CreateClassPage = ({ type }) => {
  if (type) {
    return (
      <CreataeClassWithTypeLayout type={type} />
    )
  }
  return (
    <CreateClassLayout />
  );
};

export default CreateClassPage;
