import Card from "@Atom/Card";
import Modal from "@Atom/Modal";
import ModalHeader from "@Atom/ModalHeader";
import Range from "@Atom/Range";
import Images from "@Theme/Images";
import { useRef, useState } from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import AvatarEditor from "react-avatar-editor";

const backgroundList = [
  "#C2C2C2",
  "#46B7E9",
  "#92D5F2",
  "#3C3B91",
  "#5D5CBD",
  "#9360A8",
  "#BE9FCB",
];

export default function PhotoPositioningModalWithDisplay({
  file,
  onSave = () => {},
  onClose = () => {},
}) {
  const cropRef = useRef();
  const [scaleRange, setScaleRange] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState("#C2C2C2");

  const handleSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      const croppedFile = new File([blob], "profilePict", {
        lastModified: new Date().getTime(),
        type: blob.type,
      });
      onSave(file, croppedFile, backgroundColor);
      // console.log(file);
      // setPreview(URL.createObjectURL(blob));
      // setModalOpen(false);
    }
  };

  return (
    <Modal isOpen={file ? true : false}>
      <Card className={Styles.card}>
        <ModalHeader
          title="Tambah Foto Profil"
          withCloseOption
          onClose={onClose}
        />

        <div className={Styles.body}>
          <div className={Styles.leftSection}>
            <SectionTitle
              title={"Foto Detail Guru"}
              desc={"Foto akan ditampilkan pada detail kelas"}
            />
            <div className={Styles.imageWrapper}>
              <img src={file ? URL.createObjectURL(file) : ""} alt="" />
            </div>
          </div>
          <div className={Styles.divider} />
          <div className={Styles.rightSection}>
            <SectionTitle
              title={"Display Picture"}
              desc={"Silahkan atur display picture Anda"}
            />
            <div className={Styles.avatarWrapper}>
              <AvatarEditor
                ref={cropRef}
                image={file ? URL.createObjectURL(file) : ""}
                width={280}
                height={280}
                // style={{ width: "100%", height: "100%" }}
                // border={[143,143]}
                borderRadius={10000}
                backgroundColor={backgroundColor}
                color={[0, 0, 0, 0.72]}
                scale={1 + +scaleRange / 100}
                rotate={0}
              />
            </div>
            <Range
              value={scaleRange}
              setValue={setScaleRange}
              min={0}
              max={100}
              step={2}
            />
            <div className={Styles.colorPickerContainer}>
              {backgroundList.map((el, idx) => (
                <div
                  className={Styles.colorPickWrapper}
                  is-selected={String(backgroundColor === el)}
                  onClick={() =>
                    backgroundColor !== el && setBackgroundColor(el)
                  }
                >
                  <div
                    className={Styles.colorPicker}
                    style={{ backgroundColor: el }}
                    key={idx}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={Styles.actions}>
          <Button text={"Kembali"} onClick={onClose} variant="outlined" />
          <Button text={"Simpan"} onClick={handleSave} />
        </div>
      </Card>
    </Modal>
  );
}

const SectionTitle = ({ title, desc }) => {
  return (
    <div className={Styles.sectionTitle}>
      <p>{title}</p>
      <p>{desc}</p>
    </div>
  );
};
