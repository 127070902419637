import React from "react";
import style from "./index.module.scss";
import Text from "@Atom/Text";
import RadioButton from "@Atom/RadioButton";
export default function RadioButtonOption({ textRadio, isSelected, onClick }) {
  return (
    <div className={style.wrapperRadioButton}>
      <RadioButton onClick={onClick} isSelected={isSelected} />
      <Text size={"m"} color={"#404040"} width={"regular"}>
        {textRadio}
      </Text>
    </div>
  );
}
