import Button from "@Atom/Button";
import React from "react";
import Styles from "./style.module.scss";
import useWindowSize from "@Hooks/useWindowSize";

export default function NextPrevButton({
  noNext,
  noPrev,
  nextText = "Next",
  prevText = "Prev",
  handleNext,
  handlePrev,
  disabledNext,
  disabledPrev,
}) {
  const { width } = useWindowSize();

  console.log(!!nextText);

  if (width <= 768) {
    return (
      <div className={`${Styles.containerMobile}`}>
        {(!noNext && !!nextText) && (
          <Button
            variant="outlined"
            text={nextText}
            disabled={disabledNext}
            onClick={handleNext}
            endIcon={"chevron-right"}
            
          />
        )}
        {(!noPrev && !!prevText) && (
          <Button
            variant="outlined"
            text={prevText}
            disabled={disabledPrev}
            onClick={handlePrev}
            startIcon={"chevron-left"}
          />
        )}
      </div>
    );
  } else {
    return (
      <div
        className={`${Styles.container}`}
        style={{ justifyContent: noPrev ? "flex-end" : "space-between" }}
      >
        {(!noPrev && !!prevText) && (
          <Button
            variant="text"
            text={prevText}
            disabled={disabledPrev}
            onClick={handlePrev}
            startIcon={"chevron-left"}
          />
        )}
        {(!noNext && !!nextText) && (
          <Button
            variant="text"
            text={nextText}
            disabled={disabledNext}
            onClick={handleNext}
            endIcon={"chevron-right"}
          />
        )}
      </div>
    );
  }
}
