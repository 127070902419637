import Icon from "@Atom/Icon";
import Modal from "@Atom/Modal";
import QuestionGenerator from "@Molecule/QuestionGenerator";
import Styles from "./style.module.scss";
import { useLayoutEffect } from "react";

export default function ViewQuestionsModal({
  show,
  questions = [],
  onClose = () => {},
}) {
  useLayoutEffect(() => {
    if (show) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [show]);

  return (
    <Modal isOpen={show}>
      <div className={Styles.container} is-open={String(show)}>
        <div className={Styles.header}>
          <button onClick={onClose}>
            <Icon icon={"arrow-left-back"} size={24} />
            <Icon icon={"list-numbered-filled"} size={24} />
            <span>{"Kuis"}</span>
          </button>
        </div>
        <div className={Styles.content}>
          {questions?.map((q, qI) => (
            <div key={qI} className={Styles.question}>
              <QuestionGenerator
                placeholderRadio={"Masukkan opsi jawaban"}
                question={`Soal ${qI + 1}`}
                valueTextEditor={q?.question}
                options={q?.options}
                readOnly
                score={q?.score}
                type={q.type}
                image={q.image}
              />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}
