import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import TextEditor from "@Atom/TextEditor";
import RadioTextArea from "@Molecule/RadioTextArea";
import { useMemo, useRef } from "react";
import style from "./style.module.scss";
import Select from "@Atom/Select";
import InputField from "@Molecule/InputField";
import { fileBaseUrl } from "@Config/index";
export default function QuestionGenerator({
  withDeleteOption = false,
  onDelete = () => {},
  valueTextEditor = "",
  placeholderRadio,
  question = "Question",
  setValueTextEditor = () => {},
  options = [],
  setOptions = () => {},
  readOnly = false,
  type ="Pilihan Ganda",
  setType,
  score,
  setScore,
  setImage,
  image,
}) {
  const ref = useRef();
  const textEditor = useMemo(() => {
    return (
      <TextEditor
        type=""
        setValue={setValueTextEditor}
        value={valueTextEditor}
      />
    );
  }, [setValueTextEditor, valueTextEditor]);
  return (
    <div>
      <div className={style.wrapperEditQuiz}>
        <div className={style.headerEditQuiz}>
          <Text color={"#404040"} width={"semibold"} size={"l"}>
            {question}
          </Text>
          <div className={style.spaceBetween}>
            {!readOnly ? (
              <Select
                value={type}
                setValue={setType}
                options={["Pilihan Ganda", "Essay"]}
                width="200px"
                position="bottom"
              />
            ) : (
              <p>{type}</p>
            )}

            {!readOnly && (
              <Icon
                icon={"image"}
                size={22}
                style={{ cursor: "pointer" }}
                color={"#9360A8"}
                onClick={() => ref?.current?.click()}
              />
            )}
            <input
              ref={ref}
              type="file"
              accept={"image/*"}
              hidden
              onChange={(e) => {
                if (e?.target?.files?.length) {
                  setImage(e?.target?.files[0]);
                }
              }}
            />
            {withDeleteOption && (
              <div>
                <Icon
                  onClick={onDelete}
                  icon={"delete-outline"}
                  color={"#D1421A"}
                  size={"22px"}
                />
              </div>
            )}
          </div>
        </div>
        <div className={style.questionsWrapper}>
          {image &&
            ((typeof image === "object" && image?.type) ||
              typeof image === "string") && (
              <div className={style.imageWrapper}>
                {!readOnly && (
                  <div className={style.deleteIcon}>
                    <Icon
                      icon={"bold-circle"}
                      size={24}
                      color={"#9360A8"}
                      onClick={() => setImage(null)}
                    />
                  </div>
                )}
                <img
                  alt=""
                  src={
                    typeof image === "object"
                      ? URL.createObjectURL(image)
                      : fileBaseUrl + image
                  }
                />
              </div>
            )}

          <div className={style.textEdit}>
            {!readOnly ? (
              textEditor
            ) : (
              <div dangerouslySetInnerHTML={{ __html: valueTextEditor }} />
            )}
          </div>
        </div>
        {type === "Pilihan Ganda" ? (
          <div className={style.editQuestion}>
            {options?.map((o, oI) => (
              <RadioTextArea
                key={oI}
                readOnly={readOnly}
                image={o.image}
                setImage={(image) => {
                  const newOptions = options?.map((obj) => {
                    if (obj?.id === o?.id) {
                      return {
                        ...obj,
                        image,
                      };
                    }
                    return obj;
                  });
                  setOptions(newOptions);
                }}
                onChange={(e) => {
                  const newOptions = options?.map((obj) => {
                    if (obj?.id === o?.id) {
                      return {
                        ...obj,
                        option: e?.target?.value,
                      };
                    }
                    return obj;
                  });
                  setOptions(newOptions);
                }}
                isSelected={o?.isCorrect}
                onClick={() => {
                  const newOptions = options?.map((obj) => {
                    if (obj?.id === o?.id) {
                      return {
                        ...obj,
                        isCorrect: true,
                      };
                    }
                    return {
                      ...obj,
                      isCorrect: false,
                    };
                  });
                  setOptions(newOptions);
                }}
                placeholder={placeholderRadio}
                value={o?.option}
              />
            ))}
          </div>
        ) : (
          <div className={style.score}>
            <InputField
              title={"Jumlah Skor"}
              value={score}
              setValue={setScore}
              placeholder="Masukkan jumlah skor"
              isNumber
              readOnly={readOnly}
            />
          </div>
        )}
      </div>
    </div>
  );
}
