import React, { useId, useState } from "react";
import style from "./index.module.scss";
export default function InputText({
  labelValue = "Text Label",
  required,
  placeholder = "Placeholder",
  onClick,
  onChange,
  onKeyDown,
  onBlur,
  onMouseEnter,
  type,
  value,
  name,
  isError,
  ...props
}) {
  const [typeMoney, setTypeMoney] = useState("");
  const [isFocus, setIsFocus] = useState(false);

  const handleTypeMoney = (value) => {
    const isNumber = value.split(".").join("");
    if (+isNumber) {
      setTypeMoney(parseInt(isNumber).toLocaleString("id"));
    } else if (value === "") {
      setTypeMoney("");
    }
  };
  const isTypeMoney = type === "money";
  const id = useId();
  return (
    <div className={style.container}>
      <label htmlFor={id} className={style.labelText}>
        {labelValue}
        {required && <span className={style.required}>*</span>}
      </label>
      {isTypeMoney ? (
        <div
          isFocus={isFocus ? "true" : "false"}
          className={style.wrapperTypeMoney}
        >
          <div className={style.idrCurrency}>Rp</div>
          <input
            type="text"
            id={id}
            className={style.inputTextMoney}
            placeholder={placeholder}
            onClick={onClick}
            onChange={(e) => handleTypeMoney(e.target.value)}
            onBlur={() => setIsFocus(false)}
            onKeyDown={onKeyDown}
            onMouseEnter={onMouseEnter}
            value={typeMoney}
            onFocus={() => setIsFocus(true)}
            {...props}
          />
        </div>
      ) : (
        <input
          type="text"
          id={id}
          className={style.inputText}
          placeholder={placeholder}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onMouseEnter={onMouseEnter}
          value={value}
          name={name}
          is-error={isError?.toString()}
          {...props}
        />
      )}
    </div>
  );
}
