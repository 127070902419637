import Card from '@Atom/Card'
import Icon from '@Atom/Icon'
import { useState } from 'react'
import Styles from './style.module.scss'

export default function SyllabusCard({
  data,
  sessionNumber
}) {
  const [expand, setExpand] = useState(false)

  const template = (type) => {
    switch (type) {
      case 'MATTER':
        return {
          typeTitle: 'Materi',
          logoBg: '#9360A8',
          logoBoxShadow: '2px 0px 2px 0px #632A7A inset, -2px 0px 2px 0px #632A7A inset',
          icon: 'reading-book'
        }
      case 'QUIZ':
        return {
          typeTitle: 'Kuis',
          logoBg: '#5D5CBD',
          logoBoxShadow: ' 2px 0px 2px 0px #34337D inset, -2px 0px 2px 0px #34337D inset',
          icon: 'exam'
        }
      case 'TASK':
        return {
          typeTitle: 'Tugas',
          logoBg: '#1479A4',
          logoBoxShadow: '2px 0px 2px 0px #20576F inset, -2px 0px 2px 0px #20576F inset',
          icon: 'homework'
        }
      default:
        return {}
    }
  }

  const getNumber = (title, type) => {
    const subjects = data?.subjects
    const arr = subjects?.filter(obj => obj?.type === type)

    return arr?.findIndex(obj => obj?.title === title) + 1

  }

  return (
    <Card className={Styles.container}>
      <div className={Styles.header} onClick={() => setExpand(!expand)}>
        <div className={Styles.left}>
          <span>{`Sesi ${sessionNumber}`}</span>
          <h3>{data?.title}</h3>
        </div>
        <button>
          <Icon className={expand ? Styles.rotate : ''} icon='arrow-down' size={24} />
        </button>
      </div>
      {
        expand
        &&
        <div className={Styles.content}>
          {data?.subjects?.map((s, sI) => (
            <div key={sI} className={Styles.subject}>
              <div
                className={Styles.logo}
                style={{
                  backgroundColor: template(s?.type)?.logoBg,
                  boxShadow: template(s?.type)?.logoBoxShadow
                }}
              >
                <Icon icon={template(s?.type)?.icon} size={32} />
              </div>
              <div className={Styles.description}>
                <span>{template(s?.type)?.typeTitle} {s?.type !== 'TASK' && getNumber(s?.title, s?.type)}</span>
                <h4>{s?.title}</h4>
              </div>
            </div>
          ))}
        </div>
      }
    </Card>
  )
}