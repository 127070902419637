import { useMemo, useState } from "react";
import MultipleChoice from "@Molecule/MultipleChoice";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";

export default function JournalQuestions({ questions, handleSubmit }) {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState({});
  const [valueRadio, setValueRadio] = useState({});

  const handleOptionChange = (option, index) => {
    setSelectedOption((prev) => ({ ...prev, [index]: option }));
  };

  const handleNextCard = () => {
    setCurrentCardIndex(currentCardIndex + 1);
  };
  const handlePrevCard = () => {
    setCurrentCardIndex(currentCardIndex - 1);
  };

  const datas = useMemo(() => {
    return questions.map((el) => ({
      question: [el],
    }));
  }, [questions]);

  const handleRadioButton = (option, index) => {
    setValueRadio((prev) => ({ ...prev, [index]: option }));
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.logo}>
        <img src={Images.LOGO} alt="logo" />
        <p>skills.id</p>
      </div>
      <MultipleChoice
        type="essay"
        numberQuestion={currentCardIndex + 1}
        question={datas[currentCardIndex].question}
        onChange={(e) => handleOptionChange(e.target.value, currentCardIndex)}
        value={selectedOption[currentCardIndex] || ""}
        onNextButtonClick={handleNextCard}
        onPrevButtonClick={handlePrevCard}
        totalQuestion={datas.length}
        onSubmitQuiz={() => handleSubmit(selectedOption)}
        onClickRadio={(item) => handleRadioButton(item, currentCardIndex)}
        isSelectedRadio={valueRadio[currentCardIndex] || ""}
        totalValueRadio={valueRadio}
        totalValueEssay={selectedOption}
      />
    </div>
  );
}
