import AuthPage from "@Pages/Auth";
import CreateClassPage from "@Pages/Class/Create/CreateClass";
import LearnClassPage from "@Pages/Class/Learn";
import MaterialPage from "@Pages/Class/Learn/Material";
import PretestPage from "@Pages/Class/Learn/Pretest";
import InstructorDashboardPage from "@Pages/InstructorDashboard";
import InstructorActivityPage from "@Pages/InstructorDashboard/Activity";
import RulesPage from "@Pages/Class/Learn/Rules";
import StudentDashboardPage from "@Pages/StudentDashboard";
import App from "App";
import InstructorDashboardClassPage, {
  loader as getClassDataInstructor,
} from "@Pages/InstructorDashboard/Class";
import InstructorActivityDetailPage from "@Pages/InstructorDashboard/Activity/ActivityDetail";
import StudentDashboardMyClass, {
  loader as myClassLoader,
} from "@Pages/StudentDashboard/MyClass";
import StudentDashboardTransaction from "@Pages/StudentDashboard/Transaction";
import InstructorProfilePage from "@Pages/InstructorDashboard/Profile";
import PosttestPage from "@Pages/Class/Learn/Posttest";
import UkPage from "@Pages/Class/Learn/Uk";
import JournalPage from "@Pages/Class/Learn/Journal";
import LandingClassDetailsPage, {
  loader as landingClassLoader,
} from "@Pages/LandingClassDetail";
import FaqPage from "@Pages/FAQ";
import SchedulesPage from "@Pages/Class/Learn/Schedules";
import EditClass, { loader as editClassLoader } from "@Pages/Class/Edit";
import CoursesPage, { loader as getAllClass, loader } from "@Pages/Courses";
import PrivacyPage from "@Pages/Privacy";
import AboutUsPage from "@Pages/AboutUs";
import ExpiredPage from "@Pages/Expired";
import QuizSandbox from "@Pages/Sandbox/Quiz";
import StudentClassByGradePage, {
  loader as classByGradeLoader,
} from "@Pages/StudentDashboard/ClassByGrade";
import ScoreReviewPage from "@Pages/Class/ScoreReview";
import AdminDashboardPage from "@Pages/Admin";
import AcademicYearPage, {
  loader as academicYearLoader,
} from "@Pages/Admin/AcademicYear";
import StudentListPage, {
  loader as studentListPageLoader,
} from "@Pages/Admin/StudentList";
import AddStudentPage from "@Pages/Admin/StudentList/AddStudent";
import AssessmentDetailPage, {
  loader as assessmentDetailPageLoader,
} from "@Pages/InstructorDashboard/Activity/ActivityDetail/AssessmentDetail";
import ASTDetailPage, {
  loader as ASTDetailPageLoader,
} from "@Pages/InstructorDashboard/Activity/ActivityDetail/ASTDetail";
import ScoreReview, {
  loader as instructorScoreReviewLoader,
} from "@Pages/InstructorDashboard/Activity/ActivityDetail/ScoreReview";
import ABPDetailPage, {
  loader as ABPDetailPageLoader,
} from "@Pages/InstructorDashboard/Activity/ActivityDetail/ABPDetail";
import TeacherListPage, {
  loader as teacherListLoader,
} from "@Pages/Admin/TeacherList";
import AdminCreateTeacherPage from "@Pages/Admin/TeacherList/AddTeacher";

import GradeListPage, {
  loader as gradeListLoader,
} from "@Pages/Admin/GradeList";
import ClassByGradeYear, {
  loader as classByGradeYearLoader,
} from "@Pages/Admin/GradeList/ClassByGradeYear";
import AdminClassDetailPage, {loader as classDetailLoader} from "@Pages/Admin/GradeList/ClassDetail";

const { createBrowserRouter, redirect } = require("react-router-dom");

const routes = [
  {
    path: "/",
    element: <App />,
    children: [
      { index: true, element: <AuthPage type={"login"} /> },
      {
        path: "courses",
        element: <CoursesPage />,
        loader: getAllClass,
      },
      {
        path: "privacy",
        element: <PrivacyPage />,
      },
      {
        path: "about",
        element: <AboutUsPage />,
      },
      { path: "faq", element: <FaqPage /> },
      {
        path: "course/:name/:id",
        loader: landingClassLoader,
        element: <LandingClassDetailsPage />,
      },

      { path: "register", element: <AuthPage type={"register"} /> },
      {
        path: "forgot-password",
        element: <AuthPage type={"forgot-password"} />,
      },
      {
        path: "student",
        element: <StudentDashboardPage />,
        children: [
          {
            index: true,
            path:"grade",
            element: <StudentDashboardMyClass />,
            loader: myClassLoader,
          },
          {
            path: "grade/:id",
            element: <StudentClassByGradePage />,
            loader: classByGradeLoader,
          },
          {
            path: "transaction",
            element: <StudentDashboardTransaction />,
          },
        ],
      },
      {
        path: "instructor",
        element: <InstructorDashboardPage />,
        children: [
          {
            index: true,
            loader: () => {
              throw redirect("activity");
            },
          },
          {
            path: "activity",
            children: [
              {
                index: true,
                path: "",
                element: <InstructorActivityPage />,
                loader: getClassDataInstructor,
              },
              {
                path: ":id",
                // element: <InstructorActivityDetailPage />,
                children: [
                  {
                    index: "true",
                    element: <InstructorActivityDetailPage />,
                  },
                  {
                    path: ":sessionNum",
                    children: [
                      {
                        index: true,
                        element: <AssessmentDetailPage />,
                        loader: assessmentDetailPageLoader,
                      },
                      {
                        path: "ast/:userSheetId",
                        element: <ASTDetailPage />,
                        loader: ASTDetailPageLoader,
                      },
                      {
                        path: "ast/:userSheetId/:materialNum",
                        element: <ScoreReview />,
                        loader: instructorScoreReviewLoader,
                      },
                      {
                        path: "abp/:userSheetId",
                        element: <ABPDetailPage />,
                        loader: ABPDetailPageLoader,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "class",
            element: <InstructorDashboardClassPage />,
            loader: getClassDataInstructor,
          },
          {
            path: "profile",
            element: <InstructorProfilePage />,
          },
        ],
      },
      {
        path: "classes",
        loader: () => {
          return null;
        },
        children: [
          {
            path: "learn/:id",
            element: <LearnClassPage />,
            children: [
              {
                index: true,
                element: <PretestPage />,
              },
              {
                path: "pretest",
                element: <PretestPage />,
              },
              {
                path: "posttest",
                element: <PosttestPage />,
              },
              {
                path: "uk",
                element: <UkPage />,
              },
              {
                path: "rules",
                element: <RulesPage />,
              },
              {
                path: "schedules",
                element: <SchedulesPage />,
              },
              {
                path: "journal",
                element: <JournalPage />,
              },
              {
                path: ":materialCode",

                element: <MaterialPage />,
              },
            ],
          },
          {
            path: "new",
            children: [
              { path: "", element: <CreateClassPage /> }, // Assuming CreateClassPage should be rendered for "/classes/new"
              { path: "offline", element: <CreateClassPage type="OFFLINE" /> },
              { path: "webinar", element: <CreateClassPage type="WEBINAR" /> },
              { path: "video", element: <CreateClassPage type="VIDEO" /> },
            ],
          },
          {
            path: "edit",
            children: [
              {
                path: "offline/:id",
                element: <EditClass type="OFFLINE" />,
                loader: editClassLoader,
              },
              {
                path: "webinar/:id",
                element: <EditClass type="WEBINAR" />,
                loader: editClassLoader,
              },
              {
                path: "video/:id",
                element: <EditClass type="VIDEO" />,
                loader: editClassLoader,
              },
            ],
          },
          {
            path: "score-review",
            element: <ScoreReviewPage />,
          },
        ],
      },
      {
        path: "admin",
        element: <AdminDashboardPage />,
        children: [
          {
            path: "academic-year",
            element: <AcademicYearPage />,
            loader: academicYearLoader,
          },

          {
            path: "student",
            children: [
              {
                index: true,
                element: <StudentListPage />,
                loader: studentListPageLoader,
              },
              {
                path: "add",
                element: <AddStudentPage />,
              },
            ],
          },

          {
            path: "grade/:id",
            element: <StudentClassByGradePage />,
            loader: classByGradeLoader,
          },
          {
            path: "teacher",
            children: [
              {
                index: true,
                element: <TeacherListPage />,
                loader: teacherListLoader,
              },
              {
                path: "add",
                element: <AdminCreateTeacherPage />,
              },
              {
                path: "edit/:id",
                element: <AdminCreateTeacherPage isEdit={true} />,
              },
            ],
          },
          {
            path: "subject-list",
            children: [
              {
                index: true,
                element: <GradeListPage />,
                loader: gradeListLoader,
              },
              {
                path: "classes",
                element: <ClassByGradeYear />,
                loader: classByGradeYearLoader,
              },
              {
                path: "classes/:id",
                element: <AdminClassDetailPage />,
                loader: classDetailLoader,
              },
            ],
          },
          {
            path: "*",
            element: <p>Not Found</p>,
          },
        ],
      },
    ],
  },
  {
    path: "/expired",
    element: <ExpiredPage />,
  },
  {
    path: "/test-quiz",
    element: <QuizSandbox />,
  },
];

const router = createBrowserRouter(routes);

export default router;
