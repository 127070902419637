import Sidebar from "@Molecule/Sidebar";
import { Outlet } from "react-router-dom";

const StudentDashboardPage = () => {
  return (
    <Sidebar type={"student"}>
      <Outlet />
    </Sidebar>
  );
};

export default StudentDashboardPage;
