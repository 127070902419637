import { API } from "@Config/index";
import axios from "axios";

export function getAcademicYear() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/admin/academic-years`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getAcademicYearList() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/admin/academic-years/strings`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function createAcademicYear(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/admin/academic-years`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateAcademicYear(id, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/admin/academic-years/${id}`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function deleteAcademicYear(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.delete(`/admin/academic-years/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchAllStudent(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/admin/students?${query}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function fetchAllTeacher(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/admin/teachers?${query}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function downloadStudentData() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/admin/students/excel`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        responseType: "arraybuffer",
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function updateStudentData(file) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(
        `/admin/students/excel`,
        { file },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          responseType: "arraybuffer",
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function updateTeachersData(file) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(
        `/admin/teachers/excel`,
        { file },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          responseType: "arraybuffer",
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getTeachersData(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/admin/teachers/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function createStudent(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/admin/students`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function createTeacher(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/admin/teachers`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}
export function editTeacher(id,payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.putForm(`/admin/teachers/${id}`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchGradeByYear(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/admin/grades?${query}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchClassByGradeYear(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/admin/classes?${query}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClassStudent(
  courseID = 1,
  limit = 10,
  page = 1,
  search = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admin/classes/${courseID}/students?limit=${limit}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}


export function getClassDetailAdmin(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admin/classes/${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClassSurveysAdmin(id, page = 1, limit = 5) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admins/class/${id}/surveys?limit=${limit}&page=${page}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClassesAdmin(
  stateClass = "draft",
  type = "PRAKERJA",
  limit = 5,
  page = 1,
  search = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admins/${stateClass}?classType=${type}&limit=${limit}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function publishClass(courseID = 1) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `admins/class/publish/${courseID}`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClassAbsence(id, sessionNum = 1, courseBatch = 1) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admins/class/attendance/${id}?courseBatch=${courseBatch}&sessionNum=${sessionNum}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClassPrakerjaStatus(id, sessionNum = 1, courseBatch = 1) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admins/faceVerification?batchNum=${courseBatch}&sequence=${sessionNum}&courseID=${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateAttendance(id, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/admins/class/attendance/${id}`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getDataTablePrakerja(page, limit, start, end, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admins/userSheets?limit=${limit}&page=${page}&start=${start}&end=${end}&search=${search}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function downloadDataRedeem() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/admins/dataReedem`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        responseType: "arraybuffer",
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateDataRedeem(file) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(
        `/admins/dataReedem`,
        { file },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateDataReport(file) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(
        `/admins/dataReport`,
        { file },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function updateDataNotifRegister(file) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(
        `/admins/notifications`,
        { file },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getDataNotifValidation(page, limit, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admins/students/validate?limit=${limit}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getDataNotifLearn(page, limit, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admins/classes?limit=${limit}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getDataNotifPromo(page, limit, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admins/students/names?limit=${limit}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function downloadDataCompletion() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/admins/dataCompletion`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        responseType: "arraybuffer",
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getListTemplate() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(
        "https://service-chat.qontak.com/api/open/v1/templates/whatsapp",
        {
          headers: {
            Authorization: "Bearer 3S08XbBeqPe-MuEHBUBgUkZLFNGVTKgY0Sin5XYu1QI",
          },
          responseType: "arraybuffer",
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendMessageRegister(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/admins/notifications/register`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendMessageRedeem(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/admins/notifications/redeem`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendMessageValidation(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/admins/notifications/nameValidation`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendMessageValidationAll(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/admins/notifications/nameValidation/all`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendMessagePromo(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/admins/notifications/promo`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendMessagePromoAll(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/admins/notifications/promo/all`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendMessageLearn(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/admins/notifications/classReminder`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendMessageLearnAll(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/admins/notifications/classReminder/all`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getDataStudent(limit = 10, page = 1, search = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admins/getStudents?limit=${limit}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getDataDetailStudent(id = 1, limit = 10, page = 1) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admins/getDetailStudent/${id}?limit=${limit}&page=${page}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getClassStudentParticipants(
  courseID = 1,
  limit = 10,
  page = 1,
  search = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admins/class/${courseID}/students?limit=${limit}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateCourseCode(courseId, collabId, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/collabs/${courseId}/collab/${collabId}`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function createCourseCode(courseId, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/collabs/${courseId}/collab`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchMasterDataCertificate() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get("/data-certificates");
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function createMasterDataCertificate(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm("/data-certificates", payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateMasterDataCertificate(id, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.putForm(`/data-certificates/${id}`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function deleteMasterDataCertificate(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.delete(`/data-certificates/${id}`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchDataVerificationStatusVideo(id, page, limit, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admins/faceVerification/video?courseID=${id}&page=${page}&limit=${limit}&search=${search}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
