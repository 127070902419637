import React from "react";
import Styles from "./index.module.scss";
export default function RadioButton({ onClick, isSelected }) {
  return (
    <div>
      {isSelected ? (
        <div className={Styles.radioFilled} onClick={onClick}></div>
      ) : (
        <div className={Styles.radioOutline} onClick={onClick}></div>
      )}
    </div>
  );
}
