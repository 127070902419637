import GradeByYearList from '@Organism/Admin/GradeByYearList';
import { fetchGradeByYear } from '@Services/admin';
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";


export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const academicYear = params?.get("years") || "";
  const getAllGrade = fetchGradeByYear(
    `academicYear=${academicYear}`
  );
  return defer({
    getAllGrade,
  });
}

export default function GradeListPage() {
  const { getAllGrade } = useLoaderData();
  //   console.log(getAllStudent);

  return (
    <Suspense
      fallback={
        <GradeByYearList data={[]} isLoading />
      }
    >
      <Await
        resolve={getAllGrade}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <GradeByYearList data={value.data} />}
      </Await>
    </Suspense>
  );
}
