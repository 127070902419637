import DoodleGradientCard from "@Atom/DoodleGradientCard";
import React from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import Images from "@Theme/Images";
import { useNavigate } from "react-router-dom";
import FooterLandingPage from "@Molecule/FooterLandingPage";

const privacyList = [
  {
    title: "Tentang Skills.id",
    body: `Sebagai negara kepulauan dengan penduduk yang tersebar di seluruh wilayah pelosok nusantara, yang terpisahkan pulau dan bentang samudera yang luas. Kehadiran platform pembelajaran digital seperti Skills.id menjadi solusi yang efektif dalam pemerataan kesempatan belajar. Karena Skills.id memiliki LMS (Learning Management System) sebuah system pembelajaran mandiri yang membantu kamu untuk mengakses pelatihan-pelatihan skills di manapun dan kapanpun.

    Skills.id merupakan platform belajar digital yang mempertemukan edukator untuk berbagi ilmu dengan learner yang ingin belajar, sekaligus sebagai kelas pelatihan program kartu prakerja. Keberadaan skills.id adalah dalam rangka peningkatan ketrampilan, dan kompetensi sumber daya manusia khususnya angkatan kerja Indonesia menuju Indonesia Emas.
    
    Untuk dapat mencetak manusia Indonesia yang produktif, professional dan berdaya saing tentu memerlukan proses peningkatan skills (upskilling) melalui proses pelatihan secara berkesinambungan serta proses sertifikasi kompetensi yang sesuai dengan kebutuhan industri pada masa kini dan masa yang akan datang.
    
    Dengan didukung ratusan educator professional yang berasal dari beragam bidang industri dan akademis menjadikan Skills.id sebagai Lembaga Pelatihan yang kredibel dan terpercaya. Sehingga masyarakat dapat lebih leluasa memilih ragam pelatihan sesuai dengan minat dan kebutuhan mereka.
    
    Skills.id dibawah naungan PT Global Retail Bersama merupakan salah satu ekosistem bisnis Permata Indonesia sebuah entitas penyedia jasa tenaga alih daya terbesar di Indonesia yang senantiasa memberikan kesempatan bekerja seluas-luasnya bagi setiap angkatan kerja di Tanah Air.
    
    Mari wujudkan mimpimu bersama Skills.id
    
    Upgrade Your Skills Accelerate Your Career`,
  },
];

export default function AboutUsPage() {
  const navigate = useNavigate();
  return (
    <div className={Styles.container}>
      <div className={Styles.headWrapper}>
        <div className={Styles.back}>
          <Button
            text={"Kembali"}
            variant="text"
            startIcon={"chevron-left"}
            onClick={() => navigate(-1)}
          />
        </div>
        <DoodleGradientCard className={Styles.headerSection}>
          <div className={Styles.leftSection}>
            <p>Tentang Skills.id</p>
            <p>Berikut adalah informasi mengenai Skills.id</p>
          </div>
          <div className={Styles.rightSection}>
            <img src={Images.LOGO} alt="" />
          </div>
        </DoodleGradientCard>
      </div>
      <div className={Styles.image}>
        <img src={Images.STRUCTURE} alt="" />
      </div>
      <div className={Styles.body}>
        {privacyList.map((el, idx) => (
          <div className={Styles.section} key={idx}>
            <p>{el.title}</p>
            <p>{el.body}</p>
          </div>
        ))}
      </div>
      <FooterLandingPage />
    </div>
  );
}
