import DNone from "@Atom/DNone";
import Input from "@Atom/Input";
import MultipleTagInput from "@Atom/MultipleTagInput";
import Styles from "./style.module.scss";

export default function InputField({
  title,
  value = "",
  setValue = () => {},
  placeholder = "",
  isPhoneNumber = false,
  isCurrency = false,
  isNumber = false,
  isDropdown = false,
  dropdownOptions = [],
  isLoadingDropdown = false,
  isTextArea = false,
  required = false,
  requiredLabel = "*",
  className = "",
  padding = "12px",
  isPassword = false,
  maxLength = 1000,
  isEmail = false,
  isDragAndDrop = false,
  isFile = false,
  accept = { "image/jpeg": [".jpeg", ".png"] },
  disabled = false,
  forceUpperCase = false,
  speed = 50,
  isMultipleTag = false,
  withDeleteOption = false,
  onDelete = () => {},
  isDate = false,
  isTime = false,
  remark = "",
  readOnly = false,
  titleAdornment = "",
  isImageOnly = false,
  isPDFOnly = false,
  isVideoOnly = false,
  isPinPoint = false,
  forceConvertFile = false,
  startIcon = "",
  iconSize = "24px",
  iconColor = "#C2C2C2",
  isLink = false,
  titleColor = "",
  name,
  isPercentage = false,
  ...props
}) {
  return (
    <div className={Styles.container}>
      <InputTitle
        title={title}
        required={required}
        requiredLabel={requiredLabel}
        endAdornment={titleAdornment}
        color={titleColor}
      />
      {isMultipleTag ? (
        <MultipleTagInput
          values={value}
          setValues={setValue}
          placeholder={placeholder}
        />
      ) : (
        <Input
          value={value}
          setValue={setValue}
          placeholder={placeholder}
          className={className}
          padding={padding}
          isPhoneNumber={isPhoneNumber}
          isCurrency={isCurrency}
          isNumber={isNumber}
          isPassword={isPassword}
          isEmail={isEmail}
          maxLength={maxLength}
          isDropdown={isDropdown}
          isTextArea={isTextArea}
          dropdownOptions={dropdownOptions}
          isLoadingDropdown={isLoadingDropdown}
          isDragAndDrop={isDragAndDrop}
          isFile={isFile}
          accept={accept}
          disabled={disabled}
          forceUpperCase={forceUpperCase}
          speed={speed}
          withDeleteOption={withDeleteOption}
          onDelete={onDelete}
          isDate={isDate}
          isTime={isTime}
          readOnly={readOnly}
          isImageOnly={isImageOnly}
          isPDFOnly={isPDFOnly}
          isVideoOnly={isVideoOnly}
          isPinPoint={isPinPoint}
          forceConvertFile={forceConvertFile}
          startIcon={startIcon}
          iconSize={iconSize}
          iconColor={iconColor}
          isLink={isLink}
          name={name}
          isPercentage={isPercentage}
          {...props}
        />
      )}
      {!!remark && <div className={Styles.remark}>{remark}</div>}
    </div>
  );
}

export const InputTitle = ({
  title = "",
  required = false,
  requiredLabel = "*",
  endAdornment,
  color,
  ...inputTitleProps
}) => {
  return title ? (
    <div
      className={Styles.title}
      style={{
        color: color,
      }}
      {...inputTitleProps}
    >
      {title}
      {required ? (
        <span className={Styles.redStar}>{requiredLabel}</span>
      ) : (
        <span className={Styles.redStar} />
      )}
      {!!endAdornment && endAdornment}
    </div>
  ) : (
    <DNone />
  );
};
