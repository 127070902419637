import Modal from '@Atom/Modal'
import Styles from './style.module.scss'
import { useEffect, useMemo, useRef, useState } from 'react'
import Icon from '@Atom/Icon'
import useOutsideClick from '@Hooks/useOutsideClick';

const PDFJS = require("pdfjs-dist/webpack");
// PDFJS.GlobalWorkerOptions.workerSrc = "./assets/js/pdf.worker.js";

export default function ViewFileModal({
  show,
  file,
  fileType='',
  fileName='',
  isURL=false,
  onClose = () => { }
}) {
  const [images, setImages] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [loadingPDF, setLoadingPDF] = useState(false);

  const type = useMemo(() => {
    if (file?.type) {
      switch (file?.type?.split('/')[0]) {
        case 'image':
          return 'image'
        case 'video':
          return 'video'
        case 'application':
          switch (file?.type?.split('/')[1]) {
            case 'pdf':
              return 'pdf'
            default:
              return ''
          }
        default:
          return ''
      }
    }
    return fileType || 'cloud-upload'
  }, [file?.type, fileType])

  const icon = useMemo(() => {
    if (file?.type || fileType) {
      switch (file?.type?.split('/')[0] || fileType) {
        case 'image':
          return 'image'
        case 'video':
          return 'slow-motion-video'
        case 'application':
          switch (file?.type?.split('/')[1]) {
            case 'pdf':
              return 'file-pdf-box'
            default:
              return 'cloud-upload'
          }
        default:
          return 'cloud-upload'
      }
    }
    return 'cloud-upload'
  }, [file?.type, fileType])

  const readFileData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };


  useEffect(() => {
    if (file && type === 'pdf' && show) {
      const loadDefaultImages = async () => {
        const imagesArr = [];

        // const images = [];
        const data = await readFileData(file);
        const pdf = await PDFJS.getDocument(data).promise;
        const canvas = document.createElement("canvas");
        for (let i = 0; i < pdf.numPages; i++) {
          const page = await pdf.getPage(i + 1);
          const viewport = page.getViewport({ scale: 1 });
          const context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          await page.render({ canvasContext: context, viewport: viewport }).promise;
          imagesArr.push(canvas.toDataURL());
        }
        canvas.remove();
        setImages(imagesArr);

      }

      loadDefaultImages();
    } else {
      // setImages([]);
    }
  }, [file, show, type]);

  const ref = useRef()

  const boxOutsideClick = useOutsideClick(ref)

  useEffect(()=> {
    if(show && boxOutsideClick){
      onClose()
    }
  }, [boxOutsideClick, onClose, show])

  return (
    <Modal
      isOpen={show}
      onClose={onClose}
    >
      <div className={Styles.container}>
        <div className={Styles.header}>
          <button onClick={onClose}>
            <Icon icon={'arrow-left-back'} size={24} />
            <Icon icon={icon} size={24} />
            <span>{file?.name || fileName}</span>
          </button>
        </div>
        <div  ref={ref} className={Styles.content}>
          {
            type === 'video'
            &&
            <div className={Styles.videoWrapper}>
              <video
                src={isURL ? file : URL.createObjectURL(file)}
                className={Styles.video}
                autoPlay
                controls
              />
            </div>
          }

          {
            type === 'image'
            &&
            <div className={Styles.imageWrapper}>
              <img
                src={isURL ? file :URL.createObjectURL(file)}
                alt=''
                className={Styles.image}
              />
            </div>
          }

          {
            type === 'pdf'
              &&

              loadingPDF
              ?
              <div>loading</div>
              :

              <div className={Styles.imagesWrapper}>
                {images?.map(obj => (
                  <div>
                    <img src={obj} alt='' />
                  </div>
                ))}
              </div>
          }
        </div>
      </div>
    </Modal>
  )
}