import { Outlet, useLocation } from "react-router-dom";
import "./App.scss";
import "moment/locale/id";
import Topbar from "@Molecule/Topbar";
import { useEffect } from "react";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.focus();
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <Topbar>
      <ScrollToTop />
      <Outlet />
    </Topbar>
    // <div className="App">
    //   <main>
    //     <Outlet />
    //   </main>
    // </div>
  );
}

export default App;
