import { useRef } from "react";
import style from "./index.module.scss";
import Icon from "@Atom/Icon";
// import moment from "moment";
export default function DatePicker({
  placeholder = "placeholder",
  type,
  handleChange,
  value,
  ...props
}) {
  const ref = useRef(null);
  const handleDateAndTimeChange = (e) => {
    handleChange(e.target.value);
  };
  const prop = {...props}

  return (
    <div>
      <div className={style.container}>
        <input
          type={type === "date" ? "date" : type === "time" ? "time" : "date"}
          isselected={value ? "true" : "false"}
          onChange={handleDateAndTimeChange}
          value={value}
          className={style.input}
          ref={ref}
          data-text={prop?.min ? " dd/mm/" : placeholder}
          {...props}
        />
        {type === "date" ? (
          <Icon
            icon={"calendar1"}
            color={"#C2C2C2"}
            size={"24px"}
            className={style.icon}
            onClick={() => ref.current?.showPicker()}
          />
        ) : type === "time" ? (
          <Icon
            icon={"clock"}
            color={"#C2C2C2"}
            size={"24px"}
            className={style.icon}
            onClick={() => ref.current?.showPicker()}
          />
        ) : (
          <Icon
            icon={"calendar1"}
            color={"#C2C2C2"}
            size={"24px"}
            className={style.icon}
            onClick={() => ref.current?.showPicker()}
          />
        )}
      </div>
    </div>
  );
}
