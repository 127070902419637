import AssessmentDetail from "@Organism/InstructorDashboard/AssessmentDetail";
import { fetchInstructorClassProgress } from "@Services/instructor";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
export async function loader(route) {
  const params = route.params;
  const query = new URLSearchParams(route?.request?.url);
  console.log(params.id);
  const page = query?.get("page") || 1;
  const limit = query?.get("limit") || 10;
  const search = query?.get("search") || "";
  const getStudentAssesment = fetchInstructorClassProgress(
    params.id,
    params.sessionNum,
    limit,
    page,
    search
  );
  return defer({
    studentAssesment: getStudentAssesment,
  });
}
export default function AssessmentDetailPage() {
  const { studentAssesment } = useLoaderData();

  return (
    <Suspense
      fallback={
        <AssessmentDetail data={{ data: [], totalData: 0 }} isLoading />
      }
    >
      <Await
        resolve={studentAssesment}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <AssessmentDetail data={value} />}
      </Await>
    </Suspense>
  );
}
