import { API } from "@Config/index";

export function getUserSheet(classId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/usersheet/${classId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postAnswerPopupQuiz(
  userSheetId,
  sessionId,
  materialNum,
  answers
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/usersheet/${userSheetId}/session/${sessionId}/material/${materialNum}`,
        answers,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postAnswerTPM(userSheetId, sessionId, materialNum, answers) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(
        `/usersheet/${userSheetId}/session/${sessionId}/material/${materialNum}/abp`,
        answers,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postAnswerQuiz(userSheetId, sessionId, materialNum, answers) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/usersheet/${userSheetId}/session/${sessionId}/material/${materialNum}/ast`,
        { answers },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function setFinistMaterial(userSheetId, sessionId, materialNum) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/usersheet/${userSheetId}/session/${sessionId}/material/${materialNum}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function deleteAnswerTPM(userSheetId, sessionId, materialNum, value) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.delete(
        `/usersheet/${userSheetId}/session/${sessionId}/material/${materialNum}/answer/${value}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postUkAgreement(userSheetID) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/user-sheet/agreement/uk`,
        { userSheetID },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postAnswerSurvey(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user-sheet/answer/survey`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postAnswerPostPreTest(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/user-sheet/answer/post-pre-test`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function postAnswerTpm(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/user-sheet/answer/tpm`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postAnswerMatter(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user-sheet/answer/matter`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postAnswerUk(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/user-sheet/answer/uk`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postCompletionDate(payload, userSheetId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/user-sheet/completionDate/${userSheetId}`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
