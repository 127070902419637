import React, { Suspense } from "react";
import InstructorDashboardActivity from "@Organism/InstructorDashboard/Activity";
import { Await, useLoaderData } from "react-router-dom";


export default function InstructorActivityPage() {
  return (
    <Suspense fallback={<p>loading....</p>}>
      <Await
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <InstructorDashboardActivity />}
      </Await>
    </Suspense>
  );
}
