import LoadingContent from "@Molecule/Loading";
import Material from "@Organism/LearnClass/Material";
import React from "react";
import { userCourseStore } from "store";

export default function MaterialPage() {
  const { userSheetData } = userCourseStore();

  if (!userSheetData) {
    return <LoadingContent />;
  }
  return <Material data={userSheetData} />;
  // return (
  //   <Suspense fallback={<p>loading....</p>}>
  //     <Await
  //       resolve={classData}
  //       errorElement={
  //         <div style={{ height: "100vh" }}>
  //           <p>error...</p>
  //         </div>
  //       }
  //     >
  //       {(dataClass) => (
  //         <Await
  //           resolve={userSheet}
  //           errorElement={
  //             <div style={{ height: "100vh" }}>
  //               <p>error...</p>
  //             </div>
  //           }
  //         >
  //           {(userSheet) => (
  //             <Material
  //               data={dataClass.data}
  //               userSheet={userSheet.data}
  //               schedule={userSheet.classSchedule}
  //             />
  //           )}
  //         </Await>
  //       )}
  //     </Await>
  //   </Suspense>
  // );
  // return <Material />;
}
