import React from "react";
import style from "./index.module.scss";
export default function InputTextArea({
  labelValue = "Text Label",
  required,
  placeholder = "Placeholder",
  ...props
}) {
  return (
    <div className={style.container}>
      <label className={style.labelText}>
        {labelValue}
        {required && <span className={style.required}>*</span>}
      </label>
      <textarea
        type="text"
        className={style.inputText}
        placeholder={placeholder}
        {...props}
      ></textarea>
    </div>
  );
}
