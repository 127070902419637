import axios from "axios";

export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "https://api-live.skills.id/v2",
  // baseURL: "https://d186-103-153-229-210.ngrok-free.app/v2",
  // baseURL : "https://api-ths-stagging.skills.id/v1"
  // baseURL : "https://nmmrksgt-3000.asse.devtunnels.ms/v1"
});

export const fileBaseUrl = process.env.REACT_APP_BASE_URL + "/file/";
// export const fileBaseUrl = "https://dev-api.skills.id/file/";
