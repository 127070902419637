export const omitKey = (key, obj) => {
  const { [key]: omitted, ...rest } = obj;
  return rest;
}

export const omitKeys = (keys =[], obj) => {
  return  Object.keys(obj)
  .filter(k => !keys.includes(k))
  // eslint-disable-next-line no-sequences
  .reduce((acc, key) => ((acc[key] = obj[key]), acc), {});
}