import MyClass from "@Organism/StudentDashboard/MyClass";
import { getClassDashboardStudent } from "@Services/userCourse";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export function loader() {
  const getClassData = getClassDashboardStudent();

  return defer({
    getClassData,
  });
}

export default function StudentDashboardMyClass() {
  const { getClassData } = useLoaderData();
  // return <MyClass />;
  return (
    <Suspense fallback={<MyClass data={[]} isLoading />}>
      <Await
        resolve={getClassData}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <MyClass data={value.data} />}
      </Await>
    </Suspense>
  );
}
