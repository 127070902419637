import moment from 'moment'
import Styles from './style.module.scss'
import Images from '@Theme/Images'
import Icon from '@Atom/Icon'

export default function OfflineClassScheduleCard({
  data = {
    id: '',
    title: '',
    date: new Date(),
    timeStart: '',
    timeEnd: ''
  },
  accessibility = false,
  onClickOpen = () => { }
}) {

  return (
    <div className={`${Styles.container} ${accessibility ? Styles.active : Styles.inactive}`}>
      <div className={Styles.logo}>
        <img src={accessibility ? Images.CALENDAR_ACTIVE : Images.CALENDAR_INACTIVE} alt='' />
      </div>
      <div className={Styles.details}>
        <span>{data?.title}</span>
        <p>{`${moment(new Date(data?.date))?.format('LL')} • ${data?.timeStart} - ${data?.timeEnd}`}</p>
      </div>
      <div className={Styles.button}>
        <button onClick={onClickOpen} disabled={!accessibility}>
          <span>{accessibility ? 'Buka Kelas' : 'Kelas belum dapat diakses'}</span>
          <Icon icon={accessibility ? 'chevron-right-circle' : 'lock'} size={16} />
        </button>

      </div>
    </div>
  )
}