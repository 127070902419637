import ClassesSchedules from "@Organism/LearnClass/Schedules";
import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";

export default function SchedulesPage() {
  const { classData, userSheet } = useLoaderData();
  return (
    <Suspense fallback={<p>loading....</p>}>
      <Await
        resolve={classData}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(dataClass) => (
          <Await
            resolve={userSheet}
            errorElement={
              <div style={{ height: "100vh" }}>
                <p>error...</p>
              </div>
            }
          >
            {(userSheet) => (
              <ClassesSchedules data={dataClass.data} userSheet={userSheet} allSchedules={userSheet?.classSchedule} />
            )}
          </Await>
        )}
      </Await>
    </Suspense>
  );
}
