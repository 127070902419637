import React, { useState } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import Button from "@Atom/Button";
import { useNavigate } from "react-router-dom";
import CheckBox from "@Atom/Checkbox";
import { postUkAgreement } from "@Services/userSheet";

export default function UkAgreement({ data, setModal }) {
  const navigate = useNavigate();
  const [isAgree, setIsAgree] = useState(false);
  const handleAccessUk = async () => {
    try {
      const res = await postUkAgreement(data._id);
      if (res.status) {
        navigate("", { replace: true });
        setModal(false);
      }
    } catch (error) {}
  };
  return (
    <div className={Styles.container}>
      <img alt="" src={Images.WARNING} />
      <div className={Styles.modalBody}>
        <p>Kerjakan Uji Keterampilan?</p>
        <p>
          Apakah Anda yakin ingin mengakses halaman uji keterampilan? Setelah
          Anda mengakses halaman ini, Anda harus mengumpulkan tugas dalam waktu{" "}
          <span className={Styles.warning}>7x24</span> jam atau kelas ini akan
          hangus.
        </p>
      </div>
      <div className={Styles.modalAction}>
        <div
          className={Styles.checkboxWrapper}
          onClick={() => setIsAgree(!isAgree)}
        >
          <CheckBox isChecked={isAgree} />
          <p>Saya mengerti konsekuensi yang ada.</p>
        </div>
        <div className={Styles.buttonWrapper}>
          <Button
            variant="outlined"
            text={"Kembali"}
            onClick={() => navigate(-1)}
          />
          <Button
            disabled={!isAgree}
            text={"Akses halaman"}
            onClick={() => handleAccessUk()}
          />
        </div>
      </div>
    </div>
  );
}
