import React from "react";
import Styles from "./style.module.scss";
import { fileBaseUrl } from "@Config/index";
import RadioButton from "@Atom/RadioButton";

export default function MultipleChoiceCard({
  data,
  number,
  selected,
  setSelected,
}) {
  return (
    <div className={Styles.container}>
      <div className={Styles.questionWrapper}>
        <p>Soal {number}</p>
        {data?.contentIMG && (
          <img src={fileBaseUrl + data?.contentIMG} alt="" />
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: data?.contentHTML,
          }}
          className={Styles.question}
        />
        {data?.quizType === "Essay" && (
          <textarea
            placeholder="Jawaban Kamu"
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
          />
        )}
      </div>
      <div className={Styles.optionsWrapper}>
        {data?.options?.map((el, idx) => (
          <div
            className={Styles.option}
            key={idx}
            is-selected={String(selected === el.optionNum)}
          >
            <RadioButton
              onClick={() => setSelected(el.optionNum)}
              isSelected={selected === el.optionNum}
            />
            <div className={Styles.optionText}>
              <p>{el.optionValue}</p>
              {el.optionIMG && <img src={fileBaseUrl + el.optionIMG} alt="" />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
