import React from "react";

// Function to transform a string containing URLs into JSX elements with clickable links
export const transformStringToLinks = (text) => {
  const urlRegex = /(\bhttps?:\/\/[^\s]+[^\s.]+)/g;

  return text.split(urlRegex).map((part, index) => {
    if (urlRegex.test(part)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });
};
