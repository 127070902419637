import { useMemo, useState } from "react";
import Styles from "./style.module.scss";
import moment from "moment";
import Icon from "@Atom/Icon";
import { getDates } from "@Helpers/getDates";

export default function ScheduleCard({
  title = "",
  startDate = "",
  endDate = "",
  startTime = "",
  endTime = "",
  dateAsTitle = false,
  extra = "",
}) {
  const [expand, setExpand] = useState(false);

  const schedules = useMemo(() => {
    const dates = getDates(startDate, endDate);
    return dates;
  }, [endDate, startDate]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header} onClick={() => setExpand(!expand)}>
        <Icon icon={"calendar-filled"} size={20} />
        <div className={Styles.title}>
          <h3 className={Styles.titleText}>{title}</h3>
          <span className={dateAsTitle ? Styles.titleText : ""}>{`${moment(
            new Date(startDate)
          )?.format("LL")} - ${moment(new Date(endDate))?.format("LL")}`}</span>
          {extra && <span className={Styles.extra}>{extra}</span>}
        </div>
        <button>
          <Icon icon={expand ? "arrow-up" : "arrow-down"} size={24} />
        </button>
      </div>
      {expand && (
        <div className={Styles.content}>
          {schedules?.map((s, sI) => (
            <div key={sI} className={Styles.schedule}>
              {`Hari ${sI + 1}: ${moment(new Date(s)).format("LL")}, ${moment(
                new Date(startDate)
              ).format("HH:mm")} -  ${moment(new Date(endDate)).format(
                "HH:mm"
              )} WIB`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
