import OfflineClassScheduleCard from "@Molecule/OfflineClassScheduleCard";
import Images from "@Theme/Images";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Styles from "./styles.module.scss";
import Maps from "@Atom/Maps";
import { addLeadingZero } from "@Helpers/addLeadingZero";
import moment from "moment";
import { decryptStorageData } from "@Helpers/encryptDecrypt";

export default function ClassesSchedules({ data, userSheet, allSchedules }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = decryptStorageData("user", localStorage);

  const batch = useMemo(() => {
    return data?.batches?.find(
      (obj) => obj?.num === userSheet.data?.courseBatch
    );
  }, [data?.batches, userSheet?.data?.courseBatch]);

  const handleOnClick = (sIndex) => {
    navigate(`/classes/learn/${id}/chapter${sIndex + 1}-material1`, {
      replace: true,
    });
  };

  // const schedules = useMemo(() => {
  //   return allSchedules?.filter((obj) => obj?.courseBatch === batch?.num);
  // }, [allSchedules, batch?.num]);

  const activeSessionWebinar = (sIndex) => {
    if (sIndex === 0) {
      return userSheet?.data?.score?.preTest >= 0;
    }
    if (sIndex > 0) {
      if (
        userSheet?.data?.sessions[sIndex - 1]?.subjects
          .filter((el) => el.type !== "TASK")
          .some((el) => !el.isComplete)
      ) {
        return false;
      }
    }

    if (
      moment(new Date()).format("YYYY-MM-DD") <
      userSheet.classSchedule[sIndex]?.date
    ) {
      return false;
    } else if (
      moment(new Date()).format("YYYY-MM-DD") ===
      userSheet.classSchedule[sIndex]?.date
    ) {
      if (
        userSheet.classSchedule[0]?.startHour <=
        new Date().getHours() + 1 + ":" + new Date().getMinutes()
      ) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  };

  const isSessionOpen = (idx) => {
    if (user.isAssesor) {
      return true;
    }
    if (data.platform === "WEBINAR") {
      return activeSessionWebinar(idx);
    } else {
      return userSheet?.data?.sessions[idx]?.status !== "CLOSED";
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Jadwal Kelas</h3>
        <span>
          Berikut adalah informasi jadwal kelas dan juga link Zoom untuk setiap
          sesi yang akan diselenggarakan.
        </span>
      </div>
      <div className={Styles.divider} />
      <div className={Styles.content}>
        {data.platform !== "WEBINAR" && (
          <div className={` ${Styles.section}`}>
            <span>Lokasi Kelas</span>
            <div className={Styles.location}>
              <img src={Images.LOCATION} alt="" />
              <p>{batch?.offline?.address}</p>
            </div>
            <div className={Styles.mapCard}>
              <Maps
                mapWidth="100%"
                marker={{
                  lat: batch?.offline?.latitude,
                  lng: batch?.offline?.longitude,
                }}
              />
            </div>
          </div>
        )}
        <div className={`${Styles.section}`}>
          <span>Jadwal Kelas</span>
          <div className={Styles.schedules}>
            {userSheet?.classSchedule?.map((s, sI) => (
              <OfflineClassScheduleCard
                key={sI}
                data={{
                  id: 1,
                  title: `Sesi Kelas ${sI + 1}`,
                  date: new Date(s?.date),
                  timeStart: addLeadingZero(s?.startHour),
                  timeEnd: addLeadingZero(s?.endHour),
                }}
                accessibility={isSessionOpen(sI)}
                onClickOpen={() => handleOnClick(sI)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
