import { decryptStorageData } from "@Helpers/encryptDecrypt";
import useWindowSize from "@Hooks/useWindowSize";
import React, { useMemo, useState } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import Footer from "@Molecule/Footer";
import Table from "@Molecule/Table";
import Images from "@Theme/Images";
import moment from "moment";
import Icon from "@Atom/Icon";
import Searchbar from "@Atom/Searchbar";
import Button from "@Atom/Button";
import { useDebounce } from "@Hooks/useDebounce";
import Modal from "@Atom/Modal";
import InputField from "@Molecule/InputField";
import { getYearsArray } from "@Helpers/getArrayOfYear";
import {
  createAcademicYear,
  deleteAcademicYear,
  updateAcademicYear,
} from "@Services/admin";
import { useNavigate } from "react-router-dom";

export default function AcademicYearLayout({ data, loading = false }) {
  const user = decryptStorageData("user", localStorage);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const searchDebounce = useDebounce(searchTerm, 500);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const navigate = useNavigate();
  const [modalState, setModalState] = useState({
    startAcademicYear: "",
    endAcademicYear: "",
    firstSemester: {
      startDate: "",
      endDate: "",
      reportStartDate: "",
      reportEndDate: "",
    },
    secondSemester: {
      startDate: "",
      endDate: "",
      reportStartDate: "",
      reportEndDate: "",
    },
  });

  const handleDeleteYear = async (id) => {
    try {
      await deleteAcademicYear(id);
      navigate("", { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeState = (key, value, child) => {
    const temp = { ...modalState };
    if (child) {
      temp[child][key] = value;
    } else {
      temp[key] = value;
    }
    setModalState(temp);
  };

  const { width } = useWindowSize();

  const handleSubmitAcademicYear = async () => {
    const payload = {
      ...modalState,
      academicYear:
        modalState.startAcademicYear + "-" + modalState.endAcademicYear,
    };
    try {
      if (modalState.id) {
        await updateAcademicYear(modalState.id, payload);
      } else {
        await createAcademicYear(payload);
      }
      setModalState({
        startAcademicYear: "",
        endAcademicYear: "",
        firstSemester: {
          startDate: "",
          endDate: "",
          reportStartDate: "",
          reportEndDate: "",
        },
        secondSemester: {
          startDate: "",
          endDate: "",
          reportStartDate: "",
          reportEndDate: "",
        },
      });
      setOpenModalAdd(false);
      navigate("", { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  const dataToMap = useMemo(() => {
    return data.filter((el) => el.academicYear.includes(searchTerm));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchDebounce]);

  const template = useMemo(() => {
    return {
      data: dataToMap,
      columns: [
        {
          name: "",
          title: "Tahun Ajaran",
          renderData: (row) => <h4>{row.academicYear}</h4>,
        },
        {
          name: "",
          title: "Periode Semester 1",
          renderData: (row) => (
            <span>
              {moment(row.firstSemester.startDate).format("DD MMM YYYY")} -{" "}
              {moment(row.firstSemester.endDate).format("DD MMM YYYY")}
            </span>
          ),
        },
        {
          name: "",
          title: "Periode Semester 2",
          renderData: (row) => (
            <span>
              {moment(row.secondSemester.startDate).format("DD MMM YYYY")} -{" "}
              {moment(row.secondSemester.endDate).format("DD MMM YYYY")}
            </span>
          ),
        },
        {
          name: "",
          title: "Penerbitan Rapor Akhir",
          renderData: (row) => (
            <span>
              {moment(row.secondSemester.reportStartDate).format("DD MMM YYYY")}{" "}
              - {moment(row.secondSemester.reportEndDate).format("DD MMM YYYY")}
            </span>
          ),
        },
        {
          name: "",
          title: "Status",
          renderData: (row) => (
            <div>
              {new Date(row.secondSemester.reportEndDate) > new Date() &&
              new Date() > new Date(row.firstSemester.startDate) ? (
                <div className={Styles.status} status="on-going">
                  <p>Berlangsung</p>
                </div>
              ) : new Date(row.secondSemester.reportEndDate) < new Date() ? (
                <div className={Styles.status} status="end">
                  <p>Berakhir</p>
                </div>
              ) : (
                <div className={Styles.status} status="not-started">
                  <p>Belum Dimulai</p>
                </div>
              )}
            </div>
          ),
        },
        {
          name: "",
          title: "",
          renderData: (row) => (
            <div className={Styles.rowAction}>
              <Icon
                icon={"edit-2"}
                size={20}
                color={
                  new Date(row.firstSemester.startDate) < new Date()
                    ? "#9E9E9E"
                    : "#9360A8"
                }
                onClick={() => {
                  if (new Date(row.firstSemester.startDate) > new Date()) {
                    setModalState({
                      startAcademicYear: row.academicYear.split("-")[0],
                      endAcademicYear: row.academicYear.split("-")[1],
                      firstSemester: {
                        startDate: moment(row.firstSemester.startDate).format(
                          "YYYY-MM-DD"
                        ),
                        endDate: moment(row.firstSemester.endDate).format(
                          "YYYY-MM-DD"
                        ),
                        reportStartDate: moment(
                          row.firstSemester.reportStartDate
                        ).format("YYYY-MM-DD"),
                        reportEndDate: moment(
                          row.firstSemester.reportEndDate
                        ).format("YYYY-MM-DD"),
                      },
                      secondSemester: {
                        startDate: moment(row.secondSemester.startDate).format(
                          "YYYY-MM-DD"
                        ),
                        endDate: moment(row.secondSemester.endDate).format(
                          "YYYY-MM-DD"
                        ),
                        reportStartDate: moment(
                          row.secondSemester.reportStartDate
                        ).format("YYYY-MM-DD"),
                        reportEndDate: moment(
                          row.secondSemester.reportEndDate
                        ).format("YYYY-MM-DD"),
                      },
                      id: row._id,
                    });
                    setOpenModalAdd(true);
                  }
                }}
              />
              <Icon
                icon={"trash1"}
                size={20}
                onClick={() => {
                  if (new Date(row.firstSemester.startDate) > new Date()) {
                    handleDeleteYear(row._id);
                  }
                }}
                color={
                  new Date(row.firstSemester.startDate) < new Date()
                    ? "#9E9E9E"
                    : "#D1421A"
                }
              />
            </div>
          ),
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToMap, width]);

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <DoodleGradientCard className={Styles.header}>
          <div className={Styles.title}>
            <h3>Selamat datang {<span>{user?.name}</span>}</h3>
            <span>Berikut adalah daftar tahun ajaran yang tersedia</span>
          </div>
          <div>
            <img src={Images.BOOK} alt="" />
          </div>
        </DoodleGradientCard>
        <div className={Styles.topSection}>
          <Searchbar
            value={searchTerm}
            setValue={setSearchTerm}
            placeholder="Cari tahun ajaran"
          />
          <div>
            <Button
              text={"Tambah Tahun Ajaran"}
              startIcon={"add-circle"}
              onClick={() => setOpenModalAdd(true)}
            />
          </div>
        </div>
        <Table
          data={template?.data}
          totalData={data?.length}
          totalPage={Math.ceil(data.length / limit)}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table} ${
            !template?.data?.length ? Styles.grow : ""
          }`}
          limit={limit}
          setLimit={setLimit}
          withPagination
          isLoading={loading}
          withNumbering
          //   expandComponent={(row) => (
          //     <div className={Styles.expandedInfo}>
          //       <div className={Styles.infoRow}>
          //         <span>Email</span>
          //         <span>{row?.email}</span>
          //       </div>
          //       <div className={Styles.infoRow}>
          //         <span>No. Telepon</span>
          //         <span>{row?.phoneNumber}</span>
          //       </div>
          //     </div>
          //   )}
        />
      </div>
      <Modal isOpen={openModalAdd}>
        <div className={Styles.modalContainer}>
          <p>Tambah Tahun Ajaran Baru</p>
          <div className={Styles.section}>
            {/* <p>title</p> */}
            <div>
              <InputField
                value={modalState.startAcademicYear}
                setValue={(newVal) => {
                  setModalState((prev) => ({
                    ...prev,
                    startAcademicYear: newVal,
                    endAcademicYear: String(+newVal + 1),
                  }));
                }}
                placeholder="Tahun Mulai"
                isDropdown
                dropdownOptions={getYearsArray(10)}
                isLoadingDropdown={false}
                title={"Tahun Mulai"}
              />
              <div className={Styles.inputWrapper}>
                <p>
                  Tahun Berakhir <span>*</span>
                </p>
                <input
                  value={modalState.endAcademicYear}
                  placeholder="Pilih tahun mulai"
                  disabled
                  // setValue={(newVal) =>
                  //   handleChangeForm("endAcademicYear", newVal)
                  // }

                  // dropdownOptions={getYearsArray()}
                  // isLoadingDropdown={false}
                />
              </div>
            </div>
          </div>
          <div className={Styles.divider} />
          <div className={Styles.section}>
            <p>Periode Semester 1</p>
            <div>
              <InputField
                isDate
                required
                placeholder="Tanggal dimulai"
                value={modalState.firstSemester.startDate}
                setValue={(val) =>
                  handleChangeState("startDate", val, "firstSemester")
                }
              />
              <InputField
                isDate
                required
                placeholder="Tanggal berakhir"
                value={modalState.firstSemester.endDate}
                setValue={(val) =>
                  handleChangeState("endDate", val, "firstSemester")
                }
                min={
                  modalState.firstSemester.startDate &&
                  moment(modalState.firstSemester.startDate).format(
                    "YYYY-MM-DD"
                  )
                }
              />
            </div>
          </div>
          <div className={Styles.section}>
            <p>Periode Penerbitan Rapor Semester 1</p>
            <div>
              <InputField
                isDate
                required
                placeholder="Tanggal dimulai"
                value={modalState.firstSemester.reportStartDate}
                setValue={(val) =>
                  handleChangeState("reportStartDate", val, "firstSemester")
                }
              />
              <InputField
                isDate
                required
                placeholder="Tanggal berakhir"
                value={modalState.firstSemester.reportEndDate}
                setValue={(val) =>
                  handleChangeState("reportEndDate", val, "firstSemester")
                }
                min={
                  modalState.firstSemester.reportStartDate &&
                  moment(modalState.firstSemester.reportStartDate).format(
                    "YYYY-MM-DD"
                  )
                }
              />
            </div>
          </div>
          <div className={Styles.divider} />
          <div className={Styles.section}>
            <p>Periode Semester 2</p>
            <div>
              <InputField
                isDate
                required
                placeholder="Tanggal dimulai"
                value={modalState.secondSemester.startDate}
                setValue={(val) =>
                  handleChangeState("startDate", val, "secondSemester")
                }
              />
              <InputField
                isDate
                required
                placeholder="Tanggal berakhir"
                value={modalState.secondSemester.endDate}
                setValue={(val) =>
                  handleChangeState("endDate", val, "secondSemester")
                }
                min={
                  modalState.secondSemester?.startDate &&
                  moment(modalState.secondSemester.startDate).format(
                    "YYYY-MM-DD"
                  )
                }
              />
            </div>
          </div>
          <div className={Styles.section}>
            <p>Periode Penerbitan Rapor Semester 2</p>
            <div>
              <InputField
                isDate
                required
                placeholder="Tanggal dimulai"
                value={modalState.secondSemester.reportStartDate}
                setValue={(val) =>
                  handleChangeState("reportStartDate", val, "secondSemester")
                }
              />
              <InputField
                isDate
                required
                placeholder="Tanggal berakhir"
                value={modalState.secondSemester.reportEndDate}
                min={
                  modalState.secondSemester?.reportStartDate &&
                  moment(modalState.secondSemester.reportStartDate).format(
                    "YYYY-MM-DD"
                  )
                }
                setValue={(val) =>
                  handleChangeState("reportEndDate", val, "secondSemester")
                }
              />
            </div>
          </div>
          <div className={Styles.section}>
            <div>
              <Button
                text={"Kembali"}
                variant="outlined"
                onClick={() => {
                  setModalState({
                    startAcademicYear: "",
                    endAcademicYear: "",
                    firstSemester: {
                      startDate: "",
                      endDate: "",
                      reportStartDate: "",
                      reportEndDate: "",
                    },
                    secondSemester: {
                      startDate: "",
                      endDate: "",
                      reportStartDate: "",
                      reportEndDate: "",
                    },
                  });
                  setOpenModalAdd(false);
                }}
              />
              <Button
                text={"Simpan"}
                onClick={() => handleSubmitAcademicYear()}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Footer />
    </div>
  );
}
