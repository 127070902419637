import React from "react";
import Styles from "./style.module.scss";
import ClassImage from "@Theme/ClassImage";

export default function AcademicYearCard({ academicYear, grade, schoolLevel, onClick }) {
  return (
    <div className={Styles.classCard} onClick={onClick}>
      <div className={Styles.cardImage}>
        <div className={Styles.imageOverlay}>
          <p>{academicYear}</p>
        </div>
        <img src={ClassImage(grade)} alt="" />
      </div>
      <div className={Styles.cardBody}>
        <div className={Styles.grade}>
          <p>{grade}</p>
        </div>
        <div className={Styles.cardText}>
          <p>{schoolLevel}</p>
          <p>Kelas {grade}</p>
        </div>
      </div>
    </div>
  );
}
