import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import GradientArrowRight from "@Assets/Icon/gradientArrowRight.svg";
import IconPlayVideo from "@Assets/Icon/iconPlayVideo.svg";
import Images from "@Theme/Images";

export default function ThumbnailClassVideo({
  isLockedClass=true,
  photo = Images.IMAGE_THUMBNAIL,
  onClick = () => {},
}) {
  
  const backgroundImageStyle = {
    backgroundImage: `url(${photo})`,
  };
  return (
    <div>
      {isLockedClass ? (
        <section
          className={Styles.container}
          isLockedClass={isLockedClass ? "true" : "false"}
          style={backgroundImageStyle}
        >
          <div className={Styles.lockedClass}>
            <div className={Styles.iconLockedClass}>
              <Icon
                icon={"lock_outline"}
                color={"#26255B"}
                size={"32"}
                className={Styles.icon}
              />
            </div>
            <div className={Styles.description}>
              Silahkan selesaikan video sebelumnya terlebih dahulu agar dapat
              melanjutkan ke materi selanjutnya.
            </div>
            <div className={Styles.button} onClick={onClick}>
              <span>Kembali Ke Materi Sebelumnya</span>
              <img
                src={GradientArrowRight}
                alt="arrow"
                width={"16"}
                height={"16"}
              />
            </div>
          </div>
        </section>
      ) : (
        <section className={Styles.container} style={backgroundImageStyle}>
          <img
            src={IconPlayVideo}
            alt="play"
            onClick={onClick}
            className={Styles.playVideo}
          />
        </section>
      )}
    </div>
  );
}
