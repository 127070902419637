import { useEffect, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import useOutsideClick from "@Hooks/useOutsideClick";

export default function Select({
  value,
  setValue,
  options,
  placeholder,
  className = "",
  width = "auto",
  position = "right",
  ...props
}) {
  const [expand, setExpand] = useState(false);

  const boxRef = useRef();
  const boxOutsideClick = useOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && expand) {
      setExpand(false);
    }
  }, [boxOutsideClick, expand]);

  return (
    <div
      {...props}
      ref={boxRef}
      style={{ width }}
      className={`${Styles.container} ${className}`}
      onClick={() => setExpand(!expand)}
    >
      <span>{value}</span>
      <Icon
        className={expand ? Styles.rotate : ""}
        icon={"keyboard_arrow_down"}
        size={18}
      />

      {expand && (
        <div className={Styles.optionsWrapper} position={position}>
          {options?.map((o, oI) => (
            <div key={oI} onClick={() => setValue(o)}>
              {o}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
