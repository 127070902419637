import {
  deleteAnswerTPM,
  getUserSheet,
  postAnswerPopupQuiz,
  postAnswerQuiz,
  postAnswerTPM,
  setFinistMaterial,
} from "@Services/userSheet";
import { create } from "zustand";

export const userCourseStore = create((set) => ({
  userSheetData: null,
  loading: false,
  error: false,
  fetchUserSheet: async (id) => {
    set({ loading: true, error: null });
    try {
      const { data } = await getUserSheet(id);
      set({ userSheetData: data, loading: false });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },
  postAnswerAST: async (userSheetId, sessionId, materialNum, payload) => {
    set({ loading: true, error: null });
    try {
      await postAnswerQuiz(userSheetId, sessionId, materialNum, payload);
      set({ loading: false });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },
  postAnswerPopupQuiz: async (userSheetId, sessionId, materialNum, payload) => {
    set({ loading: true, error: null });
    try {
      await postAnswerPopupQuiz(userSheetId, sessionId, materialNum, payload);
      const { data } = await getUserSheet(userSheetId);
      set({ userSheetData: data, loading: false });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },
  postAnswerTPM: async (userSheetId, sessionId, materialNum, payload) => {
    set({ loading: true, error: null });
    try {
      await postAnswerTPM(userSheetId, sessionId, materialNum, payload);
      const { data } = await getUserSheet(userSheetId);
      set({ userSheetData: data, loading: false });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },
  setFinishMatter: async (userSheetId, sessionId, materialNum) => {
    set({ loading: true, error: null });
    try {
      await setFinistMaterial(userSheetId, sessionId, materialNum);
      const { data } = await getUserSheet(userSheetId);
      set({ userSheetData: data, loading: false });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },
  deleteAnswerTPM: async (userSheetId, sessionId, materialNum, value) => {
    set({ loading: true, error: null });
    try {
      await deleteAnswerTPM(userSheetId, sessionId, materialNum, value);
      const { data } = await getUserSheet(userSheetId);
      set({ userSheetData: data, loading: false });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },
}));
