import React, { useEffect, useMemo } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Button from "@Atom/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Searchbar from "@Atom/Searchbar";
import { useState } from "react";
import Table from "@Molecule/Table";
import useQuery from "@Hooks/useQuery";
import { useDebounce } from "@Hooks/useDebounce";
import Modal from "@Atom/Modal";
import { fileBaseUrl } from "@Config/index";
import InputFileModal from "@Molecule/InputFileModal";
import { updateTeachersData } from "@Services/admin";
import Footer from "@Molecule/Footer";

export default function TeacherListLayout({ data, loading }) {
  const user = decryptStorageData("user", localStorage);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const query = useQuery();
  const pageQuery = query?.get("page");
  const limitQuery = query?.get("limit");
  const searchQuery = query?.get("search");
  const debouncedSearchQuery = useDebounce(search, 500);
  const { pathname } = useLocation();
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [error, setError] = useState("");
  const [loadingUploadFile, setLoadingUploadFile] = useState(false)

  useEffect(() => {
    setPage(+pageQuery || 1);
    setLimit(+limitQuery || 10);
    setSearch(searchQuery || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitQuery, pageQuery, searchQuery]);


 const isLoading = useMemo(() => {
    return page !== +pageQuery || search !== searchQuery || limit !== +limitQuery
 }, [page,pageQuery,search,searchQuery,limit,limitQuery]);


  useEffect(() => {
    navigate(`${pathname}?s=1&limit=${limit}&page=${page}&search=${search}`, {
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, navigate, debouncedSearchQuery]);

  const template = useMemo(() => {
    return {
      data: data?.data?.map((obj) => {
        return {
          ...obj,
          id: obj?._id,
        };
      }),
      // .filter((el) =>
      //   el.name.toLowerCase().includes(searchTerm.toLowerCase())
      // )
      // .slice(limit * (page - 1), limit * page),
      columns: [
        {
          name: "",
          title: "Nama Guru",
          renderData: (row) => (
            <div
              className={Styles.student}
              onClick={() => navigate(`edit/${row?._id}`)}
            >
              <span>{row?.name}</span>
            </div>
          ),
        },
        {
          name: "email",
          title: "Email",
        },
        {
          name: "phoneNumber",
          title: "No Telpon",
        },
      ],
    };
  }, [data, navigate]);

  const handleUploadData = async (file) => {
    try {
      setLoadingUploadFile(true)
      await updateTeachersData(file);
      setLoadingUploadFile(false)

      setOpenUploadModal(false);
      setError("");
      navigate(`${pathname}?s=1&limit=${limit}&page=${page}&search=${search}`, { replace: true });
    } catch (error) {
      setLoadingUploadFile(false)
      setError(error.response.data.message);
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <DoodleGradientCard className={Styles.header}>
          <div className={Styles.title}>
            <h3>Selamat datang {<span>{user?.name}</span>}</h3>
            <span>Berikut adalah daftar seluruh guru di THS</span>
          </div>
          <Button
            variant="outlined-neutral"
            text={"Tambah Guru"}
            startIcon={"add"}
            onClick={() => navigate("add")}
          />
        </DoodleGradientCard>
        <div className={Styles.body}>
          <div className={Styles.headWrapper}>
            <Searchbar
              value={search}
              setValue={setSearch}
              placeholder="Cari Guru"
            />
            <Button
              variant="outlined"
              text={"Upload Data"}
              startIcon={"document-upload-filled"}
              onClick={() => setOpenUploadModal(true)}
            />
          </div>
        </div>
        <Table
          data={template?.data}
          totalData={data?.totalData}
          totalPage={Math.ceil((data?.totalData || 1) / limit)}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table} ${
            !template?.data?.length ? Styles.grow : ""
          }`}
          limit={limit}
          setLimit={setLimit}
          withPagination
          isLoading={loading || isLoading}
          withNumbering
          expandComponent={(row) => (
            <div className={Styles.expandedInfo}>
              <div className={Styles.infoRow}>
                <span>Email</span>
                <span>{row?.email}</span>
              </div>
              <div className={Styles.infoRow}>
                <span>No. Telepon</span>
                <span>{row?.phoneNumber}</span>
              </div>
            </div>
          )}
        />
      </div>
      <Footer />

      <Modal isOpen={openUploadModal}>
        <InputFileModal
          text="Guru"
          onClose={() => setOpenUploadModal(false)}
          handleSubmit={(file) => handleUploadData(file)}
          withTemplate
          error={error}
          loading={loadingUploadFile}
          templateUrl={
            fileBaseUrl + "1732766982976-Template_Input_Teacher.xlsx"
          }
        />
      </Modal>
    </div>
  );
}
