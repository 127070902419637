import { API } from "@Config/index";

export function getRedeemCode(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/prakerja/redeemCode/${id}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function checkPrakerjaStatus(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/prakerja/check-status`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
