import Icon from "@Atom/Icon";
import style from "./index.module.scss";
import React, { useState, useEffect } from "react";
import Text from "@Atom/Text";

export default function PreTestWarningInformation({
  headerText = "Informasi",
  description = "PENTING! SELESAIKAN KUIS SEKARANG! Syarat nilai kuis minimal 80 untuk dapat mengikuti sesi berikutnya.",
}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 430);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 430);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={style.wrapperAlertWarning}>
      <div>
        <Icon icon={"playlist_add_check"} color={"#ECBE6C"} size={isMobile ? "26" : "40"} />
      </div>
      <div className={style.wrapperContent}>
        <div>
          <Text
            color={"#404040"}
            size={isMobile ? "s" : "l"}
            width={"semibold"}
          >
            {headerText}
          </Text>
        </div>
        <div>
          <Text
            size={isMobile ? "xs" : "m"}
            color={"#232323"}
            width={"regular"}
          >
            {description}
          </Text>
        </div>
      </div>
    </div>
  );
}
