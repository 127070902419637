import React, { useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";

export default function AccordionAdmin({ head, body, ...props }) {
  const [collapse, setCollapse] = useState(false);
  return (
    <div className={Styles.container}>
      <div
        className={Styles.headSection}
        onClick={() => setCollapse(!collapse)}
        is-collapse={collapse ? "true" : "false"}
        {...props}
      >
        {head}
        <Icon
          icon={"keyboard_arrow_down"}
          size={24}
          is-collapse={collapse ? "true" : "false"}
        />
      </div>
      <div
        className={Styles.bodySection}
        is-collapse={collapse ? "true" : "false"}
      >
        {body}
      </div>
    </div>
  );
}
