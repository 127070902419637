import Styles from './stye.module.scss'

import Icon from "@Atom/Icon";
import { formatBytes } from '@Helpers/formatBytes';
import { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

export const DragAndDropInput = ({
  file,
  setFile,
  accept
}) => {
  const [preview, setPreview] = useState(file)
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragActive,
    isDragReject,
    isDragAccept,
    // fileRejections,
    acceptedFiles,
    open
  } = useDropzone({
    // onDrop,
    accept,
    multiple: false,
    noClick: true,
    // maxSize: 1048576 * 3
  });

  useEffect(() => {
    if (acceptedFiles?.length) {
      setFile(acceptedFiles[0])
      setPreview(URL.createObjectURL(acceptedFiles[0]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  useEffect(() => {
    if (file instanceof File) {
      setPreview(URL.createObjectURL(file))
    }
  }, [file])

  const baseStyle = useMemo(() => {
    return {
      // backgroundColor: 'transparent',
    }
  }, []);


  const focusedStyle = useMemo(() => {
    return {
      // borderColor: '#2196f3',
    }
  }, []);

  const acceptStyle = useMemo(() => {
    return {
      // borderColor: '#00e676',
      // backgroundColor: '#E9ECEF',
      // backgroundColor: 'green'
    }
  }, []);

  const rejectStyle = useMemo(() => {
    return {
      // borderColor: '#ff1744',
      backgroundColor: '#FFBBBB'
    }
  }, []);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragActive ? { backgroundColor: '#F5F7FE' } : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [baseStyle, isFocused, focusedStyle, isDragAccept, acceptStyle, isDragReject, rejectStyle, isDragActive]);

  return (

    <div
      {...getRootProps({ style })}
      className={`${Styles.field} ${file ? Styles.withFile : ''}`}
      onClick={(e) => e?.stopPropagation()}
    >
      <input
        {...getInputProps()}
        type='file'
        hidden
      />
      {
        !file
          ?
          <div className={Styles.noPreview}>
            <Icon icon={'photo'} size={40} color={!file ? '#9E9E9E' : '#1571DE'} />
            <p>{!file ? 'Drag and Drop File Disini' : file?.name || 'file.jpg'}</p>
            <span>Atau</span>
            <button
              onClick={open}
            >
              {!file ? 'Pilih file' : 'Ubah file'}
            </button>

          </div>
          :

          <div className={Styles.withPreview}>
            <img src={preview} alt='' />
            <div>
              <span>{file?.name || 'File'}</span>
              <span>{formatBytes(file?.size)}</span>
            </div>
            <button onClick={() => setFile(null)}>
              <Icon icon={'trash'} size={24} color={'#FF3E13'} />
            </button>
          </div>


      }

    </div>
  )
}