/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import { useDropzone } from "react-dropzone";
import { useEffect, useState } from "react";
import PhotoPositioningModal from "@Molecule/PhotoPositioningModal";
import PhotoPositioningModalWithDisplay from "@Molecule/PhotoPositioningModalWithDisplay";

export default function PhotoField({
  value,
  setValue,
  setAvatar,
  withDisplay = false,
}) {
  const [tempFile, setTempFile] = useState(value || null);
  const [backgroundColor, setBackgroundColor] = useState("#46B7E9");

  const {
    getRootProps,
    getInputProps,
    // fileRejections,
    acceptedFiles,
    open,
  } = useDropzone({
    // onDrop,
    accept: { "image/jpeg": [".jpeg", ".png", ".jpg"] },
    multiple: false,
    noClick: true,
    // maxSize: 1048576 * 3
  });

  useEffect(() => {
    if (acceptedFiles?.length) {
      setTempFile(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  return (
    <div
      {...getRootProps()}
      className={Styles.container}
      style={{ background: value ? backgroundColor : "transparent" }}
    >
      {value ? (
        <img
          src={
            value
              ? typeof value === "string"
                ? value
                : URL.createObjectURL(value)
              : ""
          }
          alt=""
          onClick={open}
        />
      ) : (
        <div className={Styles.empty}>
          <Icon icon={"user-line"} size={191} color={"#E0E0E0"} />
          <span>Ukuran foto 234 x 315 px</span>
          <Button variant="outlined" text={"Tambah Foto"} onClick={open} />
        </div>
      )}
      {withDisplay ? (
        <PhotoPositioningModalWithDisplay
          file={tempFile}
          onSave={(file, croppedFile, bg) => {
            setValue(file);
            setAvatar(croppedFile);
            setBackgroundColor(bg);
            setTempFile(null);
          }}
          onClose={() => setTempFile(null)}
        />
      ) : (
        <PhotoPositioningModal
          file={tempFile}
          onSave={(file, croppedFile, bg) => {
            setValue(file);
            setAvatar(croppedFile);
            setBackgroundColor(bg);
            setTempFile(null);
          }}
          onClose={() => setTempFile(null)}
        />
      )}
    </div>
  );
}
