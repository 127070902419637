import { API } from "@Config/index";
import axios from "axios";

export function createClass(payload, isVideo = false) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/courses`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "ngrok-skip-browser-warning": "true",
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function editClassInstructor(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(`/courses/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "ngrok-skip-browser-warning": "true",
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getAllCourses() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/course`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getRegulations() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/regulation`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function updateRegulations(id, content) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/regulation/${id}`,
        { regulationHTML: content },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function createRegulations(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/regulation`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCourse(id = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/courses/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCoursebyVoucher(voucher) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/voucher/class/${voucher}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
