import Step from '@Atom/Step'
import Styles from './style.module.scss'

export default function Stepper({
  arr = [],
  type = 'linear'
}) {

  //type : linear 

  // const arr = [
  //   {
  //     title: 'Detail Kelas',
  //     isActive: false,
  //     isCompleted: false,
  //     onClick: () => { }
  //   }
  // ]

  return (
    <div className={Styles.container}>
      {
        arr?.map((s, sI) => (
          <Step
            key={sI}
            type={type}
            title={s?.title}
            isActive={s?.isActive}
            isCompleted={s?.isCompleted}
            onClick={s?.onClick}
            disabled={s?.disabled}
          />
        ))
      }
    </div>
  )
}