import Tag from '@Atom/Tag'
import Styles from './style.module.scss'
import { useState } from 'react'
import { makeRandomString } from '@Helpers/makeRandomString'

export default function MultipleTagInput({
  values,
  setValues,
  placeholder
}) {
  const [state, setState] = useState('')

  // values = [{id: 'foo', name: ''}]
  // id is a must for distinction between objects

  return (
    <div className={Styles.container}>
      <div className={Styles.box}>
        {
          !!values?.length
          &&
          <div className={Styles.valuesWrapper}>
            {
              values?.map((v, vI) => (
                <Tag
                  key={vI}
                  value={v?.value}
                  onDelete={() => setValues([...values]?.filter(obj => obj?.id !== v?.id))}
                />
              ))
            }
          </div>
        }


        <input
          placeholder={placeholder}
          value={state}
          onChange={(e) => setState(e?.target?.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              // setValues(prev => {
              //   return [
              //     ...prev,
              //     {
              //       id: makeRandomString(5),
              //       value: state
              //     }
              //   ]
              // })
              setValues([...values, {
                id: makeRandomString(5),
                value: state
              }])
              setState('')
            }
          }}
        />
      </div>

      <p>
        <span>Tekan enter pada keyboard Anda untuk menambahkan tag.</span>
      </p>
    </div>
  )
}