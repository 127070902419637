import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import RightArrow from "@Assets/Icon/arrow-circle-right-outlined.svg";
import { useNavigate } from "react-router-dom";

export default function TaskAssessment({ data }) {
  const navigate = useNavigate();
  return (
    <div className={Styles.container}>
      <div className={Styles.cardWrapper}>
        {data?.sessions.map((el, idx) => (
          <div className={Styles.sessionCard} key={idx}>
            <div className={Styles.cardIcon}>
              <Icon icon={"book2"} size={32} color={"#FFF"} />
            </div>
            <div className={Styles.cardText}>
              <p>
                Bagian {idx + 1} - {el.sessionTitle}
              </p>
              <div className={Styles.cardDetail}>
                <p>
                  Total Asesmen Diselesaikan Murid :{" "}
                  <span>
                    {el.totalCompleted}/{el.totalAssesment}
                  </span>
                </p>
                <p className={Styles.divider}>|</p>
                <p>
                  Total Asesmen Dinilai :{" "}
                  <span>
                    {el.totalGraded}/{el.totalCompleted}
                  </span>
                </p>
              </div>
            </div>
            <div className={Styles.cardAction}>
              <img
                alt=""
                src={RightArrow}
                onClick={() => navigate(`${idx + 1}?classSubject=${data.subjectName}`)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
