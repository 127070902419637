import Styles from "./text.module.scss";

export const classPicker = (size, width) => {
  if (size === "xs" && width === "regular") {
    return Styles.xsRegular;
  }
  if (size === "xs" && width === "medium") {
    return Styles.xsMedium;
  }
  if (size === "xs" && width === "semibold") {
    return Styles.xsSemibold;
  }
  if (size === "xs" && width === "bold") {
    return Styles.xsBold;
  }
  if (size === "s" && width === "regular") {
    return Styles.sRegular;
  }
  if (size === "s" && width === "medium") {
    return Styles.sMedium;
  }
  if (size === "s" && width === "semibold") {
    return Styles.sSemibold;
  }
  if (size === "s" && width === "bold") {
    return Styles.sBold;
  }
  if (size === "m" && width === "regular") {
    return Styles.mRegular;
  }
  if (size === "m" && width === "medium") {
    return Styles.mMedium;
  }
  if (size === "m" && width === "semibold") {
    return Styles.mSemibold;
  }
  if (size === "m" && width === "bold") {
    return Styles.mBold;
  }
  if (size === "l" && width === "regular") {
    return Styles.lRegular;
  }
  if (size === "l" && width === "medium") {
    return Styles.lMedium;
  }
  if (size === "l" && width === "semibold") {
    return Styles.lSemibold;
  }
  if (size === "l" && width === "bold") {
    return Styles.lBold;
  }
  if (size === "h4" && width === "regular") {
    return Styles.h4Regular;
  }
  if (size === "h4" && width === "medium") {
    return Styles.h4Medium;
  }
  if (size === "h4" && width === "semibold") {
    return Styles.h4Semibold;
  }
  if (size === "h4" && width === "bold") {
    return Styles.h4Bold;
  }
  if (size === "h3" && width === "regular") {
    return Styles.h3Regular;
  }
  if (size === "h3" && width === "medium") {
    return Styles.h3Medium;
  }
  if (size === "h3" && width === "semibold") {
    return Styles.h3Semibold;
  }
  if (size === "h3" && width === "bold") {
    return Styles.h3Bold;
  }
  if (size === "h2" && width === "regular") {
    return Styles.h2Regular;
  }
  if (size === "h2" && width === "medium") {
    return Styles.h2Medium;
  }
  if (size === "h2" && width === "semibold") {
    return Styles.h2Semibold;
  }
  if (size === "h2" && width === "bold") {
    return Styles.h2Bold;
  }
  if (size === "h1" && width === "regular") {
    return Styles.h1Regular;
  }
  if (size === "h1" && width === "medium") {
    return Styles.h1Medium;
  }
  if (size === "h1" && width === "semibold") {
    return Styles.h1Semibold;
  }
  if (size === "h1" && width === "bold") {
    return Styles.h1Bold;
  }
  return Styles.mRegular;
};
