import React from "react";
import Styles from "./styles.module.scss";
import Text from "@Atom/Text";
import { useMemo } from "react";


export default function ClassesOverview({ data }) {

  const about = useMemo(() => {
    return [
      {
        type: "Tingkat Keahlian",
        value: data?.level,
      },
      {
        type: "Bahasa",
        value: data?.language,
      },
      {
        type: "Jumlah Sesi",
        value: data?.sessions?.length,
      },
      {
        type: "Peserta",
        value: "-",
      },
      {
        type: "Caption",
        value: "tidak",
      },
      {
        type: "Jumlah Materi",
        value: data?.sessions?.map(obj => obj?.subjects?.filter(obj => obj?.type === 'MATTER')?.length)?.reduce((accumulator, currentValue) => {
          return accumulator + currentValue
        }, 0),
      },
    ];
  }, [data]);
  return (
    <div className={Styles.container}>
      <div className={Styles.section}>
        <div className={Styles.sectionTitle}>
          <Text size={"h4"} width={"medium"}>
            Tentang kursus ini
          </Text>
        </div>
        <div className={Styles.aboutSection}>
          {about.map((el, idx) => (
            <div key={idx}>
              <Text size={"m"} width={"regular"} color={"#9E9E9E"}>
                {el.type}
              </Text>
              <Text size={"m"} width={"regular"} color={"#404040"}>
                : {el.value}
              </Text>
            </div>
          ))}
        </div>
      </div>
      <div className={Styles.section}>
        <div className={Styles.sectionTitle}>
          <Text size={"h4"} width={"medium"}>
            Deskripsi
          </Text>
        </div>
        <div className={Styles.descSection}>
          <p>{data.overview.descriptionHTML}</p>
        </div>
      </div>
    </div>
  );
}
