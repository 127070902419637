import { useEffect, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import useOutsideClick from "@Hooks/useOutsideClick";

export default function SelectIcon({
  options,
  className = "",
  width = "auto",
  position = "bottom",
  handleClick,
  ...props
}) {
  const [expand, setExpand] = useState(false);

  const boxRef = useRef();
  const boxOutsideClick = useOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && expand) {
      setExpand(false);
    }
  }, [boxOutsideClick, expand]);

  return (
    <div
      ref={boxRef}
      style={{ width, height: width }}
      className={`${Styles.container} ${className}`}
      onClick={() => setExpand(!expand)}
    >
      <Icon {...props} />

      {expand && (
        <div className={Styles.optionsWrapper} position={position}>
          {options?.map((o, oI) => (
            <div key={oI} onClick={() => handleClick(o)}>
              <p>{o}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
