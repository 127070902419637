import Sidebar from "@Molecule/Sidebar";
import { Outlet } from "react-router-dom";

const AdminDashboardPage = () => {
  return (
    <Sidebar type={"admin"}>
      <Outlet />
    </Sidebar>
  );
};

export default AdminDashboardPage;
