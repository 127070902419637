import React, { useState } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import Button from "@Atom/Button";
import { fileBaseUrl } from "@Config/index";
import { useNavigate } from "react-router-dom";
import dropboxIcon from "@Assets/Icon/dropbox.svg";
import Icon from "@Atom/Icon";
import SelectIcon from "@Atom/SelectIcon";

export default function ClassesCard({
  title,
  image,
  type,
  level,
  id,
  buttonText = "Detail Kelas",
  navigateTo = "",
  platform = "WEBINAR",
  data,
  withEdit,
}) {
  const [imageError, setImageError] = useState(false);
  const navigate = useNavigate();
  const handleClickEdit = (selectedOption) => {
    if (selectedOption === "Edit Kelas") {
      navigate(`/classes/edit/${data.platform.toLowerCase()}/${data._id}`);
    } else {
      console.log("cicked " + selectedOption);
    }
  };
  return (
    <div className={Styles.container}>
      <div className={Styles.imageWrapper}>
        <img
          src={
            imageError
              ? "https://craftypixels.com/placeholder-image/800x200/ffffff/ffffff"
              : image?.includes("https")
              ? image
              : fileBaseUrl + image
          }
          alt="banner"
          onError={() => setImageError(true)}
        />
        {withEdit && (
          <div className={Styles.moreIcon}>
            <SelectIcon
              options={[
                "Edit Kelas",
                //  "Duplikat & Edit Kelas"
              ]}
              icon="more"
              size={24}
              width="28px"
              color="#FFFFFF"
              handleClick={handleClickEdit}
              position="left"
            />
          </div>
        )}
        {/* {!data?.isPublished && (
          <div className={Styles.draftOverlay}>
            <div className={Styles.overlay}>
              <img alt="" src={dropboxIcon} />
              <p>Draft</p>
            </div>
          </div>
        )} */}
      </div>
      <div className={Styles.bodyWrapper}>
        <div className={Styles.cardBody}>
          <div className={Styles.cardTag}>
            <div className={Styles.tag} type="level">
              <p>{level}</p>
            </div>
            <div className={Styles.tag} type="platform">
              <p>{platform}</p>
            </div>
            <div className={Styles.tag} type="type">
              <p>{data.academicYear}</p>
            </div>
            {type === "PRAKERJA" && (
              <img
                src={Images.LOGO_PRAKERJA}
                alt="prakerja"
                className={Styles.prakerjaLogo}
              />
            )}
          </div>
          <div className={Styles.cardTitle}>
            <p>{title}</p>
          </div>
          {/* <div className={Styles.cardFooter}>
            <div className={Styles.cardDesc}>
              <Icon icon={"user-group"} size={16} color={"#9E9E9E"} />
              <p>{totalStudent}</p>
            </div>
            <div className={Styles.cardDesc}>
              <Icon icon={"star"} size={16} color={"#ECBE6C"} />
              <p>{rating}</p>
            </div>
          </div> */}
        </div>
        <div className={Styles.cardAction}>
          <Button
            text={buttonText}
            variant="outlined"
            className={Styles.cardButton}
            onClick={() =>
              navigateTo ? navigate(`${navigateTo}`) : navigate(`${id}`)
            }
          />
        </div>
      </div>
    </div>
  );
}
