import React from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";

export default function Breadcrumbs({ data }) {
  const navigate = useNavigate();
  return (
    <div className={Styles.container}>
      <div className={Styles.backIcon} onClick={() => navigate(-1)}>
        <Icon size={16} icon={"chevron-left"} />
      </div>
      <div className={Styles.breadcrumbsWrapper}>
        {data.map((el, idx) => (
          <div key={idx} className={Styles.breadcrumbs}>
            <p>{el.title}</p>
            {idx !== data.length - 1 && (
              <Icon size={16} icon={"chevron-right"} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
