import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_KEY_ENC

export const encryptStorageData = (name, data, storage = localStorage) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  storage.setItem(name, encrypted);
}

export const decryptStorageData = (name, storage = localStorage) => {
  const encrypted = storage?.getItem(name)
  const decrypted = encrypted ? CryptoJS?.AES?.decrypt(encrypted, SECRET_KEY)?.toString(CryptoJS?.enc?.Utf8) : null;
  return JSON?.parse(decrypted)
}

export const encrypData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
}

export const decryptData = (encrypted) => {
  const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted);
}