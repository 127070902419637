import { API } from "@Config/index";

export function exchangeVoucher(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post("/voucher/exchange", payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getAllVoucherAdmin(limit, page, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/admins/class/vouchers?limit=${limit}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function generateVoucher(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post("/voucher", payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        responseType: "arraybuffer",
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
