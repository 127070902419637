import InputField from "@Molecule/InputField";
import Styles from "./style.module.scss";
import TextEditor from "@Atom/TextEditor";
import Icon from "@Atom/Icon";
import { useCallback, useEffect, useState } from "react";
import { createRegulations, updateRegulations } from "@Services/course";
import Modal from "@Atom/Modal";
import Button from "@Atom/Button";

export default function RulesSection({
  rule,
  setRule,
  handleSubmit,
  // rules
}) {
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [title, setTitle] = useState("");
  const [triggerChange, setTriggerChange] = useState(false);
  const renewContent = useCallback(() => {
    const data = rule.templateList.find((el) => el.title === rule.selected);
    setRule((prev) => ({
      ...prev,
      id: data?._id || "",
      content: data?.regulationHTML || "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerChange]);

  useEffect(() => {
    if (rule.selected) renewContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule.selected]);

  const handleUpdateTemplate = async (id, content) => {
    try {
      await updateRegulations(id, content);
      handleSubmit(() => {});
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateTemplate = async () => {
    const payload = { title, regulationHTML: rule.content };
    try {
      const { data } = await createRegulations(payload);
      setRule((prev) => ({
        ...prev,
        selected: title,
        id: data._id,
        content: data.regulationHTML,
        templateList: [...prev.templateList, data],
      }));
      setOpenModalCreate(false);
      handleSubmit(() => {});
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = () => {
    if (rule.id) {
      handleUpdateTemplate(rule.id, rule.content)
    } else {
      setOpenModalCreate(true);
      setTitle("");
    }
  };

  console.log(rule);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Aturan Kelas</h3>
        <span>
          Isi dan lengkapi aturan kelas pada kelas yang akan diselengarakan.
        </span>
      </div>
      <div className={Styles.contentWrapper}>
        <InputField
          title={"Template Aturan Kelas"}
          value={rule.selected}
          setValue={(newVal) => {
            setRule((prev) => ({ ...prev, selected: newVal }));
            setTriggerChange((prev) => !prev);
          }}
          placeholder="Tidak ada template dipilih"
          isDropdown
          dropdownOptions={rule.templateList.map((el) => el.title)}
          isLoadingDropdown={false}
          required
        />
        <div className={Styles.content}>
          <div className={Styles.addIcon}>
            <Icon
              icon={"save-add"}
              size={24}
              onClick={() => handleSave(handleSave)}
            />
          </div>
          <TextEditor
            value={rule.content || ""}
            setValue={(e) => setRule((prev) => ({ ...prev, content: e }))}
          />
        </div>
      </div>
      <Modal isOpen={openModalCreate}>
        <div className={Styles.modalBody}>
          <div>
            <p>Save Peraturan Kelas</p>
            <Icon
              icon={"cross"}
              size={24}
              onClick={() => setOpenModalCreate(false)}
            />
          </div>
          <div className={Styles.modalInput}>
            <InputField
              title={"Nama Template Peraturan Kelas"}
              value={title}
              setValue={setTitle}
              placeholder="Nama Template Peraturan Kelas"
            />
          </div>
          <div className={Styles.modalButton}>
            <Button
              variant="outlined"
              text={"Kembali"}
              onClick={() => setOpenModalCreate(false)}
            />
            <Button
              text={"Simpan"}
              disabled={title === ""}
              onClick={handleCreateTemplate}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
