import ClassesDesc from "@Molecule/ClassesDesc";
import PreTestWarningInformation from "@Molecule/PreTestWarningInformation";
import React, { useState } from "react";
import Styles from "./styles.module.scss";
import PreTestHeader from "@Molecule/PreTestHeader";
import Modal from "@Atom/Modal";
import { postAnswerPostPreTest } from "@Services/userSheet";
import { useNavigate } from "react-router-dom";
import PrePostTestQuizModal from "../PrePostTestQuiz";
import Button from "@Atom/Button";
import useQuery from "@Hooks/useQuery";
import NextPrevButton from "@Molecule/NextPrevButton";

export default function Pretest({ data, userSheet }) {
  const [quizModal, setQuizModal] = useState(false);
  const navigate = useNavigate();
  const query = useQuery();
  const title = query.get("t");

  const handleSubmitQuiz = async (answer) => {
    const dataToSend = {
      userSheetID: userSheet._id,
      type: "preTest",
      PreOrPostTest: Object.values(answer).map((el, idx) => ({
        questionID: userSheet.preTest[idx].questionID,
        answer: el,
      })),
    };

    try {
      const res = await postAnswerPostPreTest(dataToSend);
      if (res.status) {
        setQuizModal(false);
        navigate("", { replace: true });
      }
    } catch (error) {}
  };

  return (
    <div className={Styles.container}>
      <PreTestWarningInformation description="Selesaikan Pre-test sekarang untuk mengukuti sesi berikutnya" />
      <div className={Styles.bodyWrapper}>
        <PreTestHeader
          headerPreTest="Pre-Test"
          isScore={Boolean(userSheet.score.preTest) || false}
          score={
            userSheet.score.preTest === -1 ? 0 : `${userSheet.score.preTest}`
          }
          type={"preTest"}
          listDescription={[
            "Tidak ada skor minimal saat mengerjakan Pre-Test",
            "Anda memiliki 1x percobaan dalam mengerjakan Pre-Test",
            "Skor yang Anda dapat tidak mempengaruhi penilaian Anda di kelas",
          ]}
          textButton="Mulai Pre-Test"
          modalTitle="Mulai Pre-Test?"
          modalDescription="Apakah Anda yakin ingin mengerjakan Pre-Test sekarang? Anda harus menyelesaikan Pre-Test hingga selesai dan tidak dapat kembali sebelum Pre-Test yang Anda kerjakan selesai."
          onClickButtonStart={() => setQuizModal(true)}
          textScore={"Skor Akhir Pre-Test"}
        />
      </div>
      {/* <div className={Styles.buttonNext}>
        <Button
          variant="text"
          text={"Lanjut Baca Aturan Kelas"}
          endIcon={"chevron-right"}
          onClick={() => navigate(`/classes/learn/${data._id}/rules?t=${title}`)}
          disabled={userSheet.score.preTest === -1}
        />
      </div> */}
      <NextPrevButton
        nextText={"Lanjut Sesi 1"}
        prevText={"Lihat Jadwal Kelas"}
        disabledNext={userSheet.score.preTest === -1}
        handleNext={() =>
          navigate(`/classes/learn/${data._id}/chapter1-material1?t=${title}`)
        }
        handlePrev={() => {
          navigate(`/classes/learn/${data._id}/schedules?t=${title}`);
        }}
      />
      <ClassesDesc data={data} />
      <Modal isOpen={quizModal}>
        <PrePostTestQuizModal
          data={userSheet.preTest}
          handleSubmit={(answer) => handleSubmitQuiz(answer)}
        />
      </Modal>
    </div>
  );
}
