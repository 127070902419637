import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function ModalHeader({
  title = '',
  withCloseOption = false,
  fontSize='16px',
  onClose = () => { },
  className='',
  ...props
}) {

  return (
    <div {...props} style={{ fontSize }} className={`${Styles.container} ${className}`}>
      <span>{title}</span>
      {withCloseOption
        &&
        <button onClick={onClose}>
          <Icon icon={'cross'} size={'24px'} />
        </button>
      }
    </div>
  )
}