import React from "react";
import Styles from "./styles.module.scss";

const BgSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="244"
      height="270"
      viewBox="0 0 244 270"
      fill="none"
    >
      <g filter="url(#filter0_i_11125_100988)">
        <path
          d="M0 0L244 46.3011V269.5H0L0 0Z"
          fill="url(#paint0_linear_11125_100988)"
        />
      </g>
      <path
        d="M241.5 48.3713V267H2.5V3.01901L241.5 48.3713Z"
        stroke="#9360A8"
        stroke-width="5"
      />
      <defs>
        <filter
          id="filter0_i_11125_100988"
          x="0"
          y="0"
          width="252"
          height="278.7"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="8" dy="11" />
          <feGaussianBlur stdDeviation="4.6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_11125_100988"
          />
        </filter>
        <linearGradient
          id="paint0_linear_11125_100988"
          x1="0"
          y1="0"
          x2="275.013"
          y2="36.5556"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#795EC6" />
          <stop offset="1" stop-color="#8CC5DE" />
        </linearGradient>
      </defs>
    </svg>
  );
};


export default function ClassesInstructor({ data }) {
  return (
    <div className={Styles.container}>
      {
        data?.profilePicURL
        &&
        <div className={Styles.leftSection}>
          <img src={data.profilePicURL} alt="profile" />
          <BgSvg />
        </div>

      }
      <div className={Styles.rightSection}>
        <div className={Styles.head}>
          <p>Tentang</p>
          <p>{data.name}</p>
        </div>
        <div className={Styles.bio}>
          <p>{data.bio}</p>
        </div>
      </div>
    </div>
  );
}
