import LoadingContent from "@Molecule/Loading";
import LearnClass from "@Organism/LearnClass";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { userCourseStore } from "store";
const LearnClassPage = () => {
  // const { classData, userSheet } = useLoaderData();
  const { fetchUserSheet, userSheetData } = userCourseStore();
  const { id } = useParams();

  useEffect(() => {
    fetchUserSheet(id);
  }, []);

  if (!userSheetData) {
    return <LoadingContent />;
  }

  return <LearnClass data={userSheetData} userSheet={userSheetData} />;

  // return (
  //   <Suspense fallback={<p>loading....</p>}>
  //     <Await
  //       resolve={courseData}
  //       errorElement={
  //         <div style={{ height: "100vh" }}>
  //           <p>error...</p>
  //         </div>
  //       }
  //     >
  //       {(dataClass) => (
  //         <Suspense>
  //           <Await resolve={courseData}>
  //             {(userSheet) => (

  //             )}
  //           </Await>
  //         </Suspense>
  //       )}
  //     </Await>
  //   </Suspense>
  // );
};

export default LearnClassPage;
