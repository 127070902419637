import loading from "@Assets/animation/loading.json";
import Icon from "@Atom/Icon";
import Modal from "@Atom/Modal";
import Lottie from "lottie-react";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import Styles from "./style.module.scss";
import { useLocation } from "react-router-dom";
import { addLeadingZero } from "@Helpers/addLeadingZero";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
const PDFJS = window.pdfjsLib;

export default function FilePreview({ fileUrl, type, setOpenPopup }) {
  const [pdf, setPdf] = useState("");
  const [images, setImages] = useState([]);
  const [loadingPdf, setLoadingPdf] = useState(true);
  const user = decryptStorageData("user", localStorage);

  async function showPdf(url) {
    try {
      var _PDF_DOC = await PDFJS.getDocument({
        url: url,
      });
      _PDF_DOC.promise.then((item) => {
        setPdf(item);
      });
    } catch (error) {
      alert(error.message);
    }
  }
  async function renderPage() {
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    // let canv = document.querySelector(".canv");

    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };

      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
    setLoadingPdf(false);
  }
  useEffect(() => {
    pdf && renderPage();
    // eslint-disable-next-line
  }, [pdf]);

  useEffect(() => {
    setLoadingPdf(true);
    type === "DOCUMENT" && fileUrl && showPdf(fileUrl);
  }, [fileUrl, type]);

  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState(0);
  const [slide, setSlide] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const videoRef = useRef();
  const [played, setPlayed] = useState(0);
  const { pathname } = useLocation();
  const [play, setPlay] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [lastWatched, setLastWatched] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setPosition(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const deltaX = e.clientX - position;

    setPosition(e.clientX);

    if (deltaX < -10) {
      if (slide < images.length - 1) setSlide((prev) => prev + 1);
      setIsDragging(false);
    } else if (deltaX > 10) {
      if (slide > 0) {
        setSlide((prev) => prev - 1);
      }
      setIsDragging(false);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  useEffect(() => {
    setPlayed(0);
  }, [fileUrl]);

  useEffect(() => {
    if (type === "VIDEO") {
      if (videoRef.current.getDuration()) {
        if (videoRef.current.getDuration() <= +played + 1) {
          console.log(videoRef.current.getDuration(), played + 10);
          setOpenPopup(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [played]);

  // console.log(isHover);

  useEffect(() => {
    setPlayed(0);
    setSlide(0);
    setLastWatched(0);
  }, [pathname]);

  const onChangeProgress = (e) => {
    setPlayed(e.target.value);
    videoRef?.current?.seekTo(e.target.value);
    // if (user.isAssesor) {
    // }
    // if (e.target.value > lastWatched) {
    //   videoRef?.current?.seekTo(lastWatched);
    // } else {
    //   videoRef?.current?.seekTo(e.target.value);
    // }
  };

  useLayoutEffect(() => {
    if (fullScreen) {
      window.scrollTo(0, 0);
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [fullScreen]);
  switch (type) {
    case "DOCUMENT":
      return (
        <div
          className={Styles.container}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {!loadingPdf && !openModal ? (
            <div className={Styles.imageContainer} ref={videoRef}>
              <img src={images[slide]} alt="pdf-page" />
            </div>
          ) : (
            <div className={Styles.loadingContainer}>
              <Lottie animationData={loading} loop={true} />
              <p>Memuat Kontent</p>
            </div>
          )}
          {!loadingPdf && (
            <div className={Styles.pagination}>
              <div>
                <p>placeholder</p>
              </div>
              <div className={Styles.page}>
                <Icon
                  icon={"chevron-left"}
                  size={16}
                  color={"#FFFFFF"}
                  onClick={() => {
                    if (slide > 0) {
                      setSlide((prev) => prev - 1);
                    }
                  }}
                />
                <p>
                  {slide + 1}/{images.length}
                </p>
                <Icon
                  icon={"chevron-right"}
                  size={16}
                  color={"#FFFFFF"}
                  onClick={() => {
                    if (slide < images.length - 1) setSlide((prev) => prev + 1);
                  }}
                />
              </div>
              <Icon
                icon={"frame"}
                size={24}
                color={"#FFFFFF"}
                onClick={() => setOpenModal(!openModal)}
              />
            </div>
          )}
          <Modal isOpen={openModal}>
            <div className={`${Styles.container} ${Styles.modalContainer}`}>
              {!loadingPdf ? (
                <div className={Styles.imageContainer}>
                  {/* {images.map((el, idx) => ( */}
                  <img src={images[slide]} alt="pdf-page" />
                  {/* ))} */}
                </div>
              ) : (
                <div className={Styles.loadingContainer}>
                  <Lottie animationData={loading} loop={true} />
                  <p>Memuat Kontent</p>
                </div>
              )}
              {!loadingPdf && (
                <div className={Styles.pagination}>
                  <div>
                    <p>t</p>
                  </div>
                  <div className={Styles.page}>
                    <Icon
                      icon={"chevron-left"}
                      size={16}
                      color={"#FFFFFF"}
                      onClick={() => {
                        if (slide > 0) {
                          setSlide((prev) => prev - 1);
                        }
                      }}
                    />
                    <p>
                      {slide + 1}/{images.length}
                    </p>
                    <Icon
                      icon={"chevron-right"}
                      size={16}
                      color={"#FFFFFF"}
                      onClick={() => {
                        if (slide < images.length - 1)
                          setSlide((prev) => prev + 1);
                      }}
                    />
                  </div>
                  <Icon
                    icon={"frame"}
                    size={24}
                    color={"#FFFFFF"}
                    onClick={() => setOpenModal(!openModal)}
                  />
                </div>
              )}
            </div>
          </Modal>
        </div>
      );
    case "VIDEO":
      return (
        <div
          onMouseLeave={() => setIsHover(false)}
          onMouseEnter={() => setIsHover(true)}
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
          className={fullScreen ? Styles.videoFullScreen : ""}
        >
          <div
            onClick={() => setPlay(!play)}
            style={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
          >
            <ReactPlayer
              url={fileUrl}
              // controls
              ref={videoRef}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload noremoteplayback noplaybackrate",
                  },
                },
              }}
              playing={play}
              // controlsList="nodownload"
              onProgress={() => {
                setPlayed(videoRef.current.getCurrentTime());
                if (videoRef.current.getCurrentTime() > lastWatched) {
                  setLastWatched(videoRef.current.getCurrentTime());
                }
              }}
              width={"100%"}
              height={"100%"}
              style={{ userSelect: "none" }}
            />
          </div>
          <div
            className={Styles.controlBar}
            style={{ opacity: !isHover ? "0" : "1" }}
          >
            <div className={Styles.topControl}>
              <div className={Styles.leftSection}>
                {play ? (
                  <Icon
                    icon={"pause"}
                    size={14}
                    onClick={() => setPlay(!play)}
                  />
                ) : (
                  <Icon
                    icon={"play"}
                    size={14}
                    onClick={() => setPlay(!play)}
                  />
                )}
                <p>
                  {Math.floor(played / 60)}:
                  {addLeadingZero(String((played % 60).toFixed()), 2)} /{" "}
                  {Math.floor(videoRef?.current?.getDuration() / 60)}:
                  {addLeadingZero(
                    String((videoRef?.current?.getDuration() % 60).toFixed()),
                    2
                  )}
                </p>
              </div>
              <div>
                <Icon
                  icon={"frame"}
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={() => setFullScreen(!fullScreen)}
                />
              </div>
            </div>
            <input
              type="range"
              min={"0"}
              max={`${videoRef?.current?.getDuration()}`}
              value={played}
              onChange={(e) => onChangeProgress(e)}
            />
          </div>
        </div>
      );
    default:
      return (
        <div>
          <p>text</p>
        </div>
      );
  }
}
