import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import Styles from "./style.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "@Atom/Button";
import Images from "@Theme/Images";
import QuesionReviewCard from "@Molecule/QuestionReviewCard";
import Topbar from "@Molecule/Topbar";
import { updateInstructorClassProgressAST } from "@Services/instructor";
import Icon from "@Atom/Icon";

const tabs = ["Pilihan Ganda", "Essay"];

export default function TeacherScoreReviewModal({ materialData, handleClose }) {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setData(materialData);
  }, [materialData]);

  const [tab, setTab] = useState(0);

  const questionsToMap = useMemo(() => {
    return data?.quizzes?.filter((el) => el.quizType === tabs[tab]) || [];
  }, [data, tab]);

  const maxScore = useMemo(() => {
    return {
      pg: data?.quizzes.filter((el) => el.quizType === "Pilihan Ganda").length,
      essay: data?.quizzes
        .filter((el) => el.quizType === "Essay")
        .map((el) => +el.maxScore)
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0),
    };
  }, [data]);

  const score = useMemo(() => {
    return {
      pg: data?.quizzes
        .filter((el) => el.quizType === "Pilihan Ganda")
        .map((el) => el.score)
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0),
      essay: data?.quizzes
        .filter((el) => el.quizType === "Essay")
        .map((el) => el.score)
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0),
    };
  }, [data]);

  const handleChange = (idx, value) => {
    let temp = { ...data };
    temp["quizzes"][idx]["score"] = value;
    setData(temp);
  };

  const scoreToSend = useMemo(() => {
    return Math.floor(
      ((score.pg + (score.essay < 0 ? 0 : score.essay)) * 100) /
        (maxScore.essay + maxScore.pg)
    );
  }, [maxScore.essay, maxScore.pg, score.essay, score.pg]);

  const handleSubmit = async () => {
    const payload = {
      essayScores: data?.quizzes
        ?.filter((el) => el.quizType === "Essay")
        .map((el) => ({
          num: el.quizNum,
          score: el.score,
        })),
      totalScore: scoreToSend,
    };
    if (payload.essayScores.some((el) => el.score === -1)) {
      setError(true);
    } else {
      setError(false);
      try {
        setLoading(true);
        await updateInstructorClassProgressAST(
          params.userSheetId,
          params.sessionNum,
          data.materialNum,
          payload
        );
        setLoading(false);
        navigate("", { state, replace: true });
        handleClose();
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <Topbar containerClassName={Styles.contentWrapper}>
      <div className={Styles.container}>
        <div className={Styles.sticky}>
          <Button
            variant="text"
            text={"Kembali"}
            startIcon={"chevron-left"}
            onClick={handleClose}
          />
          {/* <div className={Styles.headText}>
            <img src={Images.THS_LOGO} alt="" />
            <p>Hasil Penilaian Asesmen Sumatif Teori</p>
          </div> */}

          <div className={Styles.headerContainer}>
            {error && (
              <div className={Styles.errorWarning}>
                <Icon icon={"warning-2"} size={24} />
                <p>Terdapat soal essay yang belum diberi nilai</p>
              </div>
            )}
            <div className={Styles.headerWrapper}>
              <div className={Styles.tabWrapper}>
                {tabs.map((el, idx) => (
                  <div
                    className={Styles.tab}
                    onClick={() => setTab(idx)}
                    is-selected={String(tab === idx)}
                    key={idx}
                  >
                    <p>{el}</p>
                  </div>
                ))}
              </div>
              <div className={Styles.scoreWrapper}>
                <p>
                  Skor PG : {score.pg}/{maxScore.pg}
                </p>
                <p>
                  Skor Essay : {score.essay < 0 ? 0 : score.essay}/
                  {maxScore.essay}
                </p>
                <p>
                  Jumlah Skor : {score.pg + (score.essay < 0 ? 0 : score.essay)}
                  /{maxScore.essay + maxScore.pg}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.saveButton}>
          <Button
            variant="contained"
            text={loading ? "Loading..." : "Simpan"}
            disabled={loading}
            onClick={() => handleSubmit()}
          />
          <p>Nilai : {scoreToSend}</p>
        </div>
        <div className={Styles.bodyContainer}>
          <div className={Styles.bodyWrapper}>
            {questionsToMap.map((el, idx) => (
              <React.Fragment key={idx}>
                <QuesionReviewCard
                  data={el}
                  quizNum={idx + 1}
                  isEdit={true}
                  score={el.score === -1 ? "" : el.score}
                  handleChange={handleChange}
                  isError={error}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </Topbar>
  );
}
