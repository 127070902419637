import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function SideActions({
  options = [],
  className = '',
  ...props
}) {

  // const optionso = [
  //   {
  //     id: ''
  //     icon: '',
  //     text: '',
  //     onClick: ()=> {}
  //   }
  // ]

  const handleHover = (type, id) => {
    const element = document.getElementById(id)
    if (type === 'enter') {
      element.classList.add(Styles.active)
    } else {
      element.classList.remove(Styles.active)
    }
  }

  return (
    <div className={`${Styles.container} ${className}`} {...props}>
      {options?.map((o, oI) => (
        <button
          key={oI}
          onClick={o?.onClick}
          id={o?.id}
          onMouseEnter={() => handleHover('enter', o?.id)}
          onMouseLeave={() => handleHover('leave', o?.id)}
        >
          <Icon icon={o?.icon} size={20} />
          <span>{o?.text}</span>
        </button>
      ))}
    </div>
  )
}