import React, { useCallback, useState } from "react";
import Styles from "./styles.module.scss";
import TabHeader from "@Atom/TabHeader";
import ClassesOverview from "./Overview";
import ClassesInstructor from "./Instuctor";

// const tabOptions = ["Overview", "Instruktur", "Review"];
const tabOptions = ["Overview", "Instruktur"];

export default function ClassesDesc({data}) {
  const [tabs, setTabs] = useState(1);

  const renderComponent = useCallback(() => {
    switch (tabs) {
      case 1:
        return <ClassesOverview data={data}/>;
      case 2:
        return <ClassesInstructor data={data.instructor}/>;
      default:
        return <ClassesOverview />;
    }
  }, [tabs,data]);

  return (
    <div className={Styles.container}>
      <div className={Styles.tabSection}>
        <TabHeader tabs={tabs} setTabs={setTabs} tabOptions={tabOptions} />
      </div>
      <div className={Styles.contentSection}>{renderComponent()}</div>
    </div>
  );
}
