import React from "react";
import Styles from './styles.module.scss'
import Lottie from "lottie-react";
import loadingClass from "@Assets/animation/loadingClass.json";


export default function LoadingContent() {
  return (
    <div className={Styles.loadingContainer}>
      <Lottie animationData={loadingClass} loop={true} />
      <p>Memuat Kontent</p>
    </div>
  );
}
