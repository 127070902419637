import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import { useDropzone } from 'react-dropzone';
import { useEffect } from 'react';

export default function Avatar({
  iconName = '',
  iconSize = '',
  iconColor = '',
  imgUrl,
  backgroundColor = '#C2C2C2',
  size = '24px',
  borderRadius = '50%',
  isCustomSizing,
  customSizing,
  className = '',
  withEditOption = false,
  onClickEdit = () => { }
}) {

  const {
    getRootProps,
    getInputProps,
    // fileRejections,
    acceptedFiles,
    open
  } = useDropzone({
    // onDrop,
    accept: { 'image/jpeg': ['.jpeg', '.png'] },
    multiple: false,
    noClick: true,
    // maxSize: 1048576 * 3
  });

  useEffect(() => {
    if (acceptedFiles?.length) {
      onClickEdit(acceptedFiles[0])
      // setPreview(URL.createObjectURL(acceptedFiles[0]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  return (
    <div className={`${Styles.container} ${className}`}
      style={{
        backgroundColor,
        borderRadius,
        width: isCustomSizing ? customSizing?.split(' ')[0] : size,
        height: isCustomSizing ? customSizing?.split(' ')[1] : size,
      }}
    >
      {imgUrl
        ?
        <img
          src={imgUrl}
          alt=''
          style={{ borderRadius }}
        />
        :
        <Icon icon={iconName} size={iconSize} color={iconColor} />
      }
      {withEditOption
        &&
        <div
          {...getRootProps()}
          className={Styles.editButton}
          style={{
            width: `calc(${size} / 2.5)`,
            aspectRatio: 1,
            overflow: 'hidden',
          }}
          onClick={open}
        >
          <input
            {...getInputProps()}
            type='file'
            hidden
          />
          <Icon icon={'pencil'} size={`calc(${size} / 4)`} />
        </div>
      }
    </div>
  )
}