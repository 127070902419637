import AcademicYearLayout from "@Organism/Admin/AcademicYear";
import { getAcademicYear } from "@Services/admin";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export function loader() {
  const academicYearData = getAcademicYear();

  return defer({
    academicYearData,
  });
}

export default function AcademicYearPage() {
  const { academicYearData } = useLoaderData();

  return (
    <Suspense fallback={<AcademicYearLayout data={[]} isLoading />}>
      <Await
        resolve={academicYearData}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <AcademicYearLayout data={value} />}
      </Await>
    </Suspense>
  );
  //   return <AcademicYearLayout />;
}
