import Images from '@Theme/Images'
import Styles from './style.module.scss'

export default function DoodleGradientCard({
  children,
  className = '',
  containerOpacity = '.99',
  doodleOpacity = '.6',
  doodleScale='1.35',
  ...props }) {

  return (
    <div
      {...props}
      className={`${Styles.container} ${className}`}
      style={{
        opacity: containerOpacity
      }}
    >
      <img
       className={Styles.bg} 
       src={Images.DOODLE_BG}
        alt='' 
        style={{
          opacity: doodleOpacity,
          scale: doodleScale
        }}
        />
      {children}
    </div>
  )
}