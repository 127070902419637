import { makeRandomString } from "@Helpers/makeRandomString";
import CurriculumCard from "@Molecule/CurriculumCard";
import GeneralInfoDetail from "@Molecule/GeneralInfoDetail";
import InputField from "@Molecule/InputField";
import { useMemo } from "react";
import Styles from "./style.module.scss";
import ScheduleCard from "@Atom/ScheduleCard";

export default function ReviewSection({ summary, type, details }) {

  const template = useMemo(() => {
    return [
      {
        title: "Thumbnail dan Video Pengenalan Kelas",
        component: (
          <div className={`${Styles.thumbnail}`}>
            <InputField
              title={"Thumbnail"}
              value={details?.thumbnail}
              placeholder="no file"
              isFile
              readOnly
            />
            <InputField
              title={"Video Pengenalan"}
              value={summary?.orientationVideo}
              placeholder="no file"
              // isFile
              readOnly
            />
          </div>
        ),
      },
      {
        title: "Informasi Umum",
        component: (
          <div className={`${Styles.generalInfo}`}>
            <GeneralInfoDetail
              title={"Kategori Mata Pelajaran"}
              description={details.classSubject || "-"}
            />
            <GeneralInfoDetail
              title={"Level Kelas"}
              description={details.grade || "-"}
            />
            <GeneralInfoDetail
              title={"Jenis Pembelajaran Murid"}
              description={"Home Schooling"}
            />
            <GeneralInfoDetail
              title={"Tahun Ajaran"}
              description={`${details.startAcademicYear} - ${details.endAcademicYear}`}
            />
            <GeneralInfoDetail
              title={"Tujuan Pembelajaran"}
              description={details.learningGoals || "-"}
            />
          </div>
        ),
      },
      {
        title: "Jadwal Kursus",
        component: (
          <div className={`${Styles.schedules}`}>
            {summary?.schedules?.map((s, sI) => (
              <ScheduleCard
                key={sI}
                title={`Batch ${sI + 1}`}
                startDate={s?.startDate}
                endDate={s?.endDate}
                startTime={s?.startTime}
                endTime={s?.finishTime}
              />
            ))}
          </div>
        ),
      },
      {
        title: "Kurikulum",
        component: (
          <div className={`${Styles.sessions}`}>
            {summary?.sessions?.map((s, sI) => (
              <CurriculumCard
                cardTitle={`Sesi ${sI + 1}`}
                data={s}
                data1={{
                  title: "Introduction to UI/UX Design",
                  description: "Pengenalan UI/UX Design",
                  subjects: [
                    {
                      title: "What is UI/UX Design?",
                      files: [{ name: "file" }, { name: "file" }],
                    },
                  ],
                  quizes: [
                    {
                      id: makeRandomString(5),
                      title: "Quiz 1",
                      questions: [
                        {
                          id: makeRandomString(5),
                          type: "Pilihan ganda",
                          question: "question 1",
                          options: [
                            {
                              id: makeRandomString(5),
                              option: "option 1",
                              isCorrect: true,
                            },
                            {
                              id: makeRandomString(5),
                              option: "option 2",
                              isCorrect: true,
                            },
                            {
                              id: makeRandomString(5),
                              option: "option 3",
                              isCorrect: true,
                            },
                            {
                              id: makeRandomString(5),
                              option: "option 4",
                              isCorrect: true,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                }}
              />
            ))}
          </div>
        ),
      },
      // {
      //   title: "Bank Soal",
      //   component: (
      //     <div className={`${Styles.questions}`}>
      //       <QuizCard data={{ questions: summary?.questions }} />
      //     </div>
      //   ),
      // },
      // {
      //   title: "Uji Keterampilan",
      //   component: (
      //     <div className={`${Styles.skillTest}`}>
      //       <InputField
      //         title={"Panduan Uji Keterampilan"}
      //         isFile
      //         value={summary?.skillTest?.guide}
      //         placeholder="no file"
      //         readOnly
      //       />
      //     </div>
      //   ),
      // },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    summary?.generalInfo?.classCategory,
    summary?.generalInfo?.className,
    summary?.generalInfo?.classType,
    summary?.generalInfo?.description,
    summary?.generalInfo?.generalPurpose,
    summary?.generalInfo?.language,
    summary?.generalInfo?.level,
    summary?.generalInfo?.mainObjective,
    summary?.generalInfo?.participantType,
    summary?.generalInfo?.specificPurpose,
    summary?.generalInfo?.tools,
    summary?.orientationVideo,
    summary?.price,
    summary?.questions,
    summary?.schedules,
    summary?.sessions,
    summary?.skillTest?.guide,
    summary?.thumbnail,
  ]);

  const templateToMap = useMemo(() => {
    if (type === "VIDEO") {
      return template?.filter((obj) => obj?.title !== "Jadwal Kursus");
    }
    return template;
  }, [template, type]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Review</h3>
        <span>
          Silahkan tinjau kembali kelas yang akan Anda buat, dan simpan bila
          Anda telah yakin semua telah diisi dengan benar.
        </span>
      </div>
      <div className={Styles.content}>
        {templateToMap?.map((t, tI) => (
          <div key={tI} className={Styles.section}>
            <h4>{t?.title}</h4>
            {t?.component}
          </div>
        ))}
      </div>
    </div>
  );
}
