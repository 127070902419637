import Styles from './style.module.scss'

export default function ProgressBar({
  containerColor = '#D5C0DD',
  fillerColor = '#A87EB9',
  completed,
  withPercentage = true
}) {

  return (
    <div className={Styles.container} style={{ background: containerColor }}>
      <div className={Styles.filler} style={{ backgroundColor: fillerColor, width: `${completed}%` }}>
        {
          !!withPercentage
          &&
          <span className={Styles.label}>{`${completed}%`}</span>
        }
      </div>
    </div>
  )
}