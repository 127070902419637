import React, { useState } from "react";
import Styles from "./styles.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import TransparentSearchbar from "@Atom/TransparentSearchbar";
import Button from "@Atom/Button";
import useQuery from "@Hooks/useQuery";
import NotFound from "@Molecule/NotFound";
import ClassProgressCard from "@Molecule/ClassProgressCard";
import SingleInputModal from "@Molecule/SingleInputModal";
import { useNavigate, useParams } from "react-router-dom";
import { exchangeCourse } from "@Services/userCourse";
import Lottie from "lottie-react";
import loadingClass from "@Assets/animation/loadingClass.json";
import LoadingContent from "@Molecule/Loading";
import { useMemo } from "react";
import { useDebounce } from "@Hooks/useDebounce";

const quotesList = [
  "Tidak ada batasan dalam belajar. Semakin rajin belajar, semakin banyak pengetahuan yang akan kamu miliki.",
  "Ketika rasa malas menghampiri, ingatlah bahwa disiplin adalah kunci menuju prestasi yang luar biasa.",
  "Jangan pernah berhenti belajar karena hidup tidak pernah berhenti mengajarkan hal-hal baru.",
  "Pembelajaran itu adalah proses tanpa akhir. Semakin kamu belajar, semakin kamu sadar betapa sedikit yang kamu ketahui.",
  "Malas adalah musuh terbesar dari kemajuan. Bangkitlah, lampaui rasa malas, dan perjuangkan masa depan yang kamu inginkan.",
  "Pendidikan adalah kunci untuk membuka pintu kesuksesan.",
  "Tak pernah ada kata terlambat untuk belajar.",
  "Teruslah mencari ilmu, jangan puas dengan pengetahuan terbatas.",
];

export default function ClassByGradeLayout({ data, isLoading }) {
  const [searchQuery, setSearchQuery] = useState("");
  const query = useQuery();
  const academicYear = query.get("ta") || "";
  const { id } = useParams();
  const [openModalAddSubject, setOpenModalAddSubject] = useState(false);
  const [errorAddSubject, setErrorAddSubject] = useState("");
  const navigate = useNavigate();
  const handleAddSubject = async (courseCode) => {
    try {
      const res = await exchangeCourse(id, courseCode);
      if (res.statusCode === 400) {
        navigate("", { replace: true });
        setOpenModalAddSubject(false);
      }
      if (res.statusCode === 200) {
        navigate(`/classes/learn/${res.data._id}/rules`);
      }
    } catch (error) {
      console.log(error.response.data.message);
      setErrorAddSubject(error.response.data.message);
    }
  };
  const searchTrigger = useDebounce(searchQuery, 500);

  const dataToMap = useMemo(() => {
    return data.filter((el) =>
      el.subjectName.toUpperCase().includes(searchQuery.toUpperCase())
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchTrigger]);

  console.log(dataToMap);

  const quotes = useMemo(() => {
    return quotesList[Math.floor(Math.random() * 7) + 1];
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.layout}>
        <section className={Styles.doodle}>
          <DoodleGradientCard>
            <div className={Styles.topCard}>
              <div className={Styles.leftSection}>
                <div>
                  <p className={Styles.title}>
                    Selamat Datang di Tahun Ajaran {academicYear}
                  </p>
                  <p className={Styles.desc}>{quotes}</p>
                </div>
                <TransparentSearchbar
                  placeholder="Cari Mata Pelajaran"
                  setValue={setSearchQuery}
                  value={searchQuery}
                />
              </div>
              <div className={Styles.rightSection}>
                <Button
                  variant="neutral-contained"
                  startIcon={"add-circle"}
                  text={"Tambah Mapel"}
                  onClick={() => setOpenModalAddSubject(true)}
                />
              </div>
            </div>
          </DoodleGradientCard>
        </section>

        {isLoading ? (
          <LoadingContent />
        ) : dataToMap.length > 0 ? (
          <div className={Styles.cardWrapper}>
            {dataToMap.map((el, idx) => (
              <div className={Styles.card} key={idx}>
                <ClassProgressCard data={el} />
              </div>
            ))}
          </div>
        ) : (
          <div className={Styles.emptyHandler}>
            <NotFound
              title="Belum Ada Mata Pelajaran"
              desc="Silahkan tukar kode mapel untuk dapat mengakses mata pelajaran Anda"
              isStudent={true}
              onClick={() => setOpenModalAddSubject(true)}
              startIcon={"add-circle"}
              buttonText="Tambah Mapel"
            />
          </div>
        )}
      </div>
      <SingleInputModal
        isOpen={openModalAddSubject}
        headTitle={"Masukkan Kode Mata Pelajaran"}
        inputTitle={"Masukkan 6 Digit Kode Mata Pelajaran"}
        handleClose={() => {
          setOpenModalAddSubject(false);
          setErrorAddSubject("");
        }}
        error={errorAddSubject}
        handleSubmit={(code) => handleAddSubject(code)}
      />
    </div>
  );
}
